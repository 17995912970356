import React, { useState, useEffect } from "react";
import axios from "axios";
import Upcoming from "./Upcoming";
import Completed from "./Completed";
import Cancelled from "./Cancelled";
import { Link } from "react-router-dom";

function Reservations() {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div>
      <div className="mini-navbar" style={{ backgroundColor: "whitesmoke" }}>
        <ul>
          <li>
            <Link
              onClick={() => {
                setActiveTab(0);
              }}
              className={`${activeTab === 0 && "clicked"}`}
            >
              Upcoming
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(1);
              }}
              className={`${activeTab === 1 && "clicked"}`}
            >
              Completed
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(2);
              }}
              className={`${activeTab === 2 && "clicked"}`}
            >
              Cancelled
            </Link>
          </li>
        </ul>
      </div>
      {activeTab === 0 && <Upcoming />}
      {activeTab === 1 && <Completed />}
      {activeTab === 2 && <Cancelled />}
    </div>
  );
}

export default Reservations;
