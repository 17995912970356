import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputGroup,
  Button,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteSpecialRequest from "./DeleteSpecialRequest";
import { baseURL } from "../../../../BaseURL";
import axios from "axios";

function SpecialRequest({
  restaurantId,
  restaurantData,
  isLoading,
  setLoading,
}) {
  const [specialRequest, setSpecialRequest] = useState("");
  const [isLoadingRequest, setLoadingRequest] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  async function addSpecialRequest() {
    const data = restaurantData?.restaurantSpecialRequests.slice();
    data.push(specialRequest);
    const formData = {
      restaurantSpecialRequests: data,
    };
    setLoading(true);
    await axios
      .post(baseURL + "/restaurant/update-restaurant/" + restaurantId, formData)
      .then((res) => {
        enqueueSnackbar("Special request added successfully", {
          variant: "success",
        });
        setLoading(false);
        setSpecialRequest("");
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "50%",
            marginRight: "20px",
          }}
        >
          <label style={{ width: "50%", marginTop: "10px" }}>
            Special Requests
          </label>
          <InputGroup>
            <FormControl
              placeholder="Enter Request Type"
              value={specialRequest}
              onChange={(e) => setSpecialRequest(e.target.value)}
            />
          </InputGroup>
        </div>
        {isLoading === true && specialRequest ? (
          <Button
            variant="dark"
            style={{ backgroundColor: "#ef3340" }}
            disabled
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: "5px" }}
            />
            <span className="visually-hidden">Loading...</span>
          </Button>
        ) : (
          <Button
            variant="dark"
            style={{ backgroundColor: "#ef3340" }}
            onClick={() => addSpecialRequest()}
            disabled={!specialRequest}
          >
            Save Changes
          </Button>
        )}
      </div>
      <div>
        <Row
          sm={3}
          style={{
            margin: "40px 2px",
            width: "50rem",
            padding: "0 20px",
          }}
        >
          {restaurantData?.restaurantSpecialRequests.length !== 0 &&
            restaurantData?.restaurantSpecialRequests.map((request) => (
              <Col
                key={request._id}
                lg={4}
                style={{
                  margin: "5px 0",
                }}
              >
                <div
                  class="shadow-md"
                  style={{
                    textAlign: "center",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    padding: "0 15px",
                    backgroundColor: "#39424e",
                    color: "white",
                  }}
                >
                  <p style={{ fontSize: "13px" }}>{request}</p>
                  <div>
                    {isLoadingRequest === request ? (
                      <CircularProgress
                        style={{
                          height: "20px",
                          width: "20px",
                          color: "white",
                        }}
                      />
                    ) : (
                      <DeleteSpecialRequest
                        request={request}
                        isLoading={isLoading}
                        setLoading={setLoading}
                        restaurantId={restaurantId}
                      />
                    )}
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </div>
  );
}

export default SpecialRequest;
