import React, { useState, useEffect } from "react";
import axios from "axios";
import { InputGroup, FormControl, Button, Spinner } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import { baseURL } from "../../../BaseURL";
import IconButton from "@mui/material/IconButton";
import AddAdvertisment from "./AddAdvertisment";
import { ReactSortable } from "react-sortablejs";
import CircularProgress from "@mui/material/CircularProgress";
import UpdateAdvertisment from "./UpdateAdvertisment";
import DeleteAdvertisment from "./DeleteAdvertisment";

const columns = [
  { id: "Image", label: "Image", maxWidth: "110px" },
  { id: "Name", label: "Name" },
  {
    id: "Link",
    label: "Link",
  },
  {
    id: "Live",
    label: "Live",
  },
  {
    id: "Created On",
    label: "Created On",
  },
  {
    id: "Actions",
    label: "Actions",
    maxWidth: "150px",
    align: "right",
  },
];

function AllUsers() {
  const [allAdvertismentData, setAllAdvertismentData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDragged, setDragged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const [deleteAd, setDeleteAd] = useState("");
  let filterAdvertisment = [];
  const filterTable = (searchTerm) => {
    filterAdvertisment = [];
    if (searchTerm === "") {
      filterAdvertisment = allAdvertismentData;
    } else {
      allAdvertismentData.forEach((item) => {
        if (item.userName.toLowerCase().includes(searchTerm.toLowerCase())) {
          filterAdvertisment.push(item);
        }
      });
    }
  };
  filterTable(searchTerm);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function extractRowsFromData() {
    const rows = [];
    allAdvertismentData.forEach((userData) => {
      let row = [];
      row.push(
        userData?._id,
        userData?.userName,
        userData?.userPhone,
        userData?.userDOB,
        userData?.userLoyalityPoints,
        userData?.createdAt.substring(0, 10)
      );

      console.log(row);
      rows.push(row);
    });

    console.log(rows);

    return rows;
  }

  async function handleChange() {
    setLoading(true);
    const body = {
      advertismentData: updatedData,
    };
    await axios
      .post(baseURL + "/admin/update-advertisment-sort-index", body)
      .then((res) => {
        console.log(res);
        console.log(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    axios.get(baseURL + "/admin/get-all-advertisment").then((res) => {
      setAllAdvertismentData(res.data.data);
    });
  }, [isLoading]);

  // // console.log(allAdvertismentData);
  // console.log(updatedData);
  return (
    <div className="all-restaurants">
      <div>
        <div style={{ backgroundColor: "white" }}>
          <div className="header">
            <p>
              <b>Total Advertisment</b> : {allAdvertismentData.length}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <AddAdvertisment
                setLoading={setLoading}
                totalAdvertisments={allAdvertismentData.length}
                isLoading={isLoading}
              />
              {isDragged &&
                (isLoading === true ? (
                  <Button
                    variant="dark"
                    style={{ backgroundColor: "#ef3340" }}
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: "5px" }}
                    />
                    <span className="visually-hidden">Loading...</span>
                  </Button>
                ) : (
                  <Button
                    variant="dark"
                    style={{ backgroundColor: "#ef3340" }}
                    onClick={() => handleChange()}
                  >
                    Save Changes
                  </Button>
                ))}
            </div>
          </div>
          <div className="restaurant-data-table">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px 30px",
                margin: "5px 30px",
                borderRadius: "5px",
                cursor: "pointer",
                backgroundColor: "#39424e",
                color: "whitesmoke",
                fontWeight: "bold",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                fontSize: "14px",
              }}
            >
              <p>Image</p>
              <p>Title</p>
              <p>URL</p>
              <p>Live</p>
              <p>Actions</p>
            </div>
            {allAdvertismentData.length !== 0 && (
              <ReactSortable
                id="ads"
                list={allAdvertismentData}
                setList={setAllAdvertismentData}
                animation={200}
                onChange={(order, sortable, evt) => {
                  console.log(order);
                  setDragged(true);
                  // data.forEach((item) => {
                  //   if (order.item.dataset.id === item._id) {
                  //     item.sortIndex = order.newDraggableIndex;
                  //   }
                  // });
                  const dataTable = document.getElementById("ads").childNodes;
                  const data = Object.values(dataTable);
                  setUpdatedData([]);
                  data.forEach((item, index) => {
                    const data = {
                      id: item.dataset.id,
                      sortIndex: index,
                    };

                    setUpdatedData((prev) => [...prev, data]);
                  });
                }}
              >
                {allAdvertismentData.map((ads) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "5px 10px",
                      margin: "5px 30px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      fontSize: "14px",
                    }}
                    key={ads._id}
                  >
                    <img
                      src={ads.advertismentImage}
                      alt="Advertisment"
                      style={{ width: "70px", height: "50px" }}
                    />
                    <p>
                      <b>{ads.advertismentName}</b>
                    </p>
                    <a href={ads.advertismentURL}>Link</a>
                    <p>{ads.isLive === true ? "Yes" : "No"}</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <UpdateAdvertisment
                        advertismentData={ads}
                        setLoading={setLoading}
                        isLoading={isLoading}
                      />
                      <DeleteAdvertisment
                        advertismentId={ads._id}
                        setLoading={setLoading}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                ))}
              </ReactSortable>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllUsers;
