import React, { useState, useEffect } from "react";
import UserNotifications from "./UserNotifications";
import RestaurantNotifications from "./RestaurantNotifications";
import AdminNotifications from "./AdminNotifications";
import { Link } from "react-router-dom";

function Notifications() {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div>
      <div className="mini-navbar" style={{ backgroundColor: "whitesmoke" }}>
        <ul>
          <li>
            <Link
              onClick={() => {
                setActiveTab(0);
              }}
              className={`${activeTab === 0 && "clicked"}`}
            >
              Users
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(1);
              }}
              className={`${activeTab === 1 && "clicked"}`}
            >
              Restaurants
            </Link>
          </li>
        </ul>
      </div>
      {activeTab === 0 && <UserNotifications />}
      {activeTab === 1 && <RestaurantNotifications />}
    </div>
  );
}

export default Notifications;
