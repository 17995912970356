import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ImagesTab from "./ImagesTab";
import FloorsTab from "./FloorsTab";
import RestaurantFacilities from "./RestaurantFacilities";
import HaultTimingsTab from "./HaultTimingsTab";
import RestaurantSubscription from "./RestaurantSubscription";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs({
  restaurantData,
  restaurantId,
  isLoading,
  setLoading,
}) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        width: "100%",
        borderRadius: "5px",
      }}
    >
      <AppBar
        position="static"
        style={{
          backgroundColor: " #39424e",
          borderRadius: "5px",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Images" {...a11yProps(0)} />
          <Tab label="Floors" {...a11yProps(1)} />
          <Tab label="Hault Timings" {...a11yProps(2)} />
          <Tab label="Facilities" {...a11yProps(3)} />
          {restaurantData?.restaurantSubscriptionPlan && (
            <Tab label="Subscription" {...a11yProps(4)} />
          )}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ImagesTab
            restaurantData={restaurantData}
            restaurantId={restaurantId}
            isLoading={isLoading}
            setLoading={setLoading}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <FloorsTab
            restaurantData={restaurantData}
            restaurantId={restaurantId}
          />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <HaultTimingsTab
            restaurantData={restaurantData}
            restaurantId={restaurantId}
            isLoading={isLoading}
            setLoading={setLoading}
          />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <RestaurantFacilities
            restaurantData={restaurantData}
            restaurantId={restaurantId}
            isLoading={isLoading}
            setLoading={setLoading}
          />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <RestaurantSubscription
            restaurantData={restaurantData}
            restaurantId={restaurantId}
            isLoading={isLoading}
            setLoading={setLoading}
          />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
