import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { CsvBuilder } from "filefy";
import { useSnackbar } from "notistack";
import { InputGroup, FormControl, Button, Spinner } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import Messages from "../singleUser/Enquires/Messages";
import { baseURL } from "../../../BaseURL";
import { DateRange } from "react-date-range";
import moment from "moment";

const columns = [
  { id: "Id", label: "Id", maxWidth: "100px" },
  { id: "Name", label: "Name" },
  {
    id: "Restaurant",
    label: "Restaurant",
  },
  {
    id: "Enquiry Type",
    label: "Enquiry Type",
  },
  {
    id: "Enquiry Comment",
    label: "Enquiry Comment",
    maxWidth: "300px",
  },
  {
    id: "Messages",
    label: "Messages",
  },
  {
    id: "Date",
    label: "Date",
  },
];

function AllEnquires() {
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [allEnquiryData, setAllEnquiryData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  let filterUsers = [];
  const filterTable = (searchTerm) => {
    filterUsers = [];
    if (searchTerm === "") {
      filterUsers = allEnquiryData;
    } else {
      allEnquiryData.forEach((item) => {
        if (item.userName.toLowerCase().includes(searchTerm.toLowerCase())) {
          filterUsers.push(item);
        }
      });
    }
  };
  filterTable(searchTerm);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function extractRowsFromData() {
    const rows = [];
    allEnquiryData.forEach((userData) => {
      let row = [];
      row.push(
        userData?._id,
        userData?.userName,
        userData?.userId?.userPhone,
        userData?.restuarantId?.restaurantName,
        userData?.starRating,
        userData?.reviewMessage,
        userData?.date?.substring(0, 10),
        userData?.date?.substring(11, 19)
      );

      console.log(row);
      rows.push(row);
    });

    console.log(rows);

    return rows;
  }

  async function findEnquiresBetweenDates() {
    const startDate = moment(state[0].startDate).format("YYYY-MM-DD");
    const endDate = moment(state[0].endDate).format("YYYY-MM-DD");

    const body = {
      startDate: startDate,
      endDate: endDate,
    };
    setLoading(true);
    await axios
      .post(baseURL + "/enquiry/get-all-enquiry-between-dates-admin", body)
      .then((res) => {
        setAllEnquiryData(res.data.data.reverse());
        setLoading(false);
        setOpen(false);

        document.querySelector(".rdrMonthAndYearWrapper").style.display =
          "none";
        document.querySelector(".rdrMonthsVertical").style.display = "none";
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        setLoading(false);
      });
  }

  function exportToCSV() {
    var csvBuilder = new CsvBuilder("review_list.csv")
      .setColumns([
        "Id",
        "User",
        "Contact",
        "Restaurant",
        "Star Rating",
        "Review",
        "Date",
        "Time",
      ])
      .addRows(extractRowsFromData())
      .exportFile();
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(baseURL + "/enquiry/get-all-enquiries")
      .then((res) => {
        setAllEnquiryData(res.data.data.reverse());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  function handleShowDate() {
    const inputField = document.querySelector(".rdrDateDisplayItemActive");
    console.log(isOpen);
    inputField?.addEventListener("click", () => {
      if (isOpen === false) {
        document.querySelector(".rdrMonthAndYearWrapper").style.display =
          "none";
        document.querySelector(".rdrMonthsVertical").style.display = "none";
        setOpen(true);
      } else {
        document
          .querySelector(".rdrMonthAndYearWrapper")
          .style.removeProperty("display");
        document
          .querySelector(".rdrMonthsVertical")
          .style.removeProperty("display");
        setOpen(false);
      }
    });
  }

  return (
    <div className="all-restaurants">
      <div>
        {activeTab === 0 && (
          <div style={{ backgroundColor: "white" }}>
            <div className="header">
              <p>
                <b>Total Enquires</b> : {allEnquiryData?.length}
              </p>
              <div
                onClick={handleShowDate}
                style={{
                  position: "absolute",
                  zIndex: "999",
                  top: "18%",
                  left: "57%",
                }}
              >
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => setState([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                />
                {isLoading === true ? (
                  <Button
                    variant="dark"
                    style={{
                      backgroundColor: "#ef3340",
                      height: "35px",
                      width: "160px",
                      marginLeft: "5px",
                    }}
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </Button>
                ) : (
                  <Button
                    variant="dark"
                    style={{
                      backgroundColor: "#ef3340",
                      height: "35px",
                      width: "160px",
                      textAlign: "center",
                      marginLeft: "5px",
                    }}
                    onClick={() => findEnquiresBetweenDates()}
                  >
                    Search Enquiry
                  </Button>
                )}
              </div>
            </div>
            <div
              className="restaurant-data-table"
              style={{ marginTop: "20px" }}
            >
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "red" }}>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align="left"
                          style={{
                            width: column.maxWidth,
                          }}
                        >
                          <b>{column.label}</b>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {allEnquiryData.length === 0 && isLoading ? (
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {allEnquiryData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow>
                              <TableCell>
                                {row?._id.substring(0, 10)}...
                              </TableCell>
                              <TableCell>
                                <Link to={`/user/${row?.userId}`}>
                                  <b style={{ color: "#ef3340" }}>
                                    {row?.userName}
                                  </b>
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={`/restaurant/${row?.restaurantId?._id}`}
                                >
                                  <b style={{ color: "#ef3340" }}>
                                    {row?.restaurantId?.restaurantName || "NA"}
                                  </b>
                                </Link>
                              </TableCell>
                              <TableCell>{row?.content || "NA"}</TableCell>
                              <TableCell>
                                {row?.enquiryDetail || "NA"}
                              </TableCell>
                              <TableCell>
                                {
                                  <Messages
                                    messages={row?.messages}
                                    userName={row?.userName}
                                    restaurantName={
                                      row?.restaurantId?.restaurantName
                                    }
                                    time={row?.date}
                                  />
                                }
                              </TableCell>
                              <TableCell>
                                {row?.date?.substring(0, 10)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={allEnquiryData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AllEnquires;
