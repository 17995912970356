import React, { useState, useEffect } from "react";
import RightSectionRestaurant from "./RightSectionRestaurant/RightSection";
import clsx from "clsx";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Menus from "./Menus/Menu";
import Reviews from "./Reviews/Reviews";
import Bookings from "./Bookings/Bookings";
import Payments from "./Payments/Payment";
import TermsAndCondition from "./TermsAndCondition/TermsAndCondition";
import AdvanceBooking from "./AdvanceBookings/AdvanceBooking";
import Skeleton from "@mui/material/Skeleton";
import { useHistory } from "react-router-dom";
import SideBar from "./SideBar";
import axios from "axios";
import { baseURL } from "../../../BaseURL";
import './tabUnderline.css'

const SingleRestaurant = (props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(1);
  const restaurantId = props.match.params.id;
  const [percentage, setPercentage] = useState(0);
  const history = useHistory();
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [imagesArray, setImagesArray] = useState([]);
  const [totalRestaurantMenus, setTotalRestaurantMenus] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingThumbnail, setLoadingThumbnail] = useState(false);
  const [floorDetail, setFloorDetail] = useState({
    floorName: "",
    restaurantId: restaurantId,
  });
  const [totalFloors, setTotalFloors] = useState([]);
  const [maximumTableCapacityArray, setMaximumTableCapacityArray] = useState(
    []
  );
  const [totalTableHaultTime, setTotalTableHaultTime] = useState([]);
  const [restroData, setRestroData] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    axios
      .get(baseURL + "/restaurant/get-weekly-bookings/" + restaurantId)
      .then((res) => {
        console.log(res.data.data);
        setPercentage(res.data.data);
      });
  }, [isLoading]);

  useEffect(() => {
    axios
      .get(
        baseURL + "/restaurant/floors/get-floors-of-restaurant/" + restaurantId
      )
      .then((res) => {
        console.log(res.data.data);
        setTotalFloors(res.data.data);
      })
      .catch((err) => {
        console.log("Error");
      });
  }, [isLoading]);

  function addFloor() {
    setLoading(true);
    axios
      .post(baseURL + "/restaurant/floors/create-floor", floorDetail)
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);
        setFloorDetail({ ...floorDetail, floorName: "" });
        handleClose2();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //make a function that will be called when the file is selected and conver the file to base64
  const handleFile = async (file) => {
    // `current` points to the mounted file input element
    const reader = new FileReader();

    reader.onload = async (e) => {
      console.log(e.target.result);
      setThumbnailImage(e.target.result);
      setLoadingThumbnail(true);
      const formData = new FormData();
      formData.append("restaurantThumbnail", file);
      const res = await axios.post(
        baseURL + "/restaurant/upload-restaurant-thumbnail/" + restaurantId,
        formData
      );
      console.log(res.data);
      setThumbnailImage(res.data.data.restaurantThumbnail);
      setLoadingThumbnail(false);
    };
    reader.readAsDataURL(file);
  };

  //make a function that takes a object filelist and loops through it and converts it to base64 and set it to the images array

  const handleFileList = async (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      console.log(e.target.result);
      setLoading(true);
      const formData = new FormData();
      formData.append("restaurantImage", file);
      const res = await axios.post(
        baseURL + "/restaurant/upload-restaurant-images/" + restaurantId,
        formData
      );
      console.log(res.data);
      setImagesArray(res.data.data.restaurantImages);
      setLoading(false);
    };
    reader.readAsDataURL(file);
  };

  // make a function to remove image from array by taking the index of the image and removing it from the array
  const removeImage = (e, index) => {
    e.preventDefault();
    setImagesArray(imagesArray.filter((image, i) => i !== index));
  };

  function handleChange(e) {
    console.log(
      e.target.parentNode.parentNode.parentNode.querySelector("input").value
    );
    let peopleCapacity =
      e.target.parentNode.parentNode.parentNode.querySelector("input").value;
    peopleCapacity = parseInt(peopleCapacity);
    console.log(typeof peopleCapacity);
    totalTableHaultTime.map((timing) => {
      if (timing.peopleCapacity === peopleCapacity)
        timing.totalTableTime = parseInt(e.target.value);
    });

    console.log(totalTableHaultTime);
  }

  async function updateHaultTime(timing) {
    console.log(totalTableHaultTime);
    const formData = {
      restaurantTableHaultTime: totalTableHaultTime,
    };
    setLoading(true);
    await axios
      .post(baseURL + "/restaurant/update-restaurant/" + restaurantId, formData)
      .then((res) => {
        console.log(res.data);
        setLoading(false);

        handleClose3();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    setTotalTableHaultTime([]);
    setMaximumTableCapacityArray([]);
    // setLoading(true);
    axios
      .get(baseURL + "/restaurant/get-restaurant-by-id/" + restaurantId)
      .then((res) => {
        console.log(res.data.data[0]);
        setRestroData(res.data.data);
        setTotalRestaurantMenus(res.data.data[0].restaurantMenu);
        const maximumTableCapacity =
          res.data.data[0].restaurantMaximumTableCapacity;
        for (var i = 1; i <= maximumTableCapacity; i++) {
          setMaximumTableCapacityArray((prev) => [...prev, i]);
          const timing = {
            peopleCapacity: i,
            totalTableTime: 0,
          };
          setTotalTableHaultTime((prev) => [...prev, timing]);
        }
        console.log(maximumTableCapacityArray);
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
        console.log(err);
      });
  }, [isLoading]);

  return (
    <div className="w-full bg-gray-100  min-h-screen">
      <div className="bg-white border border-b-2 border-gray-300 p-2 px-3">
        <p className="text-gray-600 text-xs font-semibold ml-3">
          All Restaurants -{" "}
          {(restroData && restroData[0]?.restaurantName) || (
            <Skeleton width={100} />
          )}{" "}
          - Summary
        </p>

        <div className="text-gray-700 text-lg my-2 flex items-center mr-6 ml-3 font-bold">
          Showing :{" "}
          {(restroData && restroData[0]?.restaurantName) || (
            <Skeleton width={200} />
          )}
          <span className="flex items-center ml-6 ">
            || &nbsp; Rated :{" "}
            {(restroData &&
              restroData[0]?.restaurantRating.toString().substring(0, 3)) ||
              0}
            <svg
              width="16"
              height="16"
              viewBox="0 0 25 24"
              fill="none"
              className="-mt-1 ml-2"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.4166 0C12.826 0 13.2 0.243278 13.3812 0.627556L16.4553 7.14556L23.3297 8.19722C23.7348 8.25919 24.0712 8.55643 24.1974 8.96402C24.3237 9.37161 24.218 9.81888 23.9247 10.1178L18.9513 15.1878L20.125 22.3504C20.1942 22.7728 20.0283 23.1998 19.697 23.4517C19.3657 23.7036 18.9265 23.7368 18.564 23.5373L12.4166 20.1536L6.26913 23.5373C5.90669 23.7368 5.46747 23.7036 5.13616 23.4517C4.80485 23.1998 4.63893 22.7728 4.70815 22.3504L5.88189 15.1878L0.908449 10.1178C0.615178 9.81888 0.509461 9.37161 0.635731 8.96402C0.762 8.55643 1.09837 8.25919 1.50345 8.19722L8.37792 7.14556L11.452 0.627556C11.6332 0.243278 12.0071 0 12.4166 0Z"
                fill="#F4B13E"
              />
            </svg>
          </span>
        </div>

        <ul className="list-none flex items-center w-3/4 font-light -mb-0">
          <li
            onClick={() => setActiveTabIndex(1)}
            className={clsx(
              "relative text-sm font-semibold text-gray-500 rounded-sm px-3 p-0 cursor-pointer",
              { ["bg-white text-red-400 "]: activeTabIndex === 1 }
            )}
          >{
            activeTabIndex === 1 ? <div className="bottom_underline"></div> : ''
          }
            Summary
          </li>
          <li
            onClick={() => setActiveTabIndex(2)}
            className={clsx(
              "relative text-sm font-semibold text-gray-500 rounded-sm px-3 cursor-pointer",
              { ["bg-white text-red-400"]: activeTabIndex === 2 }
            )}
          >{
            activeTabIndex === 2 ? <div className="bottom_underline"></div> : ''
          }
            Bookings
          </li>
          <li
            onClick={() => setActiveTabIndex(3)}
            className={clsx(
              "relative text-sm font-semibold text-gray-500 rounded-sm px-3 cursor-pointer",
              { ["bg-white text-red-400"]: activeTabIndex === 3 }
            )}
          >{
            activeTabIndex === 3 ? <div className="bottom_underline"></div> : ''
          }
            Payments
          </li>
          <li
            onClick={() => setActiveTabIndex(4)}
            className={clsx(
              "relative text-sm font-semibold text-gray-500 rounded-sm px-3 cursor-pointer",
              { ["bg-white text-red-400"]: activeTabIndex === 4 }
            )}
          >{
            activeTabIndex === 4 ? <div className="bottom_underline"></div> : ''
          }
            Reviews
          </li>
          <li
            onClick={() => setActiveTabIndex(5)}
            className={clsx(
              "relative text-sm font-semibold text-gray-500 rounded-sm px-3 cursor-pointer",
              { ["bg-white text-red-400"]: activeTabIndex === 5 }
            )}
          >{
            activeTabIndex === 5 ? <div className="bottom_underline"></div> : ''
          }
            Menu
          </li>

          <li
            onClick={() => setActiveTabIndex(6)}
            className={clsx(
              "relative text-sm font-semibold text-gray-500 rounded-sm px-3 cursor-pointer",
              { ["bg-white text-red-400"]: activeTabIndex === 6 }
            )}
          >{
            activeTabIndex === 6 ? <div className="bottom_underline"></div> : ''
          }
            Advance Booking
          </li>
          <li
            onClick={() => setActiveTabIndex(7)}
            className={clsx(
              "relative text-sm font-semibold text-gray-500 rounded-sm px-3 cursor-pointer",
              { ["bg-white text-red-400"]: activeTabIndex === 7 }
            )}
          >{
            activeTabIndex === 7 ? <div className="bottom_underline"></div> : ''
          }
            T&Cs
          </li>
        </ul>
      </div>
      {activeTabIndex === 1 && (
        <div className="w-full flex  min-h-screen ">
          <div className="cus_shadow w-3/12">
            {restaurantId && (
              <SideBar
                activeTabIndex={activeTabIndex}
                setActiveTabIndex={setActiveTabIndex}
                restaurantId={restaurantId}
                restroData={restroData}
                setLoading={setLoading}
                isLoading={isLoading}
              />
            )}
          </div>

          <div className="w-9/12 p-3 ">
            <div className="flex justify-between">
              <div className="relative cus_shadow flex-1 m-4 h-48 bg-white rounded-md">
                <div className="">
                  <div className="flex justify-between items-center mt-3 px-3">
                    <h6 class="font-bold">Weekly Booking</h6>
                    <p
                      className="absolute bottom-1 right-3 text-xs font-medium text-indigo-600 mb-2 cursor-pointer hover:underline"
                      onClick={() => setActiveTabIndex(2)}
                    >
                      View
                    </p>
                  </div>

                  <div className="m-3 mx-auto h-28 w-28">
                    <CircularProgressbar
                      class="bg-indigo-900"
                      value={percentage}
                      text={`${percentage}%`}
                    />
                  </div>
                </div>
              </div>

              <div className="relative cus_shadow flex-1 m-4 h-48 bg-white rounded-md">
                <div className="font-semibold">
                  <div className="flex justify-between items-center mt-3 px-3">
                    <h6 class="font-bold">Pending Payments</h6>
                    <p
                      className="absolute bottom-1 right-3 text-xs font-medium text-indigo-500 mb-2 cursor-pointer hover:underline"
                      onClick={() => setActiveTabIndex(3)}
                    >
                      View
                    </p>
                  </div>

                  <div className="flex items-center">
                    <p className="bg-red-200 text-red-700 w-12 text-center font-medium text-2xl ml-3">
                      1
                    </p>
                    <p className="text-xs font-medium text-gray-500 m-3">
                      {restroData[0]?.restaurantPricePerPerson} SGD due for
                      platform subscription fee Everything.
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="bg-yellow-200 text-yellow-700  text-center w-12 font-medium text-2xl ml-3">
                      2
                    </p>
                    <p className="text-xs font-medium text-gray-500 m-3">
                      2000.00 SGD due for platform subscription fee Everything.
                    </p>
                  </div>
                </div>
              </div>

              <div className="relative cus_shadow flex-1 m-4 bg-white rounded-md">
                <div className="font-semibold">
                  <div className="flex justify-between items-center mt-3 px-3">
                    <h6 class="font-bold">Customer's Feedback</h6>
                    <p
                      className="absolute bottom-1 right-3 text-xs font-medium text-indigo-500 mb-2 cursor-pointer hover:underline"
                      onClick={() => setActiveTabIndex(4)}
                    >
                      View
                    </p>
                  </div>
                  <div className="flex ml-3">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.4166 0C12.826 0 13.2 0.243278 13.3812 0.627556L16.4553 7.14556L23.3297 8.19722C23.7348 8.25919 24.0712 8.55643 24.1974 8.96402C24.3237 9.37161 24.218 9.81888 23.9247 10.1178L18.9513 15.1878L20.125 22.3504C20.1942 22.7728 20.0283 23.1998 19.697 23.4517C19.3657 23.7036 18.9265 23.7368 18.564 23.5373L12.4166 20.1536L6.26913 23.5373C5.90669 23.7368 5.46747 23.7036 5.13616 23.4517C4.80485 23.1998 4.63893 22.7728 4.70815 22.3504L5.88189 15.1878L0.908449 10.1178C0.615178 9.81888 0.509461 9.37161 0.635731 8.96402C0.762 8.55643 1.09837 8.25919 1.50345 8.19722L8.37792 7.14556L11.452 0.627556C11.6332 0.243278 12.0071 0 12.4166 0Z"
                        fill="#F4B13E"
                      />
                    </svg>

                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.4166 0C12.826 0 13.2 0.243278 13.3812 0.627556L16.4553 7.14556L23.3297 8.19722C23.7348 8.25919 24.0712 8.55643 24.1974 8.96402C24.3237 9.37161 24.218 9.81888 23.9247 10.1178L18.9513 15.1878L20.125 22.3504C20.1942 22.7728 20.0283 23.1998 19.697 23.4517C19.3657 23.7036 18.9265 23.7368 18.564 23.5373L12.4166 20.1536L6.26913 23.5373C5.90669 23.7368 5.46747 23.7036 5.13616 23.4517C4.80485 23.1998 4.63893 22.7728 4.70815 22.3504L5.88189 15.1878L0.908449 10.1178C0.615178 9.81888 0.509461 9.37161 0.635731 8.96402C0.762 8.55643 1.09837 8.25919 1.50345 8.19722L8.37792 7.14556L11.452 0.627556C11.6332 0.243278 12.0071 0 12.4166 0Z"
                        fill="#F4B13E"
                      />
                    </svg>

                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.4166 0C12.826 0 13.2 0.243278 13.3812 0.627556L16.4553 7.14556L23.3297 8.19722C23.7348 8.25919 24.0712 8.55643 24.1974 8.96402C24.3237 9.37161 24.218 9.81888 23.9247 10.1178L18.9513 15.1878L20.125 22.3504C20.1942 22.7728 20.0283 23.1998 19.697 23.4517C19.3657 23.7036 18.9265 23.7368 18.564 23.5373L12.4166 20.1536L6.26913 23.5373C5.90669 23.7368 5.46747 23.7036 5.13616 23.4517C4.80485 23.1998 4.63893 22.7728 4.70815 22.3504L5.88189 15.1878L0.908449 10.1178C0.615178 9.81888 0.509461 9.37161 0.635731 8.96402C0.762 8.55643 1.09837 8.25919 1.50345 8.19722L8.37792 7.14556L11.452 0.627556C11.6332 0.243278 12.0071 0 12.4166 0Z"
                        fill="#F4B13E"
                      />
                    </svg>

                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.4166 0C12.826 0 13.2 0.243278 13.3812 0.627556L16.4553 7.14556L23.3297 8.19722C23.7348 8.25919 24.0712 8.55643 24.1974 8.96402C24.3237 9.37161 24.218 9.81888 23.9247 10.1178L18.9513 15.1878L20.125 22.3504C20.1942 22.7728 20.0283 23.1998 19.697 23.4517C19.3657 23.7036 18.9265 23.7368 18.564 23.5373L12.4166 20.1536L6.26913 23.5373C5.90669 23.7368 5.46747 23.7036 5.13616 23.4517C4.80485 23.1998 4.63893 22.7728 4.70815 22.3504L5.88189 15.1878L0.908449 10.1178C0.615178 9.81888 0.509461 9.37161 0.635731 8.96402C0.762 8.55643 1.09837 8.25919 1.50345 8.19722L8.37792 7.14556L11.452 0.627556C11.6332 0.243278 12.0071 0 12.4166 0Z"
                        fill="#F4B13E"
                      />
                    </svg>

                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.4166 0C12.826 0 13.2 0.243278 13.3812 0.627556L16.4553 7.14556L23.3297 8.19722C23.7348 8.25919 24.0712 8.55643 24.1974 8.96402C24.3237 9.37161 24.218 9.81888 23.9247 10.1178L18.9513 15.1878L20.125 22.3504C20.1942 22.7728 20.0283 23.1998 19.697 23.4517C19.3657 23.7036 18.9265 23.7368 18.564 23.5373L12.4166 20.1536L6.26913 23.5373C5.90669 23.7368 5.46747 23.7036 5.13616 23.4517C4.80485 23.1998 4.63893 22.7728 4.70815 22.3504L5.88189 15.1878L0.908449 10.1178C0.615178 9.81888 0.509461 9.37161 0.635731 8.96402C0.762 8.55643 1.09837 8.25919 1.50345 8.19722L8.37792 7.14556L11.452 0.627556C11.6332 0.243278 12.0071 0 12.4166 0Z"
                        fill="#F4B13E"
                      />
                    </svg>
                  </div>
                  {restroData[0]?.restaurantReview?.map((review) => (
                    <div>
                      <p className="text-xs font-medium text-gray-500 my-3 px-3">
                        {review.reviewMessage ? review.reviewMessage : "NA"}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="cus_shadow" style={{ margin: "5px 22px" }}>
              <RightSectionRestaurant
                restaurantData={restroData[0]}
                restaurantId={restaurantId}
                isLoading={isLoading}
                setLoading={setLoading}
              />
            </div>
          </div>
        </div>
      )}
      {activeTabIndex === 2 && <Bookings restaurantId={restaurantId} />}
      {activeTabIndex === 3 && <Payments restaurantId={restaurantId} />}
      {activeTabIndex === 4 && <Reviews restaurantId={restaurantId} />}
      {activeTabIndex === 5 && (
        <Menus
          restaurantId={restaurantId}
          restaurantMenus={restroData[0].restaurantMenu}
          isLoading={isLoading}
          setLoading={setLoading}
        />
      )}
      {activeTabIndex === 6 && <AdvanceBooking restaurantId={restaurantId} />}
      {activeTabIndex === 7 && (
        <TermsAndCondition
          restaurantId={restaurantId}
          restaurantTermsAndCondition={
            restroData[0].restaurantTermsAndConditions
          }
          isLoading={isLoading}
          setLoading={setLoading}
        />
      )}
    </div>
  );
};

export default SingleRestaurant;
