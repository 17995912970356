import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  FormControl,
  Badge,
  Spinner,
} from "react-bootstrap";
import { baseURL } from "../../../../BaseURL";
import { useSnackbar } from "notistack";

function HaultTimingsTab({
  restaurantId,
  restaurantData,
  isLoading,
  setLoading,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [totalTableHaultTime, setTotalTableHaultTime] = useState([]);
  const [maximumTableCapacityArray, setMaximumTableCapacityArray] = useState(
    []
  );
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const maximumTableCapacity = restaurantData.restaurantMaximumTableCapacity;
    const tableHaultTiming = restaurantData.restaurantTableHaultTime;
    for (var i = 1; i <= maximumTableCapacity; i++) {
      setMaximumTableCapacityArray((prev) => [...prev, i]);
      const timing = {
        peopleCapacity: i,
        totalTableTime: tableHaultTiming[i - 1]?.totalTableTime,
      };
      setTotalTableHaultTime((prev) => [...prev, timing]);
    }
  }, []);

  function handleChange(e) {
    // console.log(
    //   e.target.parentNode.parentNode.parentNode.querySelector("input").value
    // );
    let peopleCapacity =
      e.target.parentNode.parentNode.parentNode.querySelector("input").value;
    peopleCapacity = parseInt(peopleCapacity);
    console.log(typeof peopleCapacity);
    totalTableHaultTime.map((timing) => {
      if (timing.peopleCapacity === peopleCapacity)
        timing.totalTableTime = parseInt(e.target.value);
    });
  }

  async function updateHaultTime(timing) {
    // console.log(totalTableHaultTime);
    const formData = {
      restaurantTableHaultTime: totalTableHaultTime,
    };
    setLoading(true);
    await axios
      .post(baseURL + "/restaurant/update-restaurant/" + restaurantId, formData)
      .then((res) => {
        setLoading(false);
        enqueueSnackbar("Hault timing updated successfully", {
          variant: "success",
        });
        handleClose();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>
          <b>
            Maximum Table Capacity :{" "}
            {restaurantData?.restaurantMaximumTableCapacity}
          </b>
        </p>
        <button
          className="text-white font-bold px-4 py-2 rounded-md"
          style={{ backgroundColor: "#EF3340" }}
          onClick={() => handleShow()}
        >
          Update Hault Timing
        </button>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body style={{ textAlign: "center", fontSize: "18px" }}>
            <b>Set Hault Timings</b>
          </Modal.Body>
          <div style={{ padding: "0 10px" }}>
            {totalTableHaultTime.length !== 0 &&
              totalTableHaultTime.map((value, index) => (
                <Row sm={12}>
                  <Col>
                    <label>Table Capacity</label>
                    <InputGroup>
                      <FormControl
                        placeholder="Table Capacity"
                        aria-label="Table Capacity"
                        value={value.peopleCapacity}
                        disabled
                      />
                    </InputGroup>
                  </Col>

                  <Col>
                    <label>Table Hault Time</label>
                    <InputGroup>
                      <FormControl
                        placeholder="Table Hault Time"
                        aria-label="Table Hault Time"
                        defaultValue={value.totalTableTime}
                        onChange={(e) => handleChange(e)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              ))}
          </div>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: "#EF3340" }}
              onClick={updateHaultTime}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="ml-4 bg-white px-2 mt-3 rounded-sm min-h-10">
          {restaurantData?.restaurantTableHaultTime.length === 0 ? (
            <p class="py-3 text-gray-500">
              No Hault Timings found.Please add one
            </p>
          ) : (
            totalTableHaultTime?.map((haultTiming) => (
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "350px",
                  backgroundColor: "#39424e",
                  margin: "5px 0",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                <p>
                  <b>For {haultTiming.peopleCapacity} people </b>
                </p>
                <Badge
                  bg="secondary"
                  style={{
                    color: "white",
                    padding: "8px",
                  }}
                  class="ml-62 p-2 bg-gray-500 rounded-md text-sm"
                >
                  {haultTiming.totalTableTime} mins
                </Badge>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default HaultTimingsTab;
