import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Modal, InputGroup, FormControl } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../../../BaseURL";
import moment from "moment";

const TAX_RATE = 0.07;

// function ccyFormat(num) {
//   return `${num?.toFixed(2)}`;
// }

// function priceRow(qty, unit) {
//   return qty * unit;
// }

// function createRow(desc, qty, unit) {
//   const price = priceRow(qty, unit);
//   return { desc, qty, unit, price };
// }

// function subtotal(items) {
//   return items
//     .map(({ subscriptionPrice }) => subscriptionPrice)
//     .reduce((sum, i) => sum + i, 0);
// }

// function subtotalMonthly(items) {
//   return items
//     .map(({ totalAmount }) => totalAmount)
//     .reduce((sum, i) => sum + i, 0);
// }
// const rows = [
//   createRow("Paperclips (Box)", 100, 1.15),
//   createRow("Paper (Case)", 10, 45.99),
//   createRow("Waste Basket", 2, 17.99),
// ];

function GenerateInvoice({ restaurantId }) {
  const [show, setShow] = useState(false);
  const [pendingPayments, setAllPendingPayments] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [createdBy, setCreatedBy] = useState("");

  //   const invoiceSubtotal =
  //     subtotal(pendingPayments?.pendingPlatformSubscriptions) +
  //     subtotalMonthly(pendingPayments?.pendingMonthlyRental);
  //   const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  //   //   const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  useEffect(() => {
    axios
      .get(baseURL + "/restaurant/get-pending-invoice-details/" + restaurantId)
      .then((res) => {
        setAllPendingPayments(res.data.data);
        setTotalPrice(
          res.data.data?.pendingMonthlyRentals
            ?.map(({ totalAmount }) => totalAmount)
            .reduce((sum, i) => sum + i, 0) +
            res.data.data?.pendingPlatformSubscriptions
              ?.map(({ subscriptionPrice }) => subscriptionPrice)
              .reduce((sum, i) => sum + i, 0)
        );
        // setMonthlyRentalId(res.data.data.pendingMonthlyRental.map((monthlyRental)=>monthlyRental._id))
        // setMonthlyRentalId(res.data.data.pendingMonthlyRental.map((monthlyRental)=>monthlyRental._id))
      });
  }, []);

  async function handleSubmit() {
    const platformSubscription =
      pendingPayments?.pendingPlatformSubscriptions?.map(
        (pendingPayment) => pendingPayment._id
      );
    const monthlyRental = pendingPayments?.pendingMonthlyRentals?.map(
      (monthlyRental) => monthlyRental._id
    );
    const advanceOffsetBookings = pendingPayments?.advanceOffsetBookings?.map(
      (offsetBooking) => offsetBooking._id
    );
    console.log(platformSubscription);
    console.log(monthlyRental);
    console.log(advanceOffsetBookings);

    const body = {
      restaurantId: restaurantId,
      platformSubscription,
      monthlyRental,
      advanceOffsetBookings,
      createdBy: createdBy,
    };

    await axios
      .post(baseURL + "/restaurant/create-invoice", body)
      .then((res) => {
        console.log(res.data.data);
        setShow(false);
      })
      .catch((Err) => console.log(Err));
  }

  return (
    <div>
      <>
        <Button
          variant="primary"
          onClick={handleShow}
          style={{
            backgroundColor: "#ef3340",
            height: "35px",
            width: "160px",
            textAlign: "center",
            marginLeft: "5px",
          }}
        >
          Generate Invoice
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Generate Invoice</Modal.Title>
          </Modal.Header>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Details
                  </TableCell>
                  <TableCell align="right" colSpan={3}>
                    Price
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Desc</TableCell>
                  <TableCell align="right">Qty.</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Sum</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingPayments?.pendingPlatformSubscriptions?.map((row) => (
                  <TableRow key={row.subscriptionRemark}>
                    <TableCell>
                      <b>{row.subscriptionRemark}</b>{" "}
                      <p>
                        {moment(row.subscriptionStartDate).format("YYYY-MM-DD")}
                        - {moment(row.subscriptionEndDate).format("YYYY-MM-DD")}
                      </p>
                    </TableCell>
                    <TableCell align="right">1</TableCell>
                    <TableCell align="right">{row.paymentStatus}</TableCell>
                    <TableCell align="right">{row.subscriptionPrice}</TableCell>
                  </TableRow>
                ))}
                {pendingPayments?.pendingMonthlyRentals?.map((row) => (
                  <TableRow key={row.subscriptionRemark}>
                    <TableCell>
                      <b>
                        {row.subscriptionRemark
                          ? row.subscriptionRemark
                          : "Monthly Rental"}
                      </b>
                      <p>
                        {moment(row.startDate).format("YYYY-MM-DD")}-{" "}
                        {moment(row.endDate).format("YYYY-MM-DD")}
                      </p>
                    </TableCell>
                    <TableCell align="right">1</TableCell>
                    <TableCell align="right">{row.paymentStatus}</TableCell>
                    <TableCell align="right">{row.totalAmount}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={2}>Subtotal</TableCell>
                  <TableCell align="right">{totalPrice}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tax</TableCell>
                  <TableCell align="right">{`${(TAX_RATE * 100).toFixed(
                    0
                  )} %`}</TableCell>
                  <TableCell align="right">
                    {(totalPrice * TAX_RATE).toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Total</TableCell>
                  <TableCell align="right">
                    {(totalPrice * TAX_RATE + totalPrice).toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              padding: "20px 0",
            }}
          >
            <div>
              <InputGroup style={{ width: "90%" }}>
                <FormControl
                  placeholder="Created By"
                  aria-label="Created By"
                  onChange={(e) => {
                    setCreatedBy(e.target.value);
                  }}
                />
              </InputGroup>
            </div>
            <Button
              style={{
                backgroundColor: "#ef3340",
                height: "35px",
                width: "160px",
                textAlign: "center",
                marginLeft: "5px",
              }}
              onClick={handleSubmit}
            >
              Create Invoice
            </Button>
          </div>
        </Modal>
      </>
    </div>
  );
}

export default GenerateInvoice;
