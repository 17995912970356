import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Spinner,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useSnackbar } from "notistack";
import { baseURL } from "../../../BaseURL";

function EditSubscription({
  isLoading,
  setLoading,
  subscriptionPrice,
  subscriptionMonth,
  subscriptionId,
}) {
  const [show, setShow] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    subscriptionMonth: subscriptionMonth,
    subscriptionPrice: 0,
    subscriptionId: subscriptionId,
  });

  async function handleSubmit() {
    setLoading(true);
    await axios
      .patch(baseURL + "/admin/update-subscription-plan", formData)
      .then((res) => {
        if (res.data.data) {
          enqueueSnackbar("Subscription updated successfully", {
            variant: "success",
          });
          setLoading(false);
          setShow(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        setLoading(false);
      });
  }

  return (
    <div>
      <IconButton size="small" onClick={handleShow}>
        <EditIcon style={{ color: "whitesmoke" }} />
      </IconButton>
      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          <Row>
            <Col sm={6}>
              <label>Subscription Month</label>
              <InputGroup>
                <FormControl value={subscriptionMonth} aria-label="Adv Link" />
              </InputGroup>
            </Col>
            <Col sm={6}>
              <label>Subscription Price</label>
              <InputGroup>
                <FormControl
                  placeholder="Enter Price"
                  defaultValue={subscriptionPrice}
                  type="number"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      subscriptionPrice: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "30px",
                width: "90px",
                marginLeft: "5px",
              }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "30px",
                width: "70px",
                textAlign: "center",
                marginLeft: "5px",
              }}
              onClick={handleSubmit}
            >
              Edit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditSubscription;
