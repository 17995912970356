import React, { useState, useEffect } from "react";
import { InputGroup, FormControl, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../../BaseURL";
import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import RestaurantTable from "../../master-settings/RestaurantTable";

function Featured() {
  const [allFeaturedRestaurants, setAllFeaturedRestaurants] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedRestaurants, setSelectedRestaurants] = useState([]);
  const [allRestaurants, setAllRestaurants] = useState([]);
  const [currOpen, setCurrOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    let mounted = true;

    const fetchSelectedRestaurants = async () => {
      try {
        const res = await axios.get(
          baseURL + "/restaurant/get-featured-restaurants"
        );
        console.log(res);
        if (mounted) {
          setSelectedRestaurants(res?.data?.featuredRestaurants);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchSelectedRestaurants();

    return () => {
      mounted = false;
    };
  }, [refetch, isLoading]);

  useEffect(() => {
    let mounted = true;

    const fetchAllRestaurants = async () => {
      try {
        const res = await axios.get(
          baseURL + "/restaurant/get-all-restaurants"
        );
        if (mounted) {
          setAllRestaurants(res.data.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchAllRestaurants();

    return () => {
      mounted = false;
    };
  }, []);

  const updateRecommendation = async (id, values) => {
    const body = { restaurantId: values };
    try {
      setLoading(true);
      const res = await axios.post(
        baseURL + "/restaurant/add-featured-restaurants",
        body
      );
      console.log(res.data.message);
      if (res.data.message || res.data.data) {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
    setRefetch((prev) => !prev);
    setCurrOpen(false);
    setLoading(false);
  };

  const updateState = (e) => {
    if (e === currOpen) {
      setCurrOpen(true);
    } else {
      setCurrOpen(e);
    }
  };

  useEffect(() => {
    axios
      .get(baseURL + "/restaurant/get-featured-restaurants")
      .then((res) => {
        if (res.data.featuredRestaurants)
          setAllFeaturedRestaurants(res.data.featuredRestaurants);
        else if (res.data.message) setAllFeaturedRestaurants([]);
      })
      .catch((Err) => {
        console.log(Err);
      });
  }, [isLoading]);
  return (
    <div style={{ backgroundColor: "white", marginTop: "-8px" }}>
      <div className="heading">
        <div>
          <p style={{ marginTop: "18px", fontSize: "16px" }}>
            <b>Total Featured Restaurants</b> : {allFeaturedRestaurants.length}
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItem: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="p-2 border m-2 border-gray-300 flex flex-row flex-wrap shadow-md rounded-md bg-gray-100 justify-between items-center"
          style={{
            width: "70%",
            marginLeft: "90px",
            backgroundColor: "#39424e",
            color: "white",
          }}
        >
          <div className="w-4/12 text-md pl-16">
            <b>Featured Restaurants</b>
          </div>
          <div
            className="w-4/12 text-md pl-24"
            onClick={() => updateState("selected")}
            style={{ cursor: "pointer" }}
          >
            {allFeaturedRestaurants?.length} restaurants
          </div>
          <div className="w-2/12 ml-6 pl-12" onClick={() => updateState("all")}>
            <AddIcon style={{ cursor: "pointer" }} />
          </div>
          <div className="w-full">
            {currOpen === "selected" ? (
              <RestaurantTable
                rows={selectedRestaurants}
                model={selectedRestaurants}
                updateRecommendation={updateRecommendation}
              />
            ) : currOpen === "all" ? (
              <RestaurantTable
                rows={allRestaurants}
                model={selectedRestaurants}
                updateRecommendation={updateRecommendation}
                isLoading={isLoading}
                setLoading={setLoading}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Featured;
