import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import List from "@mui/material/List";
import CircularProgress from "@mui/material/CircularProgress";
import {
  InputGroup,
  FormControl,
  Row,
  Col,
  Spinner,
  Button,
  Modal,
} from "react-bootstrap";
import ListItemButton from "@mui/material/ListItemButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ListItem } from "@mui/material";
import { baseURL } from "../../../BaseURL";
import TableLayout from "./TableLayout";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";

function Floor({
  floorId,
  floorName,
  restaurantId,
  isFloorLoading,
  setFloorLoading,
  maxTableSize,
}) {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [floorDetail, setFloorDetail] = useState({
    floorName,
  });
  const [deleteFloor, setDeleteFloor] = useState(false);
  const [newTable, setNewTable] = useState({
    tableName: "",
    tableCapacity: 0,
    tableStatus: "Available",
    floorId: floorId,
    restaurantId: restaurantId,
    sortIndex: 0,
  });
  const [totalTables, setTotalTables] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingTable, setLoadingTable] = useState("");

  useEffect(() => {
    console.log(floorId);
    axios
      .get(baseURL + "/restaurant/tables/get-tables-of-floors/" + floorId)
      .then((res) => {
        setTotalTables(res.data.data);
        setNewTable({ ...newTable, tableName: "", tableCapacity: 0 });
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        setLoading(false);
      });
  }, [isLoading, isLoadingTable]);

  function addTable() {
    setLoading(true);
    newTable.sortIndex = totalTables.length;
    axios
      .post(baseURL + "/restaurant/tables/createTable", newTable)
      .then((res) => {
        enqueueSnackbar("Table added successfully", { variant: "success" });
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        setLoading(false);
      });
  }

  const handleEditFloor = async () => {
    setFloorLoading(true);
    await axios
      .put(baseURL + `/restaurant/floors/edit-floor/${floorId}`, floorDetail)
      .then((res) => {
        enqueueSnackbar("Floor updated successfully", { variant: "success" });
        setFloorLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        setFloorLoading(false);
      });
    setEdit(false);
  };

  const handleDeleteFloor = async () => {
    setFloorLoading(true);
    await axios
      .delete(baseURL + `/restaurant/floors/delete-floor/${floorId}`)
      .then((res) => {
        enqueueSnackbar("Floor deleted successfully", { variant: "success" });
        setFloorLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        setFloorLoading(false);
      });
    setDeleteFloor(false);
    setFloorLoading(false);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  async function handleDelete(tableId) {
    setLoadingTable(tableId);
    await axios
      .delete(baseURL + "/restaurant/tables/delete-table/" + tableId)
      .then((res) => {
        enqueueSnackbar("Table deleted successfully", { variant: "success" });
        setLoadingTable("");
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        setLoadingTable("");
      });
  }

  return (
    <>
      <div
        style={{
          margin: "30px 5px",
          border: "2px solid grey",
          borderRadius: "5px",
          width: "48rem",
        }}
      >
        <List style={{ padding: "0px" }}>
          <ListItemButton
            onClick={handleClick}
            style={{ padding: "5px", margin: "2px" }}
          >
            <div
              className="w-full text-md font-medium text-gray-700"
              style={{ margin: "2px" }}
            >
              {floorName}
            </div>
            <div className="flex flex-row flex-nowrap gap-8 mr-4">
              <IconButton>
                <EditIcon
                  style={{
                    cursor: "pointer",
                    fontSize: "large",
                    color: "black",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEdit(true);
                  }}
                />
              </IconButton>
              <IconButton>
                <DeleteIcon
                  style={{
                    cursor: "pointer",
                    fontSize: "large",
                    color: "#EF3340",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteFloor(true);
                  }}
                />
              </IconButton>
            </div>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit className="px-1.5">
            <List component="div" disablePadding style={{ margin: "5px 0" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ padding: "0 10px" }}>
                  <Row sm={2}>
                    <Col style={{ width: "30rem" }}>
                      <label>Table Name</label>
                      <InputGroup>
                        <FormControl
                          placeholder="Table Name"
                          aria-label="Table Name"
                          value={newTable.tableName}
                          onChange={(e) =>
                            setNewTable({
                              ...newTable,
                              tableName: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                    </Col>

                    <Col>
                      <label>Table Capacity</label>
                      <InputGroup>
                        <FormControl
                          placeholder="Table Capacity"
                          aria-label="Table Capacity"
                          type="number"
                          min="1"
                          max={maxTableSize.toString()}
                          value={newTable.tableCapacity}
                          onChange={(e) =>
                            setNewTable({
                              ...newTable,
                              tableCapacity: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </div>
                {isLoading === true ? (
                  <Button
                    variant="dark"
                    style={{ backgroundColor: "#ef3340", width: "8rem" }}
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: "5px" }}
                    />
                    <span className="visually-hidden">Loading...</span>
                  </Button>
                ) : (
                  <Button
                    variant="dark"
                    style={{
                      backgroundColor: "#ef3340",
                      width: "8rem",
                      fontWeight: "bold",
                      border: "none",
                      fontSize: "13px",
                      padding: "8px 0",
                    }}
                    disabled={
                      !newTable.tableName || !newTable.tableCapacity
                        ? true
                        : false
                    }
                    onClick={addTable}
                  >
                    Save Changes
                  </Button>
                )}
                <TableLayout
                  floorId={floorId}
                  isLoading={isLoading}
                  setLoading={setLoading}
                />
              </div>
            </List>
            <List className="grid grid-col-1 mx divide-y ">
              {totalTables.map((table, index) => (
                <ListItem
                  key={index}
                  style={{
                    backgroundColor: "whitesmoke",
                    borderRadius: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <div className="w-full px py-2 flex flex-nowrap justify-between items-center text-gray-800 px-2">
                    <div>
                      {index + 1}. {table.tableName}
                    </div>
                    <div>Capacity {table.tableCapacity}</div>
                    <img
                      src={`https://eatsezy.s3.amazonaws.com/seatingLayout/green${table.tableCapacity}.png`}
                      alt={table.tableName}
                    />
                    <div>
                      {isLoadingTable === table._id ? (
                        <CircularProgress
                          style={{
                            height: "20px",
                            width: "20px",
                            color: "black",
                          }}
                        />
                      ) : (
                        <DeleteIcon
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={(e) => handleDelete(table._id)}
                        />
                      )}
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </div>
      <Modal show={edit} onHide={() => setEdit(false)} centered>
        <Modal.Body style={{ textAlign: "center", fontSize: "18px" }}>
          <b>Edit Floor Name</b>
        </Modal.Body>
        <div style={{ padding: "0 10px" }}>
          <Row sm={12}>
            <Col>
              <label>Floor Name</label>
              <InputGroup>
                <FormControl
                  placeholder="Floor Name"
                  aria-label="Floor Name"
                  value={floorDetail.floorName}
                  onChange={(e) =>
                    setFloorDetail({
                      ...floorDetail,
                      floorName: e.target.value,
                    })
                  }
                />
              </InputGroup>
            </Col>
          </Row>
        </div>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setFloorDetail({ floorName });
              setEdit(false);
            }}
          >
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#EF3340" }}
            disabled={floorDetail.floorName === ""}
            onClick={handleEditFloor}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={deleteFloor}
        onHide={() => {
          setDeleteFloor(false);
        }}
        centered
      >
        <Modal.Body
          class="font-semibold"
          style={{
            textAlign: "center",
            margin: "10px",
            padding: "5px",
          }}
        >
          Are you sure you want to delete this floor?
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button variant="secondary" onClick={() => setDeleteFloor(false)}>
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "#EF3340", font: "500" }}
            onClick={handleDeleteFloor}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default React.memo(Floor);
