import React, { useState, useEffect, useRef } from "react";
import CoolAreaItem from "./CoolAreaItem";
import { InputGroup, FormControl, Button, Spinner } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import "../BaseStyles.css";
import axios from "axios";
import { baseURL } from "../../../BaseURL";
const CoolAreaTable = () => {
  const [allCoolAreas, setallCoolAreas] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [areaName, setareaName] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleSubmission = async () => {
    const formData = new FormData();

    formData.append("areaName", areaName);
    formData.append("areaImage", selectedFile);
    setLoading(true);
    console.log(areaName, selectedFile);

    await axios
      .post(baseURL + "/restaurant/areas/add-areas", formData)
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);
        setSelectedFile();
        setareaName(" ");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    try {
      axios.get(baseURL + "/restaurant/areas/get-all-areas").then((res) => {
        setallCoolAreas(res.data.data);
        console.log(res.data);
      });
    } catch (e) {
      console.log(e);
    }
  }, [isLoading]);

  return (
    <div
      className="wrapper"
      style={{ backgroundColor: "white", padding: "10px 5px 20px" }}
    >
      <div className="recommendation-table">
        <div className="heading">
          <div>
            <p style={{ marginTop: "-20px", fontSize: "16px" }}>
              <b>Total Cool Areas</b> : {allCoolAreas.length}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItem: "center",
              justifyContent: "space-between",
              width: "40rem",
            }}
          >
            <div>
              <label>Area Name</label>
              <InputGroup>
                <FormControl
                  placeholder="Area Name"
                  aria-label="Area Name"
                  value={areaName}
                  onChange={(e) => setareaName(e.target.value)}
                />
              </InputGroup>
            </div>

            <div>
              <label>Area Image</label>
              <InputGroup>
                <FormControl
                  type="file"
                  style={{ padding: "4px" }}
                  onChange={changeHandler}
                />
              </InputGroup>
            </div>
            {isLoading === true ? (
              <Button
                variant="dark"
                style={{
                  backgroundColor: "#ef3340",
                  height: "35px",
                  marginTop: "32px",
                }}
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </Button>
            ) : (
              <Button
                variant="dark"
                style={{
                  backgroundColor: "#ef3340",
                  width: "90px",
                  height: "35px",
                  marginTop: "32px",
                  textAlign: "center",
                }}
                onClick={() => handleSubmission()}
              >
                Add
              </Button>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {isLoading === true ? (
            <Skeleton />
          ) : (
            allCoolAreas.map((item, index) => {
              return (
                <CoolAreaItem item={item} index={index + 1} key={item._id} />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default CoolAreaTable;
