import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SingleMessage from "./SingleMessage";

function Messages({ messages, userName, restaurantName, time }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Button
        style={{
          backgroundColor: "#EF3340",
          margin: "0 5px",
        }}
        onClick={handleShow}
      >
        Show Messages
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>
            <b>Messages</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "500px" }}>
          {messages.map((message) => (
            <SingleMessage
              username={message?.user}
              message={message.message}
              userName={userName}
              restaurantName={restaurantName}
              time={time}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#39424e",
              margin: "0 5px",
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Messages;
