import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { baseURL } from "../../../BaseURL";
import axios from "axios";
import { useSnackbar } from "notistack";

function EditTechSupport({
  setLoading,
  isLoading,
  techSupportId,
  findIcon,
  isLive,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    isLive: isLive,
  });

  async function handleUpdate() {
    try {
      setLoading(true);
      const updatedTechSupport = await axios.patch(
        baseURL + "/admin/update-tech-support/" + techSupportId,
        formData
      );
      if (updatedTechSupport)
        enqueueSnackbar("Successfully updated", { variant: "success" });
      setLoading(false);
      handleClose();
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItem: "center",
          justifyContent: "space-between",
          textAlign: "left",
          cursor: "pointer",
        }}
        onClick={handleShow}
      >
        {findIcon(isLive)}
        {isLive === true ? "Pending" : "Resolved"}
      </div>
      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          <label style={{ marginTop: "10px" }}>Status</label>
          <select
            style={{
              height: "calc(1.5em + 1.2rem)",
              padding: ".375rem",
              border: "1px solid #ced4da",
              borderRadius: "5px",
              width: "50%",
              marginLeft: "10px",
            }}
            onChange={(e) => {
              setFormData({ ...formData, isLive: e.target.value });
            }}
          >
            <option style={{ padding: "5px", height: "30px" }}>Select</option>
            <option
              key="Pending"
              value={true}
              style={{ padding: "5px", height: "30px" }}
            >
              Pending
            </option>
            <option
              key="Resolved"
              value={false}
              style={{ padding: "5px", height: "30px" }}
            >
              Resolved
            </option>
          </select>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "30px",
                width: "90px",
                marginLeft: "5px",
              }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "30px",
                width: "70px",
                textAlign: "center",
                marginLeft: "5px",
              }}
              onClick={handleUpdate}
            >
              Yes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditTechSupport;
