import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Spinner,
  FormControl,
  InputGroup,
  Col,
  Row,
} from "react-bootstrap";
import { baseURL } from "../../../BaseURL";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Compressor from "compressorjs";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

function UpdateAdvertisment({ setLoading, isLoading, advertismentData }) {
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [advertismentName, setAdvertismentName] = useState(
    advertismentData.advertismentName
  );
  const [advertismentURL, setAdvertismentURL] = useState(
    advertismentData.advertismentURL
  );
  const [advertismentIsLive, setAdvertismentIsLive] = useState(
    advertismentData.advertismentIsLive
  );
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleSubmit() {
    const formData = {
      advertismentName: advertismentName,
      advertismentURL: advertismentURL,
      isLive: advertismentIsLive,
    };
    setLoading(true);
    await axios
      .post(
        baseURL + "/admin/update-advertisment/" + advertismentData._id,
        formData
      )
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <IconButton>
        <EditIcon onClick={handleShow} />
      </IconButton>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Update Advertisment</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row sm={12}>
            <Col>
              <label>Advertisment Name</label>
              <InputGroup>
                <FormControl
                  placeholder="Enter Adv Name"
                  aria-label="Adv Name"
                  defaultValue={advertismentData.advertismentName}
                  onChange={(e) => {
                    setAdvertismentName(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={6}>
              <label>Advertisment Link</label>
              <InputGroup>
                <FormControl
                  placeholder="Enter Adv Link"
                  aria-label="Adv Link"
                  defaultValue={advertismentData.advertismentURL}
                  onChange={(e) => {
                    setAdvertismentURL(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col>
              <label>Live</label>
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
                defaultValue={advertismentData.isLive}
                onChange={(e) => {
                  console.log(e.target.value);
                  setAdvertismentIsLive(
                    e.target.value === "true" ? true : false
                  );
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              onClick={() => handleSubmit()}
            >
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateAdvertisment;
