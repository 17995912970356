import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import { FormControl, InputGroup, Button, Spinner } from "react-bootstrap";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import { CsvBuilder } from "filefy";
import TablePagination from "@mui/material/TablePagination";
import { baseURL } from "../../BaseURL";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";

function Upcoming() {
  const [allUpcomingReservations, setAllUpcomingReservations] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  let filterBookings = [];
  const filterTable = (searchTerm) => {
    filterBookings = [];
    if (searchTerm === "") {
      filterBookings = allUpcomingReservations;
    } else {
      allUpcomingReservations.forEach((item) => {
        if (
          item.restaurantId.restaurantName
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) {
          filterBookings.push(item);
        }
      });
    }
  };
  filterTable(searchTerm);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function findIcon(status) {
    if (status === "CANCELLED" || status === "NO-SHOW")
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99996 1.99984C4.68625 1.99984 1.99996 4.68613 1.99996 7.99984C1.99996 11.3135 4.68625 13.9998 7.99996 13.9998C11.3137 13.9998 14 11.3135 14 7.99984C14 4.68613 11.3137 1.99984 7.99996 1.99984ZM0.666626 7.99984C0.666626 3.94975 3.94987 0.666504 7.99996 0.666504C12.05 0.666504 15.3333 3.94975 15.3333 7.99984C15.3333 12.0499 12.05 15.3332 7.99996 15.3332C3.94987 15.3332 0.666626 12.0499 0.666626 7.99984Z"
            fill="#D11534"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.4713 5.52827C10.7317 5.78862 10.7317 6.21073 10.4713 6.47108L6.47132 10.4711C6.21097 10.7314 5.78886 10.7314 5.52851 10.4711C5.26816 10.2107 5.26816 9.78862 5.52851 9.52827L9.52851 5.52827C9.78886 5.26792 10.211 5.26792 10.4713 5.52827Z"
            fill="#D11534"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.52851 5.52827C5.78886 5.26792 6.21097 5.26792 6.47132 5.52827L10.4713 9.52827C10.7317 9.78862 10.7317 10.2107 10.4713 10.4711C10.211 10.7314 9.78886 10.7314 9.52851 10.4711L5.52851 6.47108C5.26816 6.21073 5.26816 5.78862 5.52851 5.52827Z"
            fill="#D11534"
          />
        </svg>
      );
    else if (status === "UPCOMING")
      return (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1ZM0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7Z"
            fill="#738F93"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 6.5C7.27614 6.5 7.5 6.72386 7.5 7V9.66667C7.5 9.94281 7.27614 10.1667 7 10.1667C6.72386 10.1667 6.5 9.94281 6.5 9.66667V7C6.5 6.72386 6.72386 6.5 7 6.5Z"
            fill="#738F93"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.5 4.5C6.5 4.22386 6.72386 4 7 4H7.00667C7.28281 4 7.50667 4.22386 7.50667 4.5C7.50667 4.77614 7.28281 5 7.00667 5H7C6.72386 5 6.5 4.77614 6.5 4.5Z"
            fill="#738F93"
          />
        </svg>
      );
    else
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.442 2.51585C9.26012 1.98926 7.93972 1.8588 6.67767 2.14394C5.41563 2.42908 4.27957 3.11455 3.43893 4.0981C2.59828 5.08165 2.0981 6.31059 2.01297 7.60164C1.92783 8.89269 2.26232 10.1767 2.96654 11.2621C3.67075 12.3475 4.70697 13.1762 5.92064 13.6246C7.13432 14.073 8.46042 14.117 9.70117 13.7502C10.9419 13.3833 12.0309 12.6252 12.8056 11.589C13.5803 10.5527 13.9992 9.29371 14 7.99985V7.3869C14 7.01871 14.2984 6.72024 14.6666 6.72024C15.0348 6.72024 15.3333 7.01871 15.3333 7.3869V8.00024C15.3324 9.58161 14.8203 11.1207 13.8735 12.3873C12.9266 13.6539 11.5957 14.5804 10.0792 15.0288C8.56274 15.4772 6.94195 15.4233 5.45857 14.8753C3.97519 14.3273 2.70871 13.3144 1.848 11.9878C0.987288 10.6612 0.578473 9.09186 0.682522 7.51391C0.786572 5.93596 1.39791 4.43392 2.42536 3.2318C3.45282 2.02968 4.84134 1.1919 6.38383 0.84339C7.92633 0.494884 9.54016 0.65433 10.9846 1.29795C11.3209 1.4478 11.4721 1.84192 11.3222 2.17824C11.1724 2.51455 10.7783 2.66571 10.442 2.51585Z"
            fill="#068932"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.1378 2.19503C15.3982 2.45525 15.3984 2.87736 15.1382 3.13784L8.47156 9.81117C8.34656 9.9363 8.17695 10.0066 8.00009 10.0067C7.82322 10.0067 7.65358 9.93647 7.52851 9.8114L5.52851 7.8114C5.26816 7.55106 5.26816 7.12895 5.52851 6.8686C5.78886 6.60825 6.21097 6.60825 6.47132 6.8686L7.99968 8.39696L14.1949 2.1955C14.4552 1.93502 14.8773 1.93481 15.1378 2.19503Z"
            fill="#068932"
          />
        </svg>
      );
  }

  function extractRowsFromData() {
    const rows = [];
    allUpcomingReservations.forEach((upcomingReservations) => {
      let row = [];
      row.push(
        upcomingReservations?._id,
        upcomingReservations?.bookingName,
        upcomingReservations?.restaurantId?.restaurantName,
        upcomingReservations?.bookingPhone,
        upcomingReservations?.totalPeople,
        upcomingReservations?.finalPrice,
        upcomingReservations?.tableId?.tableName,
        upcomingReservations?.floorId?.floorName,
        upcomingReservations?.bookingDate,
        upcomingReservations?.startTime,
        upcomingReservations?.endTime,
        upcomingReservations?.createdAt.substring(0, 10),
        upcomingReservations?.status
      );

      //   console.log(row);
      rows.push(row);
    });

    return rows;
  }

  function exportToCSV() {
    var csvBuilder = new CsvBuilder("upcomingReservation_list.csv")
      .setColumns([
        "Id",
        "Guest Name",
        "Restaurant",
        "Contact",
        "Number of Guests",
        "Paid",
        "Table No",
        "Floor No",
        "Booked Date",
        "Booked Time",
        "End Time",
        "Created On",
        "Status",
      ])
      .addRows(extractRowsFromData())
      .exportFile();
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(baseURL + "/restaurant/bookings/get-all-upcoming-bookings")
      .then((res) => {
        setAllUpcomingReservations(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        setLoading(false);
      });
  }, []);
  return (
    <div>
      <div className="heading">
        <div>
          <p>
            <b>Total Bookings</b> :{" "}
            <span>{allUpcomingReservations?.length}</span>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            style={{
              width: "250px",
              backgroundColor: "#EF3340",
              margin: "0 5px",
            }}
            onClick={exportToCSV}
          >
            Export to Excel
          </Button>
          <InputGroup>
            <FormControl
              placeholder="Search Restaurants"
              aria-label="Search Restaurants"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </div>
      </div>
      <div className="data-table">
        <TableContainer
          style={{ border: "1px solid black", borderRadius: "5px" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Id.</b>
                </TableCell>
                <TableCell align="left">
                  <b>Guest Name</b>
                </TableCell>
                <TableCell align="left">
                  <b>Restaurant</b>
                </TableCell>
                <TableCell align="left">
                  <b>Contact</b>
                </TableCell>
                <TableCell align="left">
                  <b>NOP</b>
                </TableCell>
                <TableCell align="left">
                  <b>Paid(S$)</b>
                </TableCell>
                <TableCell align="left">
                  <b>Table No.</b>
                </TableCell>
                <TableCell align="left">
                  <b>Floor No.</b>
                </TableCell>
                <TableCell align="left">
                  <b>Booking Date</b>
                </TableCell>
                <TableCell align="left">
                  <b>Booking Time</b>
                </TableCell>
                <TableCell align="left">
                  <b>Created On</b>
                </TableCell>
                <TableCell align="left">
                  <b>Status</b>
                </TableCell>
              </TableRow>
            </TableHead>
            {filterBookings.length === 0 && isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {filterBookings
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow>
                        <TableCell>{row?._id.substring(0, 5)}...</TableCell>
                        <TableCell>
                          <Link to={`/user/${row?.userId}`}>
                            <b style={{ color: "#ef3340" }}>
                              {row?.bookingName}
                            </b>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link to={`/restaurant/${row?.restaurantId?._id}`}>
                            <b style={{ color: "#ef3340" }}>
                              {row?.restaurantId?.restaurantName}
                            </b>
                          </Link>
                        </TableCell>
                        <TableCell>{row?.bookingPhone}</TableCell>
                        <TableCell>{row?.totalPeople}</TableCell>
                        <TableCell>{row?.finalPrice}</TableCell>
                        <TableCell>
                          {" "}
                          {row?.tableId?.length !== 0
                            ? row?.tableId.map((tableData) => {
                                return <p>{tableData?.tableName}</p>;
                              })
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          {row?.floorId?.length !== 0
                            ? row?.floorId.map((floorData) => {
                                return <p>{floorData?.floorName}</p>;
                              })
                            : "NA"}
                        </TableCell>
                        <TableCell>{row?.bookingDate}</TableCell>
                        <TableCell>
                          {row?.startTime} - {row?.endTime}
                        </TableCell>
                        <TableCell>
                          {moment(row?.createdAt).format("YYYY-MMM-DD HH:mm")}
                        </TableCell>
                        <TableCell>
                          {findIcon(row?.status)}
                          {row?.status}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={allUpcomingReservations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

export default Upcoming;
