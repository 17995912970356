import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { baseURL } from "../../../../BaseURL";
import axios from "axios";
import { useSnackbar } from "notistack";

function DeleteMenu({
  pdfLink,
  id,
  restaurantId,
  setLoading,
  isLoading,
  setMenuLoading,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  async function handleDelete() {
    const formData = {
      menuPdfLink: pdfLink,
    };
    setMenuLoading(id);
    await axios
      .post(baseURL + "/restaurant/delete-menu-by-id/" + restaurantId, formData)
      .then((res) => {
        enqueueSnackbar("Successfully deleted", { variant: "success" });
        setLoading(true);
        setLoading(false);
        setMenuLoading("");
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.message, { variant: "error" });
        setLoading(false);
        setMenuLoading("");
      });
  }
  return (
    <div>
      <IconButton>
        <DeleteIcon
          size="small"
          style={{ color: "red" }}
          onClick={handleShow}
        />
      </IconButton>
      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          <p>Are you sure want to delete this Menu ?</p>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "30px",
                width: "90px",
                marginLeft: "5px",
              }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "30px",
                width: "70px",
                textAlign: "center",
                marginLeft: "5px",
              }}
              onClick={handleDelete}
            >
              Yes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeleteMenu;
