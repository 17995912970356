import React, { useState, useEffect } from "react";
import { InputGroup, FormControl, Button, Spinner } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios";
import PreviewIcon from "@mui/icons-material/Preview";
import CircularProgress from "@mui/material/CircularProgress";
import { baseURL } from "../../../../BaseURL";
import { useSnackbar } from "notistack";
import DeleteTermsAndCondition from "./DeleteTermsAndCondition";
const Menu = ({
  restaurantId,
  restaurantTermsAndCondition,
  isLoading,
  setLoading,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isMenuLoading, setMenuLoading] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  useEffect(() => {}, [isLoading]);

  const handleSubmission = async () => {
    if (restaurantTermsAndCondition) {
      const formData = {
        restaurantTermsAndCondition: restaurantTermsAndCondition,
      };

      try {
        await axios.post(
          baseURL + "/restaurant/delete-restaurant-tc/" + restaurantId,
          formData
        );
      } catch (err) {
        enqueueSnackbar("Error in updating the T&Cs", { variant: "error" });
      }
    }

    const formData = new FormData();
    formData.append("restaurantT&C", selectedFile);
    setLoading(true);

    await axios
      .post(
        baseURL + "/restaurant/upload-restaurant-tc/" + restaurantId,
        formData
      )
      .then((res) => {
        enqueueSnackbar("Successfully updated", { variant: "success" });
        setLoading(false);
        setSelectedFile("");
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
    setLoading(false);
  };

  async function handleDelete() {
    const formData = {
      restaurantTermsAndCondition: restaurantTermsAndCondition,
    };

    setLoading(true);
    try {
      await axios.post(
        baseURL + "/restaurant/delete-restaurant-tc/" + restaurantId,
        formData
      );
      enqueueSnackbar("Successfully deleted", { variant: "success" });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setLoading(false);
  }

  console.log(restaurantTermsAndCondition);
  return (
    <div>
      <div className="heading">
        <div>
          <p>
            <b>Terms And Conditions</b>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItem: "center",
            justifyContent: "space-evenly",
            width: "40rem",
          }}
        >
          <div>
            <InputGroup>
              <FormControl
                placeholder="Menu Name"
                aria-label="Menu Name"
                type="file"
                onChange={changeHandler}
                style={{ padding: "2px" }}
              />
            </InputGroup>
          </div>

          {isLoading === true ? (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "35px",
                width: "160px",
                marginLeft: "5px",
              }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "35px",
                width: "160px",
                textAlign: "center",
                marginLeft: "5px",
              }}
              onClick={() => handleSubmission()}
              disabled={!selectedFile ? true : false}
            >
              Add
            </Button>
          )}
        </div>
      </div>
      {restaurantTermsAndCondition && (
        <div
          className="data-table"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "105px",
              width: "60%",
              margin: "50px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 20px",
              backgroundColor: "#39424e",
              color: "whitesmoke",
              fontSize: "14px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <div style={{ width: "50%" }}>{restaurantTermsAndCondition}</div>
            <div>
              <a href={restaurantTermsAndCondition} target="_blank">
                <IconButton>
                  <FileDownloadIcon style={{ color: "white" }} />
                </IconButton>
              </a>
              <a
                href="#"
                style={{ marginLeft: 5 }}
                onClick={(e) => {
                  e.preventDefault();
                  const printJS = require("print-js");
                  printJS({ printable: restaurantTermsAndCondition });
                }}
              >
                <IconButton>
                  <PreviewIcon style={{ color: "white" }} />
                </IconButton>
              </a>
            </div>
            <div>
              <DeleteTermsAndCondition
                restaurantTermsAndCondition={restaurantTermsAndCondition}
                setLoading={setLoading}
                isLoading={isLoading}
                restaurantId={restaurantId}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
