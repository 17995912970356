import React from "react";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import "./SingleMessage.css";

function SingleMessage({ username, message, userName, restaurantName, time }) {
  let isUser;
  let name;
  if (username) {
    isUser = true;
    name = userName;
  } else name = restaurantName;
  return (
    <div className={`message ${isUser && `message__chip`}`}>
      <div className={`${isUser ? "message__user" : "message__guest"} chip`}>
        {message}
      </div>
      <div style={{ padding: "0 10px" }}>
        <p style={{ color: "black", maxWidth: "230px", fontSize: "11px" }}>
          {name}
        </p>
        <p style={{ color: "black", maxWidth: "230px", fontSize: "11px" }}>
          {moment(time).format("HH:mm")}
        </p>
      </div>
    </div>
  );
}

export default SingleMessage;
