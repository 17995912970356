import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputGroup,
  Button,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { baseURL } from "../../BaseURL";

function Login() {
  const [userData, setUserData] = useState({ userEmail: "", userPassword: "" });
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function submitDetails() {
    if (!userData.userEmail || !userData.userPassword) {
      setTimeout(() => {
        setError("");
      }, 5000);
      setError("Please Enter All Fields");
    } else {
      setLoading(true);
      const form = {
        email: userData.userEmail,
        password: userData.userPassword,
      };
      await axios
        .post(baseURL + "/admin/sign-in", form)
        .then((res) => {
          if (res.data.data) {
            const adminId = res?.data?.data[0]?._id;
            console.log("Logged In Successfully");
            localStorage.setItem("adminId", adminId);
            window.location.pathname = "/";
            setLoading(false);
          }
        })
        .catch((err) => {
          setError(err.response.data.mesage);
          setTimeout(() => {
            setError("");
          }, 8000);
          setLoading(false);
        });
    }
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "150px auto",
      }}
    >
      <div
        className="shadow-lg"
        style={{
          borderRadius: "5px",
          padding: "20px",
          minHeight: "43vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {error && (
          <Alert
            style={{ textAlign: "left", fontSize: "14px", width: "28vw" }}
            variant="danger"
          >
            {error}
          </Alert>
        )}
        <p>
          <b>Please Enter Your Credentials</b>
        </p>
        <Row sm={12}>
          <Col sm={12} style={{ marginBottom: "20px" }}>
            <label>Email</label>
            <InputGroup>
              <FormControl
                placeholder="Enter Email"
                aria-label="Enter Email"
                type="email"
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    userEmail: e.target.value,
                  });
                }}
              />
            </InputGroup>
          </Col>
          <Col sm={12} style={{ marginBottom: "20px" }}>
            <label>Password</label>
            <InputGroup>
              <FormControl
                placeholder="Enter Password"
                aria-label="Enter Password"
                type="password"
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    userPassword: e.target.value,
                  });
                }}
              />
            </InputGroup>
          </Col>
        </Row>
        {isLoading === true ? (
          <Button
            variant="dark"
            style={{ backgroundColor: "#ef3340" }}
            disabled
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: "5px" }}
            />
            <span className="visually-hidden">Loading...</span>
          </Button>
        ) : (
          <Button
            variant="dark"
            style={{ backgroundColor: "#ef3340" }}
            onClick={submitDetails}
          >
            Login
          </Button>
        )}
      </div>
    </div>
  );
}

export default Login;
