import React, { useState, useEffect } from "react";
import CheckOut from "./CheckOut";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import TextField from "@material-ui/core/TextField";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51JRfd9HQ4MFgkyxGB3g60QCCNg0p80umMej5nXJ0O4g9x7MDSQN4UmlyVRXfvfzdZrvNQGAboxa0oyZ2OnzgHsxY00rZ9Kd7r1"
);

function PaymentTest() {
  const options = {
    // passing the client secret obtained in step 2
    clientSecret:
      "pi_3KakPLHQ4MFgkyxG0HJ5kuu0_secret_GMKaJfuCQUuE1xemcrIz5KZiC",
    // Fully customizable with appearance API.
    appearance: {
      theme: "night",
    },
  };

  return (
    <div style={{ margin: "30px 100px", padding: "5rem" }}>
      <Elements stripe={stripePromise} options={options}>
        <CheckOut />
      </Elements>
    </div>
  );
}

export default PaymentTest;
