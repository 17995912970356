import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Modal, Button, Spinner } from "react-bootstrap";
import { baseURL } from "../../../../BaseURL";
import axios from "axios";
import { useSnackbar } from "notistack";

function DeleteSpecialOccasion({
  occasion,
  isLoading,
  setLoading,
  restaurantId,
  allSpecialOccasion,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  async function handleDelete() {
    setLoading(true);
    const updatedSpecialOccasion = allSpecialOccasion.filter(
      (specialOccasion) => {
        return specialOccasion !== occasion;
      }
    );
    const body = {
      restaurantSpecialOccasions: updatedSpecialOccasion,
    };
    await axios
      .post(baseURL + "/restaurant/update-restaurant/" + restaurantId, body)
      .then((res) => {
        enqueueSnackbar("Deleted successfully", { variant: "success" });
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        setLoading(false);
      });
  }
  return (
    <div>
      <IconButton
        size="small"
        style={{
          color: "red",
          cursor: "pointer",
          height: "20px",
          width: "20px",
        }}
        onClick={handleShow}
      >
        <DeleteIcon />
      </IconButton>
      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Delete Special Occasion</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure want to delete this special occasion ?
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              onClick={(e) => handleDelete()}
            >
              Yes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeleteSpecialOccasion;
