import React, { useState, useEffect } from "react";
import { Button, InputGroup, FormControl, Row, Col } from "react-bootstrap";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { baseURL } from "../../BaseURL";
import AddBlogs from "./AddBlogs";
import DeleteBlog from "./DeleteBlog";
import EditBlog from "./EditBlog";

function Blogs() {
  const [isLoading, setLoading] = useState(false);
  const [allBlogs, setAllBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  useEffect(() => {
    axios
      .get(baseURL + "/admin/get-all-blogs")
      .then((res) => {
        setAllBlogs(res.data.data);
        setFilteredBlogs(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [isLoading]);

  function handleChange(e) {
    const searchedLetter = e.target.value.toLowerCase();
    const blogs = allBlogs.filter((blog) => {
      if (
        blog.blogTitle.substring(0, searchedLetter.length).toLowerCase() ===
        searchedLetter
      )
        return blog;
    });
    setFilteredBlogs(blogs);
    console.log(blogs);
  }
  return (
    <div>
      <div className="header">
        <p>
          <b>Total Blogs</b> : {allBlogs.length}
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <AddBlogs setLoading={setLoading} isLoading={isLoading} />
          <InputGroup>
            <FormControl
              placeholder="Search Blogs"
              aria-label="Search Blogs"
              onChange={(e) => handleChange(e)}
            />
          </InputGroup>
        </div>
      </div>
      <Row
        sm={3}
        style={{
          padding: "20px",
          overflow: "hidden",
          width: "99vw",
        }}
      >
        {filteredBlogs.length !== 0 &&
          filteredBlogs.map((blog) => (
            <Col>
              <Card sx={{ minWidth: 300, height: 380 }}>
                <CardMedia
                  component="img"
                  height="180"
                  image={blog?.blogImage}
                  alt="green iguana"
                  style={{ height: "150px" }}
                />
                <CardContent
                  style={{
                    height: "180px",
                    overflow: "auto",
                    padding: "10px",
                    // display: "flex",
                    // flexDirection: "column",
                    // justifyContent: "space-between",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="p"
                    style={{ marginBottom: "0px", lineHeight: "1.5" }}
                  >
                    <b>{blog?.blogTitle}</b>
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    component="div"
                  >
                    <b>Category :</b>
                    {blog.blogTags &&
                      blog?.categoryTags.map((tag, index) => {
                        if (index === blog.categoryTags.length - 1) return tag;
                        else return tag + ",";
                      })}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    component="div"
                  >
                    <b>Author :</b>
                    {blog.authorTags &&
                      blog?.authorTags.map((tag, index) => {
                        if (index === blog.authorTags.length - 1) return tag;
                        else return tag + ",";
                      })}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    component="div"
                  >
                    <b>Cuisine :</b>
                    {blog.cuisineTags &&
                      blog?.cuisineTags.map((tag, index) => {
                        if (index === blog.cuisineTags.length - 1) return tag;
                        else return tag + ",";
                      })}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {blog?.blogDesc}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      "align-items": "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography variant="caption">
                      ~ {blog?.createdBy}
                    </Typography>
                  </div>
                </CardContent>
                <CardActions
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    size="small"
                    style={{ backgroundColor: "#39424e" }}
                    variant="dark"
                  >
                    <a
                      href={blog?.blogURL}
                      style={{ color: "whitesmoke", textDecoration: "none" }}
                    >
                      View More
                    </a>
                  </Button>
                  <div style={{ display: "flex" }}>
                    {/* <EditBlog
                      blogId={blog._id}
                      setLoading={setLoading}
                      isLoading={isLoading}
                    /> */}
                    <DeleteBlog
                      blogId={blog._id}
                      setLoading={setLoading}
                      isLoading={isLoading}
                    />
                  </div>
                </CardActions>
              </Card>
            </Col>
          ))}
      </Row>
    </div>
  );
}

export default Blogs;
