import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useSnackbar } from "notistack";
import { CsvBuilder } from "filefy";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import DeleteReview from "./DeleteReview";
import { baseURL } from "../../../BaseURL";

const columns = [
  { id: "Id", label: "Id", maxWidth: "100px" },
  { id: "Name", label: "Name" },
  {
    id: "Contact",
    label: "Contact",
  },
  {
    id: "Restaurant",
    label: "Restaurant",
  },
  {
    id: "Star Ratings",
    label: "Star Ratings",
  },
  {
    id: "Review",
    label: "Review",
  },
  {
    id: "Date",
    label: "Date",
  },
  {
    id: "Status",
    label: "Status",
  },
];

function AllReviews() {
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [allReviewData, setAllReviewData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let filterUsers = [];
  const filterTable = (searchTerm) => {
    filterUsers = [];
    if (searchTerm === "") {
      filterUsers = allReviewData;
    } else {
      allReviewData.forEach((item) => {
        if (item.userName.toLowerCase().includes(searchTerm.toLowerCase())) {
          filterUsers.push(item);
        }
      });
    }
  };
  filterTable(searchTerm);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function extractRowsFromData() {
    const rows = [];
    allReviewData.forEach((userData) => {
      let row = [];
      row.push(
        userData?._id,
        userData?.userName,
        userData?.userId?.userPhone,
        userData?.restuarantId?.restaurantName,
        userData?.starRating,
        userData?.reviewMessage,
        userData?.date?.substring(0, 10),
        userData?.date?.substring(11, 19)
      );
      rows.push(row);
    });
    return rows;
  }

  function exportToCSV() {
    var csvBuilder = new CsvBuilder("review_list.csv")
      .setColumns([
        "Id",
        "User",
        "Contact",
        "Restaurant",
        "Star Rating",
        "Review",
        "Date",
        "Time",
      ])
      .addRows(extractRowsFromData())
      .exportFile();
  }

  useEffect(() => {
    axios
      .get(baseURL + "/admin/get-all-reviews")
      .then((res) => {
        setAllReviewData(res.data.data.reverse());
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }, [isLoading]);

  return (
    <div className="all-restaurants">
      <div>
        {activeTab === 0 && (
          <div style={{ backgroundColor: "white" }}>
            <div className="header">
              <p>
                <b>Total Reviews</b> : {allReviewData?.length}
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <Button
                  style={{
                    width: "250px",
                    backgroundColor: "#EF3340",
                    margin: "0 5px",
                  }}
                  onClick={exportToCSV}
                >
                  Export to Excel
                </Button>
                <InputGroup>
                  <FormControl
                    placeholder="Search User"
                    aria-label="Search User"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="restaurant-data-table">
              <TableContainer
              // sx={{
              //   maxHeight: 640,
              //   borderRadius: "5px",
              //   boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              // }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "red" }}>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align="left"
                          style={{
                            width: column.maxWidth,
                          }}
                        >
                          <b>{column.label}</b>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {filterUsers.length === 0 && isLoading ? (
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {filterUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow>
                              <TableCell>
                                {row?._id.substring(0, 10)}...
                              </TableCell>
                              <TableCell>
                                <Link to={`/user/${row?.userId?._id}`}>
                                  <b style={{ color: "#ef3340" }}>
                                    {row?.userName || "NA"}
                                  </b>
                                </Link>
                              </TableCell>
                              <TableCell>
                                {row?.userId?.userPhone || "NA"}
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={`/restaurant/${row?.restuarantId?._id}`}
                                >
                                  <b style={{ color: "#ef3340" }}>
                                    {" "}
                                    {row?.restuarantId?.restaurantName || "NA"}
                                  </b>
                                </Link>
                              </TableCell>
                              <TableCell>{row?.starRating || "NA"}</TableCell>
                              <TableCell style={{ width: "300px" }}>
                                {row?.reviewMessage ? row?.reviewMessage : "NA"}
                              </TableCell>
                              <TableCell>
                                {row?.date?.substring(0, 10)}
                              </TableCell>
                              <TableCell>
                                <DeleteReview
                                  reviewId={row._id}
                                  setLoading={setLoading}
                                  isLoading={isLoading}
                                  isLive={
                                    row.isLive !== undefined ? row.isLive : true
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={allReviewData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AllReviews;
