import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  FormControl,
  Spinner,
  InputGroup,
  Row,
  Col,
} from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { baseURL } from "../../../BaseURL";
import SetTimingsModal from "../allRestaurants/SetTimingsModal";

function UpdateRestaurant({
  restaurantId,
  restaurantData,
  setLoading,
  isLoading,
}) {
  // const [isLoading, setLoading] = useState(false);
  const [allCuisines, setAllCuisines] = useState(restaurantData?.cuisineNames);
  const [value, setValue] = React.useState(false);
  const [show, setShow] = useState(false);
  const [address, setAddress] = React.useState(
    restaurantData?.restaurantAddress
  );
  const [timings, setTimings] = useState(restaurantData?.restaurantTimings);
  const [coordinates, setCoordinates] = React.useState({
    lat: restaurantData?.loc[1],
    lng: restaurantData?.loc[0],
  });
  const [restaurantPrice, setRestaurantPrice] = useState(
    restaurantData?.restaurantPricePerPerson
  );

  const [locality, setLocality] = useState(restaurantData?.restaurantLocality);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [cuisineArray, setCuisineArray] = useState([]);
  const [formData, setFormData] = useState({
    restaurantName: restaurantData?.restaurantName,
    restaurantOwner: restaurantData?.restaurantOwner,
    restaurantEmail: restaurantData?.restaurantEmail,
    restaurantPassword: restaurantData?.restaurantPassword,
    restaurantAccountManager: restaurantData?.restaurantAccountManager,
    restaurantRating: restaurantData?.restaurantRating,
    restaurantAddress: restaurantData?.restaurantAddress,
    restaurantLocality: restaurantData?.restaurantLocality,
    restaurantType: restaurantData?.restaurantType,
    restaurantPhone: restaurantData?.restaurantPhone,
    restaurantHalalCertificate: restaurantData?.restaurantHalalCertificate,
    restaurantRegistrationNo: restaurantData?.restaurantRegistrationNo,
    restaurantWebsite: restaurantData?.restaurantWebsite,
    restaurantPrice: restaurantData?.restaurantPrice,
    restaurantPricePerPerson: restaurantData?.restaurantPricePerPerson,
    isPrepaidBookingAvailable: restaurantData?.isPrepaidBookingAvailable,
    restaurantMaximumTableCapacity:
      restaurantData?.restaurantMaximumTableCapacity,
    cuisineNames: restaurantData?.cuisineNames,
  });

  function handleCheckboxChange(e) {
    console.log(e.target.checked);
    if (e.target.checked === false) {
      const cuisineName = e.target.parentNode.parentNode.innerText;
      console.log(cuisineName);
      const data = cuisineArray.filter((cuisine) => {
        return cuisineName !== cuisine;
      });
      console.log(data);
      e.target.setAttribute("checked", "false");
      setCuisineArray(data);
    } else {
      const cuisineName = e.target.parentNode.parentNode.innerText;
      console.log(cuisineName);
      setCuisineArray((prev) => [...prev, cuisineName]);
      e.target.setAttribute("checked", "true");
    }
  }

  useEffect(() => {
    render(locality);
  }, [locality]);

  useEffect(() => {
    axios
      .get(baseURL + "/restaurant/cuisines/get-all-cuisines")
      .then((res) => {
        if (res.data.data) setAllCuisines(res.data.data);
      })
      .catch((err) => {
        console.log("Error");
      });
  }, [isLoading]);

  function render(locality) {
    return (
      <div>
        <label>Restaurant Locality</label>
        <InputGroup>
          <FormControl
            placeholder="Restaurant Locality"
            aria-label="Restaurant Locality"
            value={locality}
            onChange={(e) => {
              setLocality(e.target.value);
            }}
          />
        </InputGroup>
      </div>
    );
  }

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    console.log(results);
    const latLng = await getLatLng(results[0]);
    console.log(latLng);
    setAddress(results[0].formatted_address);
    setCoordinates(latLng);

    const addressComponents = results[0].address_components;

    let local = "";

    addressComponents?.forEach((address) => {
      if (address?.types.includes("neighborhood")) {
        local = address.long_name;
      }
    });

    if (!local) {
      addressComponents.forEach((address) => {
        if (address?.types.includes("sublocality")) {
          local = address.long_name;
        }
      });
    }

    setLocality(local);
    render(local);
  };

  async function submitDetails(formData) {
    // console.log(longitude, latitude);
    if (formData.isPrepaidBookingAvailable === "false") {
      formData.restaurantPricePerPerson = 0;
      formData.isPrepaidBookingAvailable = false;
    } else {
      formData.isPrepaidBookingAvailable = true;
      formData.restaurantPricePerPerson = parseInt(restaurantPrice);
    }
    formData = {
      ...formData,
      restaurantAddress: address,
      restaurantLocality: locality,
      restaurantTimings: timings,
      restaurantLocation: [coordinates?.lng, coordinates?.lat],
    };
    console.log(formData);

    setLoading(true);
    await axios
      .post(baseURL + "/restaurant/update-restaurant/" + restaurantId, formData)
      .then((res) => {
        console.log(res.data);
        // setStatus("success");
        handleClose();
        setLoading(false);
        setShow(false);
        // window.location.reload(true);
      })
      .catch((err) => {
        // setStatus("error");
        setShow(true);
        handleClose();
        setLoading(false);
      });
    setLoading(false);
  }

  return (
    <div>
      <button
        onClick={() => handleShow()}
        style={{ color: "#EF3340" }}
        className="cus_border font-semibold p-2 w-20 text-xs font-medium rounded-md"
      >
        {" "}
        Edit Details
      </button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Update Restaurant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row sm={3}>
            <Col>
              <label>Restaurant Name</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Name"
                  aria-label="Restaurant Name"
                  defaultValue={formData?.restaurantName}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      restaurantName: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <label>Restaurant Type</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Type"
                  aria-label="Restaurant Type"
                  defaultValue={formData?.restaurantType}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      restaurantType: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <label>Restaurant Owner</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Owner"
                  aria-label="Restaurant Owner"
                  defaultValue={formData?.restaurantOwner}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      restaurantOwner: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <label>Restaurant Account Manager</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Account Manager"
                  aria-label="Restaurant Account Manager"
                  defaultValue={formData?.restaurantAccountManager}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      restaurantAccountManager: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <label>Restaurant Phone</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Phone"
                  aria-label="Restaurant Phone"
                  defaultValue={formData?.restaurantPhone}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      restaurantPhone: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <label>Restaurant Email</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Email"
                  aria-label="Restaurant Email"
                  type="email"
                  defaultValue={formData?.restaurantEmail}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      restaurantEmail: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <label>Restaurant Password</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Password"
                  aria-label="Restaurant Password"
                  type="text"
                  defaultValue={formData?.restaurantPassword}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      restaurantPassword: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <label>Restaurant Address</label>
                    <FormControl
                      {...getInputProps({ placeholder: "Type address" })}
                      // defaultValue={formData?.restaurantAddress}
                    />

                    <div
                      style={{
                        position: "absolute",
                        zIndex: "999",
                        border: "1px solid gray",
                        borderRadius: "5px",
                      }}
                    >
                      {loading ? <div>...loading</div> : null}

                      {suggestions.map((suggestion, index) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#ef3340"
                            : "#fff",
                          color: suggestion.active ? "white" : "black",
                          padding: "5px",
                          cursor: "pointer",
                        };

                        return (
                          <div
                            key={index}
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
            <Col>{render(locality)}</Col>

            <Col>
              <label>Restaurant Longitude</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Longitude"
                  aria-label="Restaurant Longitude"
                  value={coordinates.lng}
                  disabled
                />
              </InputGroup>
            </Col>

            <Col>
              <label>Restaurant Latitude</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Lalitude"
                  aria-label="Restaurant Lalitude"
                  value={coordinates.lat}
                  disabled
                />
              </InputGroup>
            </Col>

            <Col>
              <label>Restaurant Website</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Website"
                  aria-label="Restaurant Website"
                  defaultValue={formData?.restaurantWebsite}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      restaurantWebsite: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <label>Restaurant Registration No.</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Registration No."
                  aria-label="Restaurant Registration No."
                  defaultValue={formData?.restaurantRegistrationNo}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      restaurantRegistrationNo: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>

            {/* <Col>
              <label>Restaurant Ratings</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Ratings"
                  aria-label="Restaurant Ratings"
                  type="number"
                  value={formData?.restaurantRating}
                />
              </InputGroup>
            </Col> */}

            <Col>
              <label>Restaurant Avg. Price</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Avg. Price"
                  aria-label="Restaurant Avg. Price"
                  type="number"
                  defaultValue={formData?.restaurantPrice}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      restaurantPrice: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <label>Restaurant Halal No.</label>
              <InputGroup>
                <FormControl
                  placeholder="Restaurant Halal No."
                  aria-label="Restaurant Halal No."
                  type="number"
                  defaultValue={formData?.restaurantHalalCertificate}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      restaurantHalalCertificate: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <label>Is Prepaid Booking Available</label>
              <RadioGroup
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
                defaultValue={formData?.isPrepaidBookingAvailable}
                onChange={(e) => {
                  console.log(e.target.value);
                  let value;
                  if (e.target.value === true) value = true;
                  else value = false;
                  setFormData({
                    ...formData,
                    isPrepaidBookingAvailable: e.target.value,
                  });
                  setValue(e.target.value);
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio style={{ color: "#ef3340" }} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio style={{ color: "#ef3340" }} />}
                  label="No"
                />
              </RadioGroup>
            </Col>
            {(value === "true" ||
              formData?.isPrepaidBookingAvailable === true) && (
              <Col>
                <label>Restaurant Cost/Person</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Cost/Person"
                    aria-label="Restaurant Cost/Person"
                    type="number"
                    defaultValue={restaurantPrice}
                    onChange={(e) => setRestaurantPrice(e.target.value)}
                  />
                </InputGroup>
              </Col>
            )}

            <Col>
              <label>Max Table Capacity</label>
              <InputGroup>
                <FormControl
                  placeholder="Max Table Capacity"
                  aria-label="Max Table Capacity"
                  type="number"
                  defaultValue={formData?.restaurantMaximumTableCapacity}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      restaurantMaximumTableCapacity: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row sm={3}>
            {/* <Col sm={12}>
              <label>Cuisines</label>
              <InputGroup>
                {allCuisines?.map((cuisine) => (
                  <FormControlLabel
                    id={cuisine.id}
                    value={cuisine.id}
                    control={
                      <Checkbox
                        value={cuisine.id}
                        id={cuisine.id}
                        sx={{
                          color: "#ef3340",
                          "&.Mui-checked": {
                            color: "#ef3340",
                          },
                        }}
                        checked={formData?.cuisineNames.includes(
                          cuisine.cuisineName
                        )}
                      />
                    }
                    onClick={(e) => handleCheckboxChange(e)}
                    label={cuisine.cuisineName}
                    labelPlacement="end"
                  />
                ))}
              </InputGroup>
            </Col> */}
            <Col>
              <div>
                <p>
                  <b>Timings</b>
                </p>
              </div>
              <div style={{ marginTop: "10px" }}>
                {timings?.map((time, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "10px 0",
                      width: "500px",
                    }}
                  >
                    <p style={{ fontSize: "12px", letterSpacing: "1px" }}>
                      {time?.weekDay}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {time?.workingHours?.length === 0 ? (
                          <p>-- - --</p>
                        ) : (
                          time?.workingHours?.map((workingTiming) => (
                            <p>
                              {workingTiming?.openTime} -{" "}
                              {workingTiming?.endTime}
                            </p>
                          ))
                        )}
                      </div>
                      <SetTimingsModal
                        key={index}
                        day={time?.weekDay}
                        timings={time?.workingHours}
                        allTimings={timings}
                        setTimings={setTimings}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            style={{ backgroundColor: "#39424e" }}
            onClick={handleClose}
          >
            Close
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              onClick={() => submitDetails(formData)}
            >
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UpdateRestaurant;
