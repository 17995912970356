import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "_id", headerName: "ID", width: 100 },
  { field: "restaurantName", headerName: "Name", width: 190 },
  { field: "restaurantAddress", headerName: "Address", width: 130 },
  { field: "restaurantType", headerName: "Type", width: 130 },
  { field: "restaurantRating", headerName: "Rating", width: 130 },
  { field: "restaurantOwner", headerName: "Owner", width: 130 },
];

export default function RestaurantTable({
  rows,
  model,
  updateRecommendation,
  id,
  isLoading,
  setLoading,
}) {
  const tempmodel = model?.map((r) => r._id);
  const [selectionModel, setSelectionModel] = useState(tempmodel);

  return (
    <>
      <div
        style={{
          height: 400,
          width: "100%",
          backgroundColor: "white",
          margin: "10px 0",
          borderRadius: "10px",
        }}
        className="pt-3"
      >
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row._id}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={setSelectionModel}
        />
      </div>
      <div class="flex items-center justify-center">
        {isLoading === true ? (
          <Button
            variant="dark"
            style={{
              backgroundColor: "#ef3340",
              height: "35px",
              marginTop: "32px",
              width: "33%",
            }}
            disabled
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">Loading...</span>
          </Button>
        ) : (
          <button
            className="w-4/12 text-white py-2 px-3 rounded-sm border-none bg-gray-400"
            style={{ backgroundColor: "#EF3340" }}
            onClick={(e) => updateRecommendation(id, selectionModel)}
          >
            Update Linked Restaurants
          </button>
        )}
      </div>
    </>
  );
}
