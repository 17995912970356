import React, { useState, useEffect } from "react";
import RecommendationTable from "./RecommendationTable/RecommendationTable";
import CoolAreaTable from "./CoolAreaTable/CoolAreaTable";
import Cuisines from "./Cuisines/Cuisines";
import LoyalityPoints from "./LoyalityPoints/LoyalityPoints";
import Enquiry from "./Enquiry/Enquiry";
import Advertisment from "./Advertisment/Advertisment";
import TechSupport from "./TechSupport/TechSupport";
import BlogTags from "./BlogTags/BlogTags";
import Subscription from "./Subscriptions/Subscription";
import { Link } from "react-router-dom";

function MasterSettings() {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div>
      <div className="mini-navbar" style={{ backgroundColor: "whitesmoke" }}>
        <ul style={{ width: "75%" }}>
          <li>
            <Link
              onClick={() => {
                setActiveTab(0);
              }}
              className={`${activeTab === 0 && "clicked"}`}
            >
              Recommendations
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(1);
              }}
              className={`${activeTab === 1 && "clicked"}`}
            >
              Cool Areas
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(2);
              }}
              className={`${activeTab === 2 && "clicked"}`}
            >
              Cuisines
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(3);
              }}
              className={`${activeTab === 3 && "clicked"}`}
            >
              Enquiry
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(4);
              }}
              className={`${activeTab === 4 && "clicked"}`}
            >
              Loyality Points
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(5);
              }}
              className={`${activeTab === 5 && "clicked"}`}
            >
              Advertisments
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(6);
              }}
              className={`${activeTab === 6 && "clicked"}`}
            >
              Subscription Plan
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(7);
              }}
              className={`${activeTab === 7 && "clicked"}`}
            >
              Tech-Support
            </Link>
          </li>
        </ul>
      </div>

      <div>
        {activeTab === 0 && <RecommendationTable />}
        {activeTab === 1 && <CoolAreaTable />}
        {activeTab === 2 && <Cuisines />}
        {activeTab === 3 && <Enquiry />}
        {activeTab === 4 && <LoyalityPoints />}
        {activeTab === 5 && <Advertisment />}
        {activeTab === 6 && <Subscription />}
        {activeTab === 7 && <TechSupport />}
      </div>
    </div>
  );
}

export default MasterSettings;
