import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useSnackbar } from "notistack";
import axios from "axios";
import { baseURL } from "../../../../BaseURL";
import FormControlLabel from "@mui/material/FormControlLabel";

function RestaurantSubscription({
  restaurantId,
  restaurantData,
  isLoading,
  setLoading,
}) {
  const [subscriptionPlan, setSubscriptionPlan] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    restaurantSubscriptionPlan: restaurantData.restaurantSubscriptionPlan,
  });
  const restaurantSubscriptionPlan = restaurantData.restaurantSubscriptionPlan;

  useEffect(() => {
    axios
      .get(baseURL + "/admin/get-all-subscription-plans")
      .then((res) => {
        // console.log(res.data.data);
        setSubscriptionPlan(res.data.data);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }, []);

  async function handleUpdate() {
    try {
      const updatedRestaurant = await axios.post(
        baseURL + "/restaurant/update-restaurant/" + restaurantId,
        formData
      );
      enqueueSnackbar("Subscription plan updated successfully", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px",
        }}
      >
        <button
          className="text-white font-bold px-4 py-2 rounded-md"
          style={{
            backgroundColor: "#EF3340",
          }}
          onClick={() => handleUpdate()}
          disabled={
            formData.restaurantSubscriptionPlan === restaurantSubscriptionPlan
              ? true
              : false
          }
        >
          Update Subscription
        </button>
      </div>
      <RadioGroup
        onChange={(e) => {
          let value = e.target.value;
          setFormData({
            ...formData,
            restaurantSubscriptionPlan: value,
          });
        }}
        defaultValue={restaurantSubscriptionPlan}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        {subscriptionPlan?.map((subscriptionPlan) => (
          <FormControlLabel
            style={{
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: " #39424e",
              color: "whitesmoke",
              paddingRight: "20px",
            }}
            value={subscriptionPlan._id}
            control={<Radio style={{ color: "whitesmoke" }} />}
            label={`$${subscriptionPlan.subscriptionPrice}-${subscriptionPlan.subscriptionMonth} months`}
          />
        ))}
      </RadioGroup>
    </div>
  );
}

export default RestaurantSubscription;
