import React from "react";
import SetTimingsModal from "./SetTimingsModal";

function Timings({ timings, setTimings }) {
  return (
    <div>
      <div>
        <p>
          <b>Timings</b>
        </p>
      </div>
      <div style={{ marginTop: "10px" }}>
        {timings?.map((time) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
              width: "500px",
            }}
          >
            <p style={{ fontSize: "12px", letterSpacing: "1px" }}>
              {time.weekDay}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                {time?.workingHours.length === 0 ? (
                  <p>-- - --</p>
                ) : (
                  time.workingHours.map((workingTiming) => (
                    <p>
                      {workingTiming?.openTime} - {workingTiming?.endTime}
                    </p>
                  ))
                )}
              </div>
              <SetTimingsModal
                day={time.weekDay}
                timings={time.workingHours}
                allTimings={timings}
                setTimings={setTimings}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Timings;
