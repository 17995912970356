import React, { useState, useEffect } from "react";
import AddNotification from "./AddNotification";
import Alert from "@mui/material/Alert";
import DeleteNotification from "./DeleteNotification";
import EditNotification from "./EditNotification";
import { baseURL } from "../../BaseURL";
import axios from "axios";

function UserNotifications() {
  const [allUserNotifications, setAllUserNotifications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  let filterBookings = [];
  const filterTable = (searchTerm) => {
    filterBookings = [];
    if (searchTerm === "") {
      filterBookings = allUserNotifications;
    } else {
      allUserNotifications.forEach((item) => {
        if (
          item.restaurantId.restaurantName
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) {
          filterBookings.push(item);
        }
      });
    }
  };
  filterTable(searchTerm);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    axios
      .get(baseURL + "/user/notification/get-all-notification-content")
      .then((res) => {
        if (res.data.data) {
          setAllUserNotifications(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isLoading]);

  function findCondition(condition) {
    if (condition === "Confirmed Booking") return "After Successfull Booking";
    else if (condition === "Review Reminder")
      return "After Successfull Check-out";
    else if (condition === "Booking Reminder") return "Before Reservation Date";
    else if (condition === "Enquiry Message") return "At Enquiry Message";
    else if (condition === "Sign-Up") return "After Successfull SignUp";
    else return "";
  }

  return (
    <div>
      <div className="heading">
        <div>
          <p>
            <b>Total Notifications</b> :{" "}
            <span>{allUserNotifications?.length}</span>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <AddNotification setLoading={setLoading} isLoading={isLoading} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        {allUserNotifications.map((notification) => (
          <Alert
            severity="success"
            variant="filled"
            style={{
              width: "70%",
              margin: "10px",
              backgroundColor: " #39424e ",
              fontSize: "12px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p style={{ width: "70%" }}>
                <b>{notification?.scheduleType}</b>
              </p>
              <p>
                <b>Schedule Date</b> :{" "}
                {notification.scheduleDate ? notification?.scheduleDate : "NA"}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ width: "75%" }}>{notification?.title}</p>
              <p>
                <b>Schedule Day</b> :{" "}
                {notification.scheduleDay ? notification?.scheduleDay : "NA"}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ width: "75%" }}>{notification?.body}</p>
              <p>
                <b>Schedule Time (HH:mm)</b> :{" "}
                {notification?.scheduleTime?.Hours
                  ? notification?.scheduleTime?.Hours
                  : "00"}
                -
                {notification.scheduleTime?.Minutes
                  ? notification?.scheduleTime?.Minutes
                  : "00"}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ width: "75%" }}>
                {findCondition(notification?.scheduleType)}
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <EditNotification /> */}
                <DeleteNotification
                  isLoading={isLoading}
                  setLoading={setLoading}
                  notificationId={notification._id}
                />
              </div>
            </div>
          </Alert>
        ))}
      </div>
    </div>
  );
}

export default UserNotifications;
