import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import { InputGroup, FormControl } from "react-bootstrap";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { baseURL } from "../../../../BaseURL";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

function Reviews({ restaurantId }) {
  const [allReviews, setAllReviews] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    axios
      .get(baseURL + "/restaurant/reviews/get-all-reviews/" + restaurantId)
      .then((res) => {
        console.log(res.data.data);
        setAllReviews(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <div>
        <div className="heading">
          <div>
            <p>
              <b>Total Reviews</b> : <span>{allReviews?.length}</span>
            </p>
          </div>
        </div>
        <div className="data-table">
          <TableContainer
            style={{ borderRadius: "5px", backgroundColor: "white" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Id.</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Guest Name</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Contact</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Table No.</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Floor No.</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Created At</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Booking Date</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Booking Time</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Ratings</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Comment</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allReviews
                  .reverse()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow>
                        <TableCell>{row?._id.substring(0, 10)}...</TableCell>
                        <TableCell>
                          <Link to={`/user/${row?.userId}`}>
                            <b style={{ color: "#ef3340" }}>{row?.userName}</b>
                          </Link>
                        </TableCell>
                        <TableCell>{row?.bookingId?.bookingPhone}</TableCell>
                        <TableCell>
                          {row?.bookingId?.tableId?.length !== 0
                            ? row?.bookingId?.tableId.map((tableData) => {
                                return <p>{tableData?.tableName}</p>;
                              })
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          {row?.bookingId?.floorId?.length !== 0
                            ? row?.bookingId?.floorId.map((floorData) => {
                                return <p>{floorData?.floorName}</p>;
                              })
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          {moment(row?.date).format("YYYY-MMM-DD HH:mm")}
                        </TableCell>
                        <TableCell>{row?.bookingId?.bookingDate}</TableCell>
                        <TableCell>{row?.bookingId?.startTime}</TableCell>
                        <TableCell>{row?.starRating}</TableCell>
                        <TableCell>
                          {row?.reviewMessage ? row?.reviewMessage : "NA"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={allReviews.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default Reviews;
