import React, { useState, useEffect } from "react";
import axios from "axios";
import { InputGroup, FormControl, Button, Spinner } from "react-bootstrap";
import { baseURL } from "../../../BaseURL";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

function LoyalityPoints() {
  const [loyalityPoints, setLoyalityPoints] = useState([]);
  const [newLoyalityPoints, setNewLoyalityPoints] = useState(0);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    axios.get(baseURL + "/admin/get-loyality-points").then((res) => {
      if (res.data.data) setLoyalityPoints(res.data.data[0].points);
    });
  }, [isLoading]);

  function handleSubmission() {
    const data = {
      loyalityPoint: parseInt(newLoyalityPoints),
    };
    setLoading(true);
    console.log(data);
    axios
      .post(baseURL + "/admin/set-loyality-points", data)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data.data);
          setLoading(false);
          setNewLoyalityPoints(0);
        }
      })
      .catch((err) => {
        console.log("Error");
      });
  }

  useEffect(() => {});
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          padding: "0 10rem",
          margin: "20px",
        }}
      >
        <label>Loyality Points</label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <InputGroup>
            <FormControl
              placeholder="Set Loyality Points"
              aria-label="Set Loyality Points"
              value={newLoyalityPoints}
              type="number"
              style={{ marginRight: "20px" }}
              onChange={(e) => setNewLoyalityPoints(e.target.value)}
            />
          </InputGroup>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "35px",
                width: "160px",
              }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "35px",
                width: "160px",
                textAlign: "center",
              }}
              onClick={() => handleSubmission()}
            >
              Update
            </Button>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "30px auto",
        }}
      >
        <div
          style={{
            backgroundColor: "#39424e",
            color: "white",
            padding: "10px 25px",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "350px",
          }}
          class="shadow-md"
        >
          <b>{loyalityPoints}</b>
          <MonetizationOnIcon style={{ color: "gold" }} />
        </div>
      </div>
    </div>
  );
}

export default LoyalityPoints;
