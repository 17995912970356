import React, { useState, useEffect } from "react";
import { InputGroup, FormControl, Button, Spinner } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { baseURL } from "../../../BaseURL";
import { useSnackbar } from "notistack";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

function TechSupport({ restaurantId }) {
  const [allTechSupport, setAllTechSupport] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //API Call

  let filterTechSupport = [];
  const filterTable = (searchTerm) => {
    filterTechSupport = [];
    if (searchTerm === "") {
      filterTechSupport = allTechSupport;
      console.log(filterTechSupport);
    } else {
      allTechSupport.forEach((item) => {
        if (
          item?.userId?.userName
            .toLowerCase()
            .substring(0, searchTerm.length) === searchTerm
        ) {
          filterTechSupport.push(item);
        }
      });
    }
  };
  filterTable(searchTerm);

  useEffect(() => {
    axios
      .get(baseURL + "/admin/get-all-tech-support?search=user")
      .then((res) => {
        setAllTechSupport(res.data.data);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function findIcon(status) {
    if (status) {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8Z"
            fill="#738F93"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 7.5C8.27614 7.5 8.5 7.72386 8.5 8V10.6667C8.5 10.9428 8.27614 11.1667 8 11.1667C7.72386 11.1667 7.5 10.9428 7.5 10.6667V8C7.5 7.72386 7.72386 7.5 8 7.5Z"
            fill="#738F93"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.5 5.5C7.5 5.22386 7.72386 5 8 5H8.00667C8.28281 5 8.50667 5.22386 8.50667 5.5C8.50667 5.77614 8.28281 6 8.00667 6H8C7.72386 6 7.5 5.77614 7.5 5.5Z"
            fill="#738F93"
          />
        </svg>
      );
    } else {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.442 2.51585C9.26012 1.98926 7.93972 1.8588 6.67767 2.14394C5.41563 2.42908 4.27957 3.11455 3.43893 4.0981C2.59828 5.08165 2.0981 6.31059 2.01297 7.60164C1.92783 8.89269 2.26232 10.1767 2.96654 11.2621C3.67075 12.3475 4.70697 13.1762 5.92064 13.6246C7.13432 14.073 8.46042 14.117 9.70117 13.7502C10.9419 13.3833 12.0309 12.6252 12.8056 11.589C13.5803 10.5527 13.9992 9.29371 14 7.99985V7.3869C14 7.01871 14.2984 6.72024 14.6666 6.72024C15.0348 6.72024 15.3333 7.01871 15.3333 7.3869V8.00024C15.3324 9.58161 14.8203 11.1207 13.8735 12.3873C12.9266 13.6539 11.5957 14.5804 10.0792 15.0288C8.56274 15.4772 6.94195 15.4233 5.45857 14.8753C3.97519 14.3273 2.70871 13.3144 1.848 11.9878C0.987288 10.6612 0.578473 9.09186 0.682522 7.51391C0.786572 5.93596 1.39791 4.43392 2.42536 3.2318C3.45282 2.02968 4.84133 1.1919 6.38383 0.84339C7.92633 0.494884 9.54016 0.65433 10.9846 1.29795C11.3209 1.4478 11.4721 1.84192 11.3222 2.17824C11.1724 2.51455 10.7783 2.66571 10.442 2.51585Z"
            fill="#068932"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.1378 2.19503C15.3982 2.45525 15.3984 2.87736 15.1382 3.13784L8.47156 9.81117C8.34656 9.9363 8.17695 10.0066 8.00009 10.0067C7.82322 10.0067 7.65358 9.93647 7.52851 9.8114L5.52851 7.8114C5.26816 7.55106 5.26816 7.12895 5.52851 6.8686C5.78886 6.60825 6.21097 6.60825 6.47132 6.8686L7.99968 8.39696L14.1949 2.1955C14.4552 1.93502 14.8773 1.93481 15.1378 2.19503Z"
            fill="#068932"
          />
        </svg>
      );
    }
  }

  return (
    <div>
      <div className="heading" style={{ padding: "15px 0" }}>
        <div>
          <p>
            <b>Total Tech Requests</b> : <span>{allTechSupport?.length}</span>
          </p>
        </div>
        <div>
          <InputGroup>
            <FormControl
              style={{ width: "250px" }}
              placeholder="Search User"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </div>
      </div>
      <div className="data-table">
        <TableContainer
          style={{ borderRadius: "5px", backgroundColor: "white" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Id.</b>
                </TableCell>
                <TableCell align="left">
                  <b>Restaurant</b>
                </TableCell>
                <TableCell align="left">
                  <b>Name</b>
                </TableCell>
                <TableCell align="left">
                  <b>Contact No.</b>
                </TableCell>
                <TableCell align="left">
                  <b>Email</b>
                </TableCell>
                <TableCell align="left">
                  <b>Topic</b>
                </TableCell>
                <TableCell align="left" width="300px">
                  <b>Request</b>
                </TableCell>
                <TableCell align="left">
                  <b>Created At</b>
                </TableCell>
                <TableCell align="center">
                  <b>Status</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterTechSupport
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow>
                      <TableCell>{row?._id.substring(0, 10)}...</TableCell>
                      <TableCell>
                        <Link to={`/user/${row?.restaurantId?._id}`}>
                          <b style={{ color: "#ef3340" }}>
                            {row?.restaurantId?.restaurantName}
                          </b>
                        </Link>
                      </TableCell>
                      <TableCell>{row?.name}</TableCell>
                      <TableCell>{row?.phone}</TableCell>
                      <TableCell>{row?.email}</TableCell>
                      <TableCell>{row?.techSupportTopic?.topic}</TableCell>
                      <TableCell>{row?.techSupportEnquiry}</TableCell>
                      <TableCell>
                        {moment(row?.createdAt).format("YYYY-MMM-DD HH:mm")}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItem: "center",
                            justifyContent: "space-between",
                            textAlign: "left",
                          }}
                        >
                          {findIcon(row?.isLive)}
                          {row?.isLive === true ? "Pending" : "Resolved"}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={allTechSupport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

export default TechSupport;
