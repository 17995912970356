import React from "react";
import Navbar from "./components/navbar/Navbar";
import AllRestaurants from "./components/restaurants/allRestaurants/AllRestaurants";
import SingleRestaurant from "./components/restaurants/singleRestaurants/SingleRestaurant";
import MasterSettings from "./components/master-settings/MasterSettings";
import AllUsers from "./components/users/allUsers/AllUsers";
import SingleUser from "./components/users/singleUser/SingleUser";
import Reservations from "./components/reservations/Reservations";
import Notifications from "./components/notifications/Notifications";
import Blogs from "./components/blogs/Blogs";
import Login from "./components/login/Login";
import ProtectedRoute from "./ProtectedRoute";
import PaymentTest from "./PaymentTest";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Route exact path="/login" component={Login} />
        {localStorage.getItem("adminId") && <Navbar />}
        <Switch>
          <ProtectedRoute exact path="/" component={AllRestaurants} />
          <ProtectedRoute exact path="/users" component={AllUsers} />
          <ProtectedRoute
            exact
            path="/restaurant/:id"
            component={SingleRestaurant}
          />
          <ProtectedRoute exact path="/user/:id" component={SingleUser} />
          <ProtectedRoute exact path="/reservations" component={Reservations} />
          <ProtectedRoute
            exact
            path="/master-setting"
            component={MasterSettings}
          />
          <ProtectedRoute exact path="/blogs" component={Blogs} />
          <ProtectedRoute
            exact
            path="/notifications"
            component={Notifications}
          />
          <ProtectedRoute exact path="/payment" component={PaymentTest} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
