import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { baseURL } from "../../../BaseURL";
import { useSnackbar } from "notistack";

function DeleteCuisine({ isLoading, setLoading, cuisineId }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  async function handleDelete() {
    setLoading(true);
    await axios
      .delete(baseURL + "/restaurant/cuisines/delete-cuisines/" + cuisineId)
      .then((res) => {
        if (res.data.data) {
          setLoading(false);
          setShow(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message);
        setLoading(false);
      });
  }

  return (
    <div>
      <IconButton size="small" onClick={handleShow}>
        <DeleteIcon style={{ color: "red" }} />
      </IconButton>
      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          <p>Are you sure want to delete this cuisine ?</p>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "30px",
                width: "90px",
                marginLeft: "5px",
              }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "30px",
                width: "70px",
                textAlign: "center",
                marginLeft: "5px",
              }}
              onClick={handleDelete}
            >
              Yes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeleteCuisine;
