import React, { useState, useEffect } from "react";
import { Button, Modal, InputGroup, FormControl } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../../../BaseURL";

function RecordPayment({ restaurantId }) {
  const [show, setShow] = useState(false);
  const [pendingInvoice, setPendingInvoice] = useState([]);
  const [data, setData] = useState({
    restaurantId: restaurantId,
    invoiceId: "",
    paidAmount: 0,
    paymentMode: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleSubmit() {
    data.paidAmount = parseInt(data.paidAmount);
    await axios
      .post(baseURL + "/restaurant/record-payment", data)
      .then((res) => {
        setShow(false);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err.error);
      });
  }

  useEffect(() => {
    axios
      .get(baseURL + "/restaurant/get-all-pending-invoices/" + restaurantId)
      .then((res) =>
        setPendingInvoice(res.data.data.map((invoice) => invoice._id))
      )
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <>
        <Button
          variant="primary"
          onClick={handleShow}
          style={{
            backgroundColor: "#ef3340",
            height: "35px",
            width: "160px",
            textAlign: "center",
            marginLeft: "5px",
          }}
        >
          Record Payment
        </Button>

        <Modal show={show} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label for="cars">Choose Invoice ID:</label>

                <select
                  name="cars"
                  id="cars"
                  onChange={(e) =>
                    setData({ ...data, invoiceId: e.target.value })
                  }
                >
                  {pendingInvoice.map((invoice) => (
                    <option value={invoice}>{invoice}</option>
                  ))}
                </select>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label for="cars">Payment Type</label>

                <select
                  name="cars"
                  id="cars"
                  onChange={(e) =>
                    setData({ ...data, paymentMode: e.target.value })
                  }
                >
                  <option value="Offline">Offline</option>
                  <option value="Online">Online</option>
                </select>
              </div>
              <div>
                <label>Amount Paid</label>
                <InputGroup>
                  <FormControl
                    placeholder="Amount Paid"
                    aria-label="Amount Paid"
                    onChange={(e) => {
                      setData({ ...data, paidAmount: e.target.value });
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{
                backgroundColor: "#ef3340",
                height: "31px",
                width: "100px",
                textAlign: "center",
                marginLeft: "5px",
              }}
              onClick={handleSubmit}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}

export default RecordPayment;
