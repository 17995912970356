import React from "react";
import { Link } from "react-router-dom";
import "./AddRestaurant.css";

function MiniNavbar({ activeTab, setActiveTab }) {
  return (
    <div>
      <div className="mini-navbar">
        <ul>
          <li>
            <Link
              onClick={() => {
                setActiveTab(0);
              }}
              className={`${activeTab === 0 && "clicked"}`}
            >
              Restaurants
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(1);
              }}
              className={`${activeTab === 1 && "clicked"}`}
            >
              Payments Due
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(2);
              }}
              className={`${activeTab === 2 && "clicked"}`}
            >
              Featured
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(3);
              }}
              className={`${activeTab === 3 && "clicked"}`}
            >
              Recent Reviews
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(4);
              }}
              className={`${activeTab === 4 && "clicked"}`}
            >
              Enquires
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setActiveTab(5);
              }}
              className={`${activeTab === 5 && "clicked"}`}
            >
              Tech Requests
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MiniNavbar;
