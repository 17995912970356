import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { baseURL } from "../../BaseURL";

function AddNotification({ isLoading, setLoading }) {
  const [show, setShow] = useState(false);
  const hours = [];
  const minutes = [];
  const days = [];
  const scheduleType = [
    "Sign-Up",
    "Confirmed Booking",
    "Review Reminder",
    "Enquiry Message",
    "Others",
  ];
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [day, setDay] = useState("");
  const [type, setType] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState({
    title: "",
    body: "",
    scheduleFor: "",
    scheduleDate: "",
    scheduleType: type,
  });

  const daysName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  for (let i = 0; i < 24; i++) {
    if (i < 10) {
      let myNumber = i;
      let formattedNumber = ("0" + myNumber).slice(-2);
      hours.push({ value: formattedNumber, label: formattedNumber });
    } else hours.push({ value: i, label: i });
  }

  for (let i = 0; i < 60; i++) {
    if (i < 10) {
      let myNumber = i;
      let formattedNumber = ("0" + myNumber).slice(-2);
      minutes.push({ value: formattedNumber, label: formattedNumber });
    } else minutes.push({ value: i, label: i });
  }

  for (let i = 0; i < daysName.length; i++) {
    days.push({ value: i, label: daysName[i] });
  }
  // console.log(hours);

  async function handleSubmit() {
    data.scheduleTime = { Hours: hour, Minutes: minute };
    if (day !== -1) data.scheduleDay = parseInt(day);
    console.log(data);
    setLoading(true);
    const formData = data;
    console.log(formData);
    await axios
      .post(baseURL + "/admin/notification/add-notification", data)
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  return (
    <div>
      <Button
        style={{
          padding: "5px 10px",
          backgroundColor: "#EF3340",
          margin: "0 5px",
        }}
        onClick={handleShow}
      >
        Add Notification
      </Button>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row sm={12}>
            <Col sm={6}>
              <label>Notification Title</label>
              <InputGroup>
                <FormControl
                  placeholder="Enter Title"
                  aria-label="Adv Name"
                  onChange={(e) => {
                    setData({ ...data, title: e.target.value });
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={6}>
              <label>Notification Message</label>
              <InputGroup>
                <FormControl
                  as="textarea"
                  aria-label="With textarea"
                  placeholder="Enter Message"
                  style={{ fontSize: "13px" }}
                  onChange={(e) => {
                    setData({ ...data, body: e.target.value });
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={6}>
              <label>Notification Type</label>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <TextField
                  id="outlined-select-currency"
                  select
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                    setData({ ...data, scheduleType: e.target.value });
                  }}
                  style={{
                    padding: "0px",
                    width: "365px",
                    backgroundColor: "white",
                    //   height: "30px",
                  }}
                  variant="filled"
                >
                  {scheduleType.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Col>
            {type === "Others" && (
              <Col sm={6}>
                <label>Schedule Date</label>
                <InputGroup>
                  <FormControl
                    placeholder="YYYY-MM-DD"
                    aria-label="Adv Name"
                    onChange={(e) => {
                      setData({ ...data, scheduleDate: e.target.value });
                    }}
                  />
                </InputGroup>
              </Col>
            )}
            <Col sm={6}>
              <label>Notification For</label>
              <InputGroup>
                <RadioGroup
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                  onChange={(e) => {
                    setData({ ...data, scheduleFor: e.target.value });
                  }}
                >
                  <FormControlLabel
                    value="USER"
                    control={<Radio style={{ color: "#ef3340" }} />}
                    label="User"
                  />
                  <FormControlLabel
                    value="RESTAURANT"
                    control={<Radio style={{ color: "#ef3340" }} />}
                    label="Restaurant"
                  />
                  <FormControlLabel
                    value="BOTH"
                    control={<Radio style={{ color: "#ef3340" }} />}
                    label="Both"
                  />
                </RadioGroup>
              </InputGroup>
            </Col>
            <Col sm={12}>
              <label>Schedule Time</label>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <InputGroup>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Hours"
                    value={hour}
                    onChange={(e) => {
                      setHour(e.target.value);
                    }}
                    style={{
                      padding: "0px",
                      width: "200px",
                    }}
                  >
                    {hours.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </InputGroup>
                <InputGroup>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Minutes"
                    value={minute}
                    onChange={(e) => {
                      setMinute(e.target.value);
                    }}
                    style={{
                      padding: "0px",
                      width: "200px",
                    }}
                  >
                    {minutes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </InputGroup>
                <InputGroup>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Day"
                    value={day}
                    onChange={(e) => {
                      setDay(e.target.value);
                    }}
                    style={{
                      padding: "0px",
                      width: "200px",
                    }}
                  >
                    {days.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </InputGroup>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="close-btn"
          >
            Close
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              onClick={() => handleSubmit()}
            >
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddNotification;
