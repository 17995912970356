import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Skeleton from "@mui/material/Skeleton";
import { baseURL } from "../../../BaseURL";
import axios from "axios";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SideBar from "./SideBar";
import Bookings from "./Bookings/Bookings";
import Payments from "./Payments/Payments";
import Reviews from "./Reviews/Reviews";
import Enquires from "./Enquires/Enquires";
function SingleUser(props) {
  const [userData, setUserData] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(1);
  const userId = props.match.params.id;

  useEffect(() => {
    axios
      .get(baseURL + "/user/get-user-by-id/" + userId)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <div className="bg-white border border-b-2 border-gray-300 p-2 px-3">
        <p className="text-gray-600 text-xs font-semibold ml-3">
          All Users -{" "}
          {(userData && userData.userName) || <Skeleton width={100} />} -
          Summary
        </p>

        <div className="text-gray-700 text-lg my-2 flex items-center mr-6 ml-3 font-bold">
          Showing :{" "}
          {(userData && userData.userName) || <Skeleton width={200} />}
          <span className="flex items-center ml-6 ">
            || &nbsp; Points : {(userData && userData.userLoyalityPoints) || 0}
            <MonetizationOnIcon style={{ marginLeft: "5px", color: "gold" }} />
          </span>
        </div>

        <ul className="list-none flex items-center justify-between w-80 font-light -mb-0">
          <li
            onClick={() => setActiveTabIndex(1)}
            className={clsx(
              "text-sm font-semibold text-gray-500 rounded-sm px-3 p-0 cursor-pointer",
              { ["bg-white text-red-500 "]: activeTabIndex === 1 }
            )}
          >
            Summary
          </li>
          <li
            onClick={() => setActiveTabIndex(2)}
            className={clsx(
              "text-sm font-semibold text-gray-500 rounded-sm px-3 cursor-pointer",
              { ["bg-white text-red-500 "]: activeTabIndex === 2 }
            )}
          >
            Bookings
          </li>
          <li
            onClick={() => setActiveTabIndex(3)}
            className={clsx(
              " text-sm font-semibold text-gray-500 rounded-sm px-3 cursor-pointer",
              { ["bg-white text-red-500 "]: activeTabIndex === 3 }
            )}
          >
            Payments
          </li>
          <li
            onClick={() => setActiveTabIndex(4)}
            className={clsx(
              " text-sm font-semibold text-gray-500 rounded-sm px-3 cursor-pointer",
              { ["bg-white text-red-500 "]: activeTabIndex === 4 }
            )}
          >
            Reviews
          </li>
          <li
            onClick={() => setActiveTabIndex(5)}
            className={clsx(
              "text-sm font-semibold text-gray-500 rounded-sm px-3 cursor-pointer",
              { ["bg-white text-red-500 "]: activeTabIndex === 5 }
            )}
          >
            Enquiries
          </li>
        </ul>
      </div>
      <div>
        {activeTabIndex === 1 && (
          <div>
            <SideBar userData={userData} />
          </div>
        )}
        {activeTabIndex === 2 && (
          <div>
            <Bookings userId={userId} />
          </div>
        )}
        {activeTabIndex === 3 && (
          <div>
            <Payments userId={userId} />
          </div>
        )}
        {activeTabIndex === 4 && (
          <div>
            <Reviews userId={userId} />
          </div>
        )}
        {activeTabIndex === 5 && (
          <div>
            <Enquires userId={userId} />
          </div>
        )}
      </div>
    </div>
  );
}

export default SingleUser;
