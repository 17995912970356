import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Modal,
  Button,
  FormControl,
  InputGroup,
  Row,
  Col,
} from "react-bootstrap";

function SetTimings({ day, timings, setTimings, allTimings }) {
  const [show, setShow] = useState(false);
  const [operationTimes, setOperationTimes] = useState(timings ? timings : []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // const data = {
    //   openTime: "",
    //   closeTime: "",
    // };
    // console.log(timings);
    // setOperationTimes(timings);
    console.log(timings);
  }, [operationTimes]);

  function handleSave() {
    setTimings((prev) => {
      return prev.map((time) => {
        if (time.weekDay === day) {
          time.workingHours = operationTimes;
        }
        return time;
      });
    });
    handleClose();
  }

  // console.log(operationTimes);

  return (
    <div>
      <IconButton onClick={handleShow}>
        <EditIcon style={{ cursor: "pointer", fontSize: "medium" }} />
      </IconButton>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "16px" }}>
            <b>{day}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {operationTimes?.map((input, index) => (
            <Row sm={12}>
              <Col>
                <label>Open Time</label>
                <InputGroup>
                  <FormControl
                    placeholder="Enter Open Time"
                    aria-label="Enter Open Time"
                    defaultValue={input?.openTime}
                    onChange={(e) => {
                      setOperationTimes((prev) => {
                        let tempState = [...prev];
                        tempState[index].openTime = e.target.value;
                        return tempState;
                      });
                    }}
                  />
                </InputGroup>
              </Col>
              <Col>
                <label>Close Time</label>
                <InputGroup>
                  <FormControl
                    placeholder="Enter Close Time"
                    aria-label="Enter Close Time"
                    defaultValue={input?.endTime}
                    onChange={(e) => {
                      setOperationTimes((prev) => {
                        let tempState = [...prev];
                        tempState[index].endTime = e.target.value;
                        return tempState;
                      });
                    }}
                  />
                </InputGroup>
              </Col>
              <IconButton
                size="small"
                onClick={() => {
                  // setNumberOfInputs(numberOfInputs - 1);
                  // operationTimes.pop();
                  setOperationTimes((prev) => {
                    let tempState = [...prev];
                    console.log(tempState);
                    console.log(index);
                    tempState.splice(index, 1);
                    return tempState;
                  });
                }}
                style={{
                  height: "35px",
                  marginTop: "30px",
                  marginRight: "10px",
                }}
              >
                <ClearIcon />
              </IconButton>
            </Row>
          ))}
          <Button
            className="save-btn"
            onClick={() => {
              setOperationTimes((prev) => [
                ...prev,
                { openTime: "", endTime: "" },
              ]);
              // console.log(operationTimes);
              // setNumberOfInputs(numberOfInputs + 1);
            }}
          >
            Add Hours
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} className="close-btn">
            Close
          </Button>
          <Button onClick={handleSave} className="save-btn">
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SetTimings;
