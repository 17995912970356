import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import { baseURL } from "../../../BaseURL";
import axios from "axios";
import { useSnackbar } from "notistack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function DeleteReview({ reviewId, setLoading, isLoading, isLive }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleDelete() {
    try {
      setLoading(true);
      const deletedReview = await axios.delete(
        baseURL + "/admin/delete-review/" + reviewId
      );
      if (deletedReview) {
        enqueueSnackbar("Successfully Updated", { variant: "success" });
        setLoading(false);
        handleClose();
      }
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  }
  return (
    <div>
      {isLive ? (
        <IconButton size="small" onClick={handleShow}>
          <VisibilityIcon style={{ color: "grey" }} />
        </IconButton>
      ) : (
        <IconButton size="small" onClick={handleShow}>
          <VisibilityOffIcon style={{ color: "grey" }} />
        </IconButton>
      )}
      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          <p>Are you sure want to change the visibility of this review ?</p>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "30px",
                width: "90px",
                marginLeft: "5px",
              }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "30px",
                width: "70px",
                textAlign: "center",
                marginLeft: "5px",
              }}
              onClick={handleDelete}
            >
              Yes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeleteReview;
