import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { baseURL } from "../../../../BaseURL";
const columns = [
  { id: "ID", label: "ID", minWidth: 170 },
  { id: "Type", label: "Type", minWidth: 100 },
  {
    id: "Value",
    label: "Value",
  },
  {
    id: "Start Date",
    label: "Start Date",
  },
  {
    id: "End Date",
    label: "End Date",
  },
  {
    id: "Remarks",
    label: "Remarks",
  },
  {
    id: "Plan",
    label: "Plan",
  },
  {
    id: "Created On",
    label: "Created On",
  },
  {
    id: "Status",
    label: "Status",
  },
];

function PlatformSubscription({ restaurantId }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [platformSubscription, setPlatformSubscription] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    axios
      .get(
        baseURL +
          "/restaurant/get-restaurant-platform-subscription/" +
          restaurantId
      )
      .then((res) => setPlatformSubscription(res.data.data));
  }, []);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {platformSubscription
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow>
                    <TableCell>{row?._id}</TableCell>
                    <TableCell>Debit</TableCell>
                    <TableCell>{row?.subscriptionPrice}</TableCell>
                    <TableCell>{row?.subscriptionStartDate}</TableCell>
                    <TableCell>{row?.subscriptionEndDate}</TableCell>
                    <TableCell>{row?.subscriptionRemark}</TableCell>
                    <TableCell>
                      {row?.subscriptionPlan?.subscriptionMonth}
                    </TableCell>
                    <TableCell>{row?.createdAt?.substring(0, 10)}</TableCell>
                    <TableCell>{row?.paymentStatus}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={platformSubscription.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default PlatformSubscription;
