import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { baseURL } from "../../../../BaseURL";
const columns = [
  { id: "ID", label: "ID", minWidth: 170 },
  { id: "Type", label: "Type", minWidth: 100 },
  {
    id: "Reference",
    label: "Reference",
  },
  {
    id: "Value",
    label: "Value",
  },
  {
    id: "Cost/PAX",
    label: "Cost/PAX",
  },
  {
    id: "Total Bookings",
    label: "Total Bookings",
  },
  {
    id: "Created On",
    label: "Created On",
  },
  {
    id: "Status",
    label: "Status",
  },
];

function MonthlyRental({ restaurantId }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [monthlyRental, setMonthlyRental] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function getIcon(paymentStatus) {
    if (paymentStatus === "Paid") {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.4421 2.51585C9.26024 1.98926 7.93984 1.8588 6.6778 2.14394C5.41575 2.42908 4.27969 3.11455 3.43905 4.0981C2.59841 5.08165 2.09822 6.31059 2.01309 7.60164C1.92796 8.89269 2.26244 10.1767 2.96666 11.2621C3.67087 12.3475 4.70709 13.1762 5.92076 13.6246C7.13444 14.073 8.46054 14.117 9.7013 13.7502C10.9421 13.3833 12.031 12.6252 12.8057 11.589C13.5804 10.5527 13.9993 9.29371 14.0001 7.99985V7.3869C14.0001 7.01871 14.2986 6.72024 14.6668 6.72024C15.0349 6.72024 15.3334 7.01871 15.3334 7.3869V8.00024C15.3325 9.58161 14.8204 11.1207 13.8736 12.3873C12.9267 13.6539 11.5958 14.5804 10.0793 15.0288C8.56286 15.4772 6.94207 15.4233 5.45869 14.8753C3.97531 14.3273 2.70883 13.3144 1.84812 11.9878C0.98741 10.6612 0.578595 9.09186 0.682644 7.51391C0.786694 5.93596 1.39803 4.43392 2.42549 3.2318C3.45294 2.02968 4.84146 1.1919 6.38395 0.84339C7.92645 0.494884 9.54028 0.65433 10.9848 1.29795C11.3211 1.4478 11.4722 1.84192 11.3224 2.17824C11.1725 2.51455 10.7784 2.66571 10.4421 2.51585Z"
            fill="#068932"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.138 2.19503C15.3985 2.45525 15.3987 2.87736 15.1385 3.13784L8.4718 9.81117C8.3468 9.9363 8.1772 10.0066 8.00033 10.0067C7.82346 10.0067 7.65382 9.93647 7.52876 9.8114L5.52876 7.8114C5.26841 7.55106 5.26841 7.12895 5.52876 6.8686C5.78911 6.60825 6.21122 6.60825 6.47157 6.8686L7.99993 8.39696L14.1952 2.1955C14.4554 1.93502 14.8775 1.93481 15.138 2.19503Z"
            fill="#068932"
          />
        </svg>
      );
    } else
      return (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1ZM0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7Z"
            fill="#738F93"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 6.5C7.27614 6.5 7.5 6.72386 7.5 7V9.66667C7.5 9.94281 7.27614 10.1667 7 10.1667C6.72386 10.1667 6.5 9.94281 6.5 9.66667V7C6.5 6.72386 6.72386 6.5 7 6.5Z"
            fill="#738F93"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.5 4.5C6.5 4.22386 6.72386 4 7 4H7.00667C7.28281 4 7.50667 4.22386 7.50667 4.5C7.50667 4.77614 7.28281 5 7.00667 5H7C6.72386 5 6.5 4.77614 6.5 4.5Z"
            fill="#738F93"
          />
        </svg>
      );
  }

  useEffect(() => {
    axios
      .get(
        baseURL + "/restaurant/get-restaurant-monthly-rentals/" + restaurantId
      )
      .then((res) => setMonthlyRental(res.data.data));
  }, []);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {monthlyRental
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow>
                    <TableCell>{row?._id}</TableCell>
                    <TableCell>Debit</TableCell>
                    <TableCell>
                      <p style={{ color: "red", fontSize: "10px" }}>
                        {row?.startDate} - {row?.endDate}
                      </p>
                    </TableCell>
                    <TableCell>{row?.totalAmount}</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>{row?.totalBookings.length}</TableCell>
                    <TableCell>{row?.createdAt?.substring(0, 10)}</TableCell>
                    <TableCell>
                      {
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          {getIcon(row.paymentStatus)}
                          <span
                            style={{ marginLeft: "5px" }}
                          >{`${row?.paymentStatus}`}</span>
                        </p>
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={monthlyRental.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default MonthlyRental;
