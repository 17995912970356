import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSnackbar } from "notistack";
import { baseURL } from "../../../BaseURL";
import axios from "axios";
import Timings from "./Timings";
import "./AddRestaurant.css";

function AddRestaurant({
  show,
  setShow,
  handleShow,
  handleClose,
  setAllRestaurantLoading,
}) {
  const [locality, setLocality] = useState("");
  const [cuisineNames, setCuisineNames] = useState([]);
  const [maximumTableCapacity, setMaximumTableCapacity] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [restaurantId, setRestaurantId] = useState("");
  const [value, setValue] = React.useState(false);
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [formData, setFormData] = useState({
    restaurantName: "",
    restaurantOwner: "",
    restaurantEmail: "",
    restaurantPassword: "",
    restaurantAccountManager: "",
    restaurantRating: 0,
    restaurantAddress: "",
    restaurantType: "",
    restaurantPhone: "",
    restaurantHalalCertificate: "",
    restaurantRegistrationNo: "",
    restaurantWebsite: "",
    restaurantPrice: "",
    restaurantPricePerPerson: 0,
    isPrepaidBookingAvailable: true,
    restaurantSubscriptionPlan: "",
  });
  const [cuisines, setCuisines] = useState([]);
  const [timings, setTimings] = useState([
    {
      weekDay: "SUNDAY",
      workingHours: [],
    },
    {
      weekDay: "MONDAY",
      workingHours: [],
    },
    {
      weekDay: "TUESDAY",
      workingHours: [],
    },
    {
      weekDay: "WEDNESDAY",
      workingHours: [],
    },
    {
      weekDay: "THURSDAY",
      workingHours: [],
    },
    {
      weekDay: "FRIDAY",
      workingHours: [],
    },
    {
      weekDay: "SATURDAY",
      workingHours: [],
    },
  ]);

  const [address, setAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({
    lat: 0,
    lng: 0,
  });
  const [subscriptionPlan, setSubscriptionPlan] = useState([]);

  useEffect(() => {
    axios
      .get(baseURL + "/admin/get-all-subscription-plans")
      .then((res) => {
        // console.log(res.data.data);
        setSubscriptionPlan(res.data.data);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }, [value]);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(results[0].formatted_address);
    setCoordinates(latLng);

    const addressComponents = results[0].address_components;

    let locality;

    addressComponents.forEach((address) => {
      if (address.types.includes("neighborhood")) {
        locality = address.long_name;
      }
    });

    if (!locality) {
      addressComponents.forEach((address) => {
        if (address.types.includes("sublocality")) {
          locality = address.long_name;
        }
      });
    }

    setLocality(locality);
  };

  useEffect(() => {
    axios
      .get(baseURL + "/restaurant/cuisines/get-all-cuisines")
      .then((res) => {
        setCuisines(res.data.data);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }, [isLoading]);

  function handleClick(e) {
    if (e.target.checked) {
      setCuisineNames((prev) => [
        ...prev,
        e.target.parentNode.nextSibling.innerText,
      ]);
    } else {
      setCuisineNames(
        cuisineNames.filter(
          (cuisine) => cuisine !== e.target.parentNode.nextSibling.innerText
        )
      );
    }
  }

  async function submitDetails(formData) {
    if (!formData.restaurantName || maximumTableCapacity === 0) {
      enqueueSnackbar("Please Enter all the required fields", {
        variant: "error",
      });
    } else {
      let data = [];
      for (let index = 1; index <= maximumTableCapacity; index++) {
        const item = {
          peopleCapacity: index,
          totalTableTime: 30,
        };
        data.push(item);
      }
      let isRestaurantHalalCertified =
        formData.restaurantHalalCertificate !== "" ? true : false;
      formData = {
        ...formData,
        restaurantLocality: locality,
        restaurantAddress: address,
        cuisineNames,
        restaurantHaultTime: 15,
        isRestaurantHalalCertified: isRestaurantHalalCertified,
        restaurantTimings: timings,
        restaurantMaximumTableCapacity: maximumTableCapacity,
        restaurantLocation: [coordinates?.lng, coordinates?.lat],
        restaurantTableHaultTime: data,
      };

      console.log(formData);
      setLoading(true);
      setAllRestaurantLoading(true);
      await axios
        .post(baseURL + "/restaurant/create-restaurant", formData)
        .then((res) => {
          console.log(res.data);
          setRestaurantId(res.data.restaurantId);
          setAllRestaurantLoading(false);
          setShow(false);
          setLoading(false);
          enqueueSnackbar("Restaurant Added Successfully", {
            variant: "success",
          });
          setTimeout(() => {
            enqueueSnackbar(
              `Please add some images and floors to ${formData.restaurantName} restaurant`,
              {
                variant: "info",
              }
            );
          }, 5000);
        })
        .catch((err) => {
          enqueueSnackbar(err.response.data.message, { variant: "error" });
          setLoading(false);
        });
    }
  }
  return (
    <div>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header>
          <Modal.Title
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "80rem",
            }}
          >
            <div>
              <b>Add Restaurant</b>
            </div>
          </Modal.Title>
          <Button
            style={{ boxShadow: "none" }}
            variant="light"
            onClick={handleClose}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row sm={3}>
              <Col>
                <label>Restaurant Name</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Name"
                    aria-label="Restaurant Name"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        restaurantName: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Restaurant Type</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Type"
                    aria-label="Restaurant Type"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        restaurantType: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Restaurant Owner</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Owner"
                    aria-label="Restaurant Owner"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        restaurantOwner: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Restaurant Account Manager</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Account Manager"
                    aria-label="Restaurant Account Manager"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        restaurantAccountManager: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Restaurant Phone</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Phone"
                    aria-label="Restaurant Phone"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        restaurantPhone: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Restaurant Email</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Email"
                    aria-label="Restaurant Email"
                    type="email"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        restaurantEmail: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Restaurant Password</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Password"
                    aria-label="Restaurant Password"
                    type="password"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        restaurantPassword: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Col>

              <Col>
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <label>Restaurant Address</label>
                      <FormControl
                        {...getInputProps({ placeholder: "Type address" })}
                      />

                      <div
                        style={{
                          position: "absolute",
                          zIndex: "999",
                          border: "1px solid gray",
                          borderRadius: "5px",
                        }}
                      >
                        {loading ? <div>...loading</div> : null}

                        {suggestions.map((suggestion, index) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#ef3340"
                              : "#fff",
                            color: suggestion.active ? "white" : "black",
                            padding: "5px",
                            cursor: "pointer",
                          };

                          return (
                            <div
                              key={index}
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Col>

              <Col>
                <label>Restaurant Locality</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Locality"
                    aria-label="Restaurant Locality"
                    defaultValue={locality}
                    onChange={(e) => {
                      setLocality(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Restaurant Longitude</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Longitude"
                    aria-label="Restaurant Longitude"
                    value={coordinates.lng}
                    disabled
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Restaurant Latitude</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Lalitude"
                    aria-label="Restaurant Lalitude"
                    value={coordinates.lat}
                    disabled
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Restaurant Website</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Website"
                    aria-label="Restaurant Website"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        restaurantWebsite: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Restaurant Registration No.</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Registration No."
                    aria-label="Restaurant Registration No."
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        restaurantRegistrationNo: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Restaurant Ratings</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Ratings"
                    aria-label="Restaurant Ratings"
                    type="number"
                    onChange={(e) => {
                      let val = parseFloat(e.target.value);
                      setFormData({
                        ...formData,
                        restaurantRating: val,
                      });
                    }}
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Restaurant Avg. Price</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Avg. Price"
                    aria-label="Restaurant Avg. Price"
                    type="number"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        restaurantPrice: parseInt(e.target.value),
                      });
                    }}
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Restaurant Halal No.</label>
                <InputGroup>
                  <FormControl
                    placeholder="Restaurant Halal No."
                    aria-label="Restaurant Halal No."
                    type="text"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        restaurantHalalCertificate: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Col>

              <Col>
                <label>Is Prepaid Booking Available</label>
                <RadioGroup
                  onChange={(e) => {
                    console.log(typeof e.target.value);
                    let value;
                    if (e.target.value === "true") value = true;
                    else value = false;
                    setValue(e.target.value);
                    setFormData({
                      ...formData,
                      isPrepaidBookingAvailable: value,
                    });
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio style={{ color: "#ef3340" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio style={{ color: "#ef3340" }} />}
                    label="No"
                  />
                </RadioGroup>
              </Col>
              {/* {console.log(typeof value)} */}
              {value === "true" && (
                <Col>
                  <label>Restaurant Cost/Person</label>
                  <InputGroup>
                    <FormControl
                      placeholder="Restaurant Cost/Person"
                      aria-label="Restaurant Cost/Person"
                      type="number"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          restaurantPricePerPerson: parseInt(e.target.value),
                          RestaurantPricePerPerson: parseInt(e.target.value),
                        });
                      }}
                    />
                  </InputGroup>
                </Col>
              )}

              <Col>
                <label>Max Table Capacity</label>
                <InputGroup>
                  <FormControl
                    placeholder="Max Table Capacity"
                    aria-label="Max Table Capacity"
                    type="number"
                    value={maximumTableCapacity}
                    onChange={(e) => {
                      setMaximumTableCapacity(parseInt(e.target.value));
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row sm={3}>
              <Col sm={12}>
                <label>Cuisines</label>
                <InputGroup>
                  {cuisines.map((cuisine) => (
                    <FormControlLabel
                      id={cuisine.id}
                      value={cuisine.id}
                      control={
                        <Checkbox
                          value={cuisine.id}
                          id={cuisine.id}
                          sx={{
                            color: "#ef3340",
                            "&.Mui-checked": {
                              color: "#ef3340",
                            },
                          }}
                        />
                      }
                      onClick={(e) => handleClick(e)}
                      label={cuisine.cuisineName}
                      labelPlacement="end"
                    />
                  ))}
                </InputGroup>
              </Col>
              <Col sm={12}>
                <Timings timings={timings} setTimings={setTimings} />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Subscription Plan</label>
                <RadioGroup
                  onChange={(e) => {
                    console.log(e.target.value);
                    let value = e.target.value;
                    setFormData({
                      ...formData,
                      restaurantSubscriptionPlan: value,
                    });
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  {subscriptionPlan?.map((subscriptionPlan) => (
                    <FormControlLabel
                      value={subscriptionPlan._id}
                      control={<Radio style={{ color: "#ef3340" }} />}
                      label={`$${subscriptionPlan.subscriptionPrice}-${subscriptionPlan.subscriptionMonth} months`}
                    />
                  ))}
                </RadioGroup>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            style={{ backgroundColor: "#39424e" }}
            onClick={handleClose}
          >
            Close
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              onClick={() => submitDetails(formData)}
            >
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddRestaurant;
