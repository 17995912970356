import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { baseURL } from "../../BaseURL";
import axios from "axios";

function DeleteNotification({ isLoading, setLoading, notificationId }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(notificationId);
  async function handleDelete() {
    setLoading(true);
    await axios
      .delete(
        baseURL + "/admin/notification/delete-notification/" + notificationId
      )
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setShow(false);
      });
  }

  return (
    <div>
      <IconButton
        style={{ width: "20px", height: "20px" }}
        onClick={handleShow}
      >
        <DeleteIcon style={{ width: "15px", height: "15px", color: "red" }} />
      </IconButton>
      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Delete Notification</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to delete this Notification ?</Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              onClick={() => handleDelete()}
            >
              Yes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeleteNotification;
