import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import { InputGroup, FormControl, Button, Spinner } from "react-bootstrap";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios";
import PreviewIcon from "@mui/icons-material/Preview";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteMenu from "./DeleteMenu";
import { baseURL } from "../../../../BaseURL";

const Menu = ({ restaurantId, restaurantMenus, isLoading, setLoading }) => {
  const [menuName, setMenuName] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isMenuLoading, setMenuLoading] = useState("");

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  useEffect(() => {}, [isLoading]);

  const handleSubmission = async () => {
    const formData = new FormData();

    formData.append("menuName", menuName);
    formData.append("restaurantMenu", selectedFile);
    setLoading(true);

    await axios
      .post(
        baseURL + "/restaurant/upload-restaurant-menus/" + restaurantId,
        formData
      )
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);
        setMenuName("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div>
      <div className="heading">
        <div>
          <p>
            <b>Total Menu</b> :<span>{restaurantMenus?.length}</span>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItem: "center",
            justifyContent: "space-evenly",
            width: "40rem",
          }}
        >
          <div>
            <InputGroup>
              <FormControl
                placeholder="Menu Name"
                aria-label="Menu Name"
                value={menuName}
                onChange={(e) => {
                  setMenuName(e.target.value);
                }}
              />
            </InputGroup>
          </div>

          <div>
            <InputGroup>
              <FormControl
                placeholder="Menu Name"
                aria-label="Menu Name"
                type="file"
                onChange={changeHandler}
                style={{ padding: "2px" }}
              />
            </InputGroup>
          </div>

          {isLoading === true ? (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "35px",
                width: "160px",
                marginLeft: "5px",
              }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "35px",
                width: "160px",
                textAlign: "center",
                marginLeft: "5px",
              }}
              onClick={() => handleSubmission()}
              disabled={!menuName || !selectedFile ? true : false}
            >
              Add
            </Button>
          )}
        </div>
      </div>
      <div className="data-table">
        <TableContainer
          style={{ borderRadius: "5px", backgroundColor: "white" }}
        >
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <b>ID</b>
                </TableCell>
                <TableCell align="left">
                  <b>Menu Name</b>
                </TableCell>
                <TableCell align="left">
                  <b>Created On</b>
                </TableCell>
                <TableCell align="left">
                  <b>Status</b>
                </TableCell>
                <TableCell align="center">
                  <b>Pdf Link</b>
                </TableCell>
                <TableCell align="left">
                  <b>Action</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {restaurantMenus.map((row, index) => {
                return (
                  <TableRow>
                    <TableCell>{row._id.substring(0, 10) + "..."}</TableCell>
                    <TableCell>
                      <b style={{ color: "#ef3340" }}> {row?.menuName}</b>
                    </TableCell>
                    <TableCell>
                      {new Date().toISOString().split("T")[0]}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItem: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.442 2.51585C9.26012 1.98926 7.93972 1.8588 6.67767 2.14394C5.41563 2.42908 4.27957 3.11455 3.43893 4.0981C2.59828 5.08165 2.0981 6.31059 2.01297 7.60164C1.92783 8.89269 2.26232 10.1767 2.96654 11.2621C3.67075 12.3475 4.70697 13.1762 5.92064 13.6246C7.13432 14.073 8.46042 14.117 9.70117 13.7502C10.9419 13.3833 12.0309 12.6252 12.8056 11.589C13.5803 10.5527 13.9992 9.29371 14 7.99985V7.3869C14 7.01871 14.2984 6.72024 14.6666 6.72024C15.0348 6.72024 15.3333 7.01871 15.3333 7.3869V8.00024C15.3324 9.58161 14.8203 11.1207 13.8735 12.3873C12.9266 13.6539 11.5957 14.5804 10.0792 15.0288C8.56274 15.4772 6.94195 15.4233 5.45857 14.8753C3.97519 14.3273 2.70871 13.3144 1.848 11.9878C0.987288 10.6612 0.578473 9.09186 0.682522 7.51391C0.786572 5.93596 1.39791 4.43392 2.42536 3.2318C3.45282 2.02968 4.84134 1.1919 6.38383 0.84339C7.92633 0.494884 9.54016 0.65433 10.9846 1.29795C11.3209 1.4478 11.4721 1.84192 11.3222 2.17824C11.1724 2.51455 10.7783 2.66571 10.442 2.51585Z"
                            fill="#068932"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.1378 2.19503C15.3982 2.45525 15.3984 2.87736 15.1382 3.13784L8.47156 9.81117C8.34656 9.9363 8.17695 10.0066 8.00009 10.0067C7.82322 10.0067 7.65358 9.93647 7.52851 9.8114L5.52851 7.8114C5.26816 7.55106 5.26816 7.12895 5.52851 6.8686C5.78886 6.60825 6.21097 6.60825 6.47132 6.8686L7.99968 8.39696L14.1949 2.1955C14.4552 1.93502 14.8773 1.93481 15.1378 2.19503Z"
                            fill="#068932"
                          />
                        </svg>
                        <p style={{ marginLeft: "5px" }}>Published</p>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <a href={row?.pdfLink} target="_blank">
                        <IconButton>
                          <FileDownloadIcon />
                        </IconButton>
                      </a>
                      <a
                        href="#"
                        style={{ marginLeft: 5 }}
                        onClick={(e) => {
                          e.preventDefault();
                          const printJS = require("print-js");
                          printJS({ printable: row?.pdfLink });
                        }}
                      >
                        <IconButton>
                          <PreviewIcon />
                        </IconButton>
                      </a>
                    </TableCell>
                    <TableCell>
                      {isMenuLoading === row?._id ? (
                        <CircularProgress
                          style={{
                            width: "20px",
                            height: "20px",
                            marginLeft: "10px",
                            color: "black",
                            marginTop: "10px",
                          }}
                        />
                      ) : (
                        <DeleteMenu
                          restaurantId={restaurantId}
                          setMenuLoading={setMenuLoading}
                          pdfLink={row?.pdfLink}
                          id={row?._id}
                          setLoading={setLoading}
                          isLoading={isLoading}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Menu;
