import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";

function SideBar({ userData }) {
  return (
    <div className="w-3/12">
      <div className="w-full py-2 px-10 bg-white min-h-screen">
        <div className="bg-indigo-900 p-3 mt-3 text-white rounded-md flex justify-center align-center">
          <Avatar
            alt=""
            style={{ width: "90px", height: "90px" }}
            src={userData.userProfileImage}
          />
        </div>

        {userData && (
          <div className="my-2 p-1 text-xs rounded-md shadow-md border-">
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Name</span> :{" "}
              </span>{" "}
              {userData && userData?.userName}{" "}
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Phone</span> :{" "}
              </span>{" "}
              {userData && userData?.userPhone}{" "}
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Date of Birth</span>:{" "}
              </span>{" "}
              {userData && userData?.userDOB}{" "}
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Cuisines Preferences</span>:{" "}
              </span>{" "}
              {userData &&
                userData?.cuisinePreferences?.map((cuisine, index) => {
                  if (index !== userData?.cuisinePreferences.length - 1)
                    return cuisine + ", ";
                  else return cuisine;
                })}
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Loyality Points</span>:{" "}
              </span>{" "}
              {userData && userData?.userLoyalityPoints}{" "}
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Email</span> :{" "}
              </span>{" "}
              {userData && userData?.userEmail}{" "}
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">ID</span> :{" "}
              </span>{" "}
              {userData && userData?._id}{" "}
            </p>
            {/* <p className="font-medium  my-4 px-2">
                {" "}
                <span className="text-gray-400 font-light">
                  Cuisines :{" "}
                </span>{" "}
                {userData?.cuisineNames.map((cuisine) => ({ cuisine }))}
              </p> */}

            {/* <div class="flex items-center justify-evenly mb-3">
                <button
                  onClick={() => handleShow5()}
                  style={{ color: "#EF3340" }}
                  className="border border-red-300 font-semibold p-2 w-20 text-xs font-medium rounded-md"
                >
                  {" "}
                  Edit
                </button>
                <UpdateRestaurant
                  show={show5}
                  setShow={setShow5}
                  handleClose={handleClose5}
                  handleShow={handleShow5}
                  restaurantData={userData}
                  restaurantId={restaurantId}
                />

                <button
                  onClick={() => handleShow4()}
                  style={{ backgroundColor: "#EF3340" }}
                  className="border border-red-300 text-white font-semibold p-2 w-20 text-xs font-medium rounded-md"
                >
                  {" "}
                  Delete
                </button>
                <Modal show={show4} onHide={handleClose4} centered>
                  <Modal.Body
                    class="font-semibold"
                    style={{
                      textAlign: "center",
                      margin: "10px",
                      padding: "5px",
                    }}
                  >
                    Are you sure you want to delete this restaurant?
                  </Modal.Body>
                  <Modal.Footer
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button variant="secondary" onClick={handleClose4}>
                      Cancel
                    </Button>
                    <Button
                      style={{ backgroundColor: "#EF3340", font: "500" }}
                      onClick={handleDelete}
                    >
                      Yes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div> */}
          </div>
        )}

        {!userData && (
          <div className="my-1 p-1 text-xs  ">
            <p className="font-medium  my-4 ">
              Email : <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Phone :<Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Website : <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Location : <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Owner : <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Halal Certificate
              <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Total Capacity
              <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Table Count
              <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              <Skeleton />
            </p>

            <button className="border p-2 text-xs font-medium my-2">
              <Skeleton width={100} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default SideBar;
