import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  InputGroup,
  FormControl,
  Button,
  Spinner,
  Col,
  Row,
} from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import EditSubscription from "./EditSubscription";
import axios from "axios";
import { baseURL } from "../../../BaseURL";

const subscriptionMonths = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 6,
    label: 6,
  },
  {
    value: 12,
    label: 12,
  },
  {
    value: 24,
    label: 24,
  },
];

function Subscription() {
  const [months, setMonths] = React.useState(1);
  const [price, setPrice] = React.useState(0);
  const [isLoading, setLoading] = useState(false);
  const [allSubscription, setAllSubscription] = useState([]);

  const handleChange = (event) => {
    setMonths(event.target.value);
  };

  useEffect(() => {
    axios
      .get(baseURL + "/admin/get-all-subscription-plans")
      .then((res) => setAllSubscription(res.data.data));
  }, [isLoading]);

  async function handleSubmission() {
    const body = {
      subscriptionMonth: months,
      subscriptionPrice: price,
    };

    setLoading(true);
    await axios
      .post(baseURL + "/admin/add-subscription-plan", body)
      .then((res) => {
        setLoading(false);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log("Error");
      });
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px 35px",
        }}
      >
        <p>
          <b>Subscription Plans</b>
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "550px",
            padding: "2px",
          }}
        >
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            {/* <TextField
              id="standard-select-months"
              select
              label="Select Subscription Months"
              value={months}
              onChange={handleChange}
              variant="standard"
              style={{ width: "100%" }}
            >
              {subscriptionMonths.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField> */}
            <label>Subscription Month</label>

            <select
              style={{
                height: "calc(1.5em + 0.5rem)",
                padding: ".375rem",
                border: "1px solid #ced4da",
                borderRadius: "5px",
                width: "90%",
              }}
              onChange={(e) => handleChange}
            >
              {subscriptionMonths.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  // style={{ padding: "5px", height: "30px" }}
                >
                  {option.label} month
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Subscription Price</label>
            <InputGroup>
              <FormControl
                placeholder="Set Subscription Price"
                aria-label="Set Subscription Price"
                value={price}
                type="number"
                style={{ marginRight: "20px" }}
                onChange={(e) => setPrice(e.target.value)}
              />
            </InputGroup>
          </div>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "35px",
                width: "160px",
              }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "35px",
                width: "100px",
                textAlign: "center",
                marginTop: "28px",
              }}
              onClick={() => handleSubmission()}
            >
              Add
            </Button>
          )}
        </div>
      </div>
      <Row style={{ margin: "20px" }}>
        {allSubscription.map((subscription) => (
          <Col
            sm={2.5}
            style={{
              borderRadius: "5px",
              padding: "20px",
              width: "25%",
              margin: "10px 20px",
              fontSize: "13px",
              backgroundColor: "#39424e",
              color: "white",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p>
                <b>Subscription Month</b>- {subscription?.subscriptionMonth}
              </p>
              <p>
                <b>Subscription Price</b>- ${subscription?.subscriptionPrice}
              </p>
            </div>
            <div>
              <EditSubscription
                isLoading={isLoading}
                setLoading={setLoading}
                subscriptionMonth={subscription.subscriptionMonth}
                subscriptionPrice={subscription.subscriptionPrice}
                subscriptionId={subscription._id}
              />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Subscription;
