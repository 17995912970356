import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import { CsvBuilder } from "filefy";
import AddRestaurants from "./AddRestaurant";
import { baseURL } from "../../../BaseURL";
import Featured from "../FeaturedRestaurants/Featured";
import Payments from "../PaymentsDue/Payments";
import AllReviews from "../../users/allReviews/AllReviews";
import AllEnquires from "../../users/allEnquires/AllEnquires";
import TechSupport from "../TechSupport/TechSupport";
import MiniNavbar from "./MiniNavbar";
import "./AllRestaurants.css";
import { useSnackbar } from "notistack";

const columns = [
  { id: "Thumbnail", label: "Thumbnail", maxWidth: "90px" },
  { id: "Name", label: "Name" },
  {
    id: "Type",
    label: "Type",
    maxWidth: "100px",
  },
  {
    id: "$Due",
    label: "Due(S$)",
  },
  {
    id: "Location",
    label: "Location",
    maxWidth: "200px",
  },
  {
    id: "Created On",
    label: "Created",
  },
  {
    id: "Reservation",
    label: "Reservation",
  },
  {
    id: "Max Seating",
    label: "Max Seating",
  },
  {
    id: "Owner",
    label: "Owner",
  },
  {
    id: "Contact",
    label: "Contact",
  },
  {
    id: "Status",
    label: "Status",
  },
];

function AllRestaurants() {
  const [activeTab, setActiveTab] = useState(0);
  const [allRestaurantsData, setAllRestaurantsData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function handleChange(e) {
    const searchedLetter = e.target.value.toLowerCase();
    const restaurants = allRestaurantsData.filter((restaurant) => {
      if (
        restaurant.restaurantName
          .substring(0, searchedLetter.length)
          .toLowerCase() === searchedLetter
      )
        return restaurant;
    });
    setFilteredRestaurants(restaurants);
  }

  function findIcon(status) {
    if (status === "IN-ACTIVE")
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99996 1.99984C4.68625 1.99984 1.99996 4.68613 1.99996 7.99984C1.99996 11.3135 4.68625 13.9998 7.99996 13.9998C11.3137 13.9998 14 11.3135 14 7.99984C14 4.68613 11.3137 1.99984 7.99996 1.99984ZM0.666626 7.99984C0.666626 3.94975 3.94987 0.666504 7.99996 0.666504C12.05 0.666504 15.3333 3.94975 15.3333 7.99984C15.3333 12.0499 12.05 15.3332 7.99996 15.3332C3.94987 15.3332 0.666626 12.0499 0.666626 7.99984Z"
            fill="#D11534"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.4713 5.52827C10.7317 5.78862 10.7317 6.21073 10.4713 6.47108L6.47132 10.4711C6.21097 10.7314 5.78886 10.7314 5.52851 10.4711C5.26816 10.2107 5.26816 9.78862 5.52851 9.52827L9.52851 5.52827C9.78886 5.26792 10.211 5.26792 10.4713 5.52827Z"
            fill="#D11534"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.52851 5.52827C5.78886 5.26792 6.21097 5.26792 6.47132 5.52827L10.4713 9.52827C10.7317 9.78862 10.7317 10.2107 10.4713 10.4711C10.211 10.7314 9.78886 10.7314 9.52851 10.4711L5.52851 6.47108C5.26816 6.21073 5.26816 5.78862 5.52851 5.52827Z"
            fill="#D11534"
          />
        </svg>
      );
    else
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.442 2.51585C9.26012 1.98926 7.93972 1.8588 6.67767 2.14394C5.41563 2.42908 4.27957 3.11455 3.43893 4.0981C2.59828 5.08165 2.0981 6.31059 2.01297 7.60164C1.92783 8.89269 2.26232 10.1767 2.96654 11.2621C3.67075 12.3475 4.70697 13.1762 5.92064 13.6246C7.13432 14.073 8.46042 14.117 9.70117 13.7502C10.9419 13.3833 12.0309 12.6252 12.8056 11.589C13.5803 10.5527 13.9992 9.29371 14 7.99985V7.3869C14 7.01871 14.2984 6.72024 14.6666 6.72024C15.0348 6.72024 15.3333 7.01871 15.3333 7.3869V8.00024C15.3324 9.58161 14.8203 11.1207 13.8735 12.3873C12.9266 13.6539 11.5957 14.5804 10.0792 15.0288C8.56274 15.4772 6.94195 15.4233 5.45857 14.8753C3.97519 14.3273 2.70871 13.3144 1.848 11.9878C0.987288 10.6612 0.578473 9.09186 0.682522 7.51391C0.786572 5.93596 1.39791 4.43392 2.42536 3.2318C3.45282 2.02968 4.84134 1.1919 6.38383 0.84339C7.92633 0.494884 9.54016 0.65433 10.9846 1.29795C11.3209 1.4478 11.4721 1.84192 11.3222 2.17824C11.1724 2.51455 10.7783 2.66571 10.442 2.51585Z"
            fill="#068932"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.1378 2.19503C15.3982 2.45525 15.3984 2.87736 15.1382 3.13784L8.47156 9.81117C8.34656 9.9363 8.17695 10.0066 8.00009 10.0067C7.82322 10.0067 7.65358 9.93647 7.52851 9.8114L5.52851 7.8114C5.26816 7.55106 5.26816 7.12895 5.52851 6.8686C5.78886 6.60825 6.21097 6.60825 6.47132 6.8686L7.99968 8.39696L14.1949 2.1955C14.4552 1.93502 14.8773 1.93481 15.1378 2.19503Z"
            fill="#068932"
          />
        </svg>
      );
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function extractRowsFromData() {
    const rows = [];
    allRestaurantsData.forEach((restuarantData) => {
      let row = [];
      row.push(
        restuarantData?._id,
        restuarantData?.restaurantName,
        restuarantData?.restaurantType,
        restuarantData?.restaurantSubscriptionPlan?.subscriptionPrice,
        restuarantData?.restaurantAddress,
        restuarantData.createdAt.substring(0, 10),
        restuarantData.isPrepaidBookingAvailable,
        restuarantData.restaurantMaximumTableCapacity,
        restuarantData.restaurantOwner,
        restuarantData.restaurantPhone
      );

      rows.push(row);
    });
    return rows;
  }

  function exportToCSV() {
    var csvBuilder = new CsvBuilder("restaurant_list.csv")
      .setColumns([
        "Id",
        "Restaurant",
        "Type",
        "Due",
        "Location",
        "Created On",
        "Reservation",
        "Max Seating",
        "Owner",
        "Contact",
      ])
      .addRows(extractRowsFromData())
      .exportFile();
  }

  useEffect(() => {
    axios
      .get(baseURL + "/restaurant/get-all-restaurants")
      .then((res) => {
        setAllRestaurantsData(res.data.data);
        setFilteredRestaurants(res.data.data.reverse());
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }, [isLoading]);

  return (
    <div className="all-restaurants">
      <MiniNavbar activeTab={activeTab} setActiveTab={setActiveTab} />
      <div>
        {(activeTab === 0 || activeTab === 1) && (
          <div style={{ backgroundColor: "white" }}>
            <div className="header">
              <p>
                <b>Total Restaurants</b> : {allRestaurantsData.length}
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <Button
                  style={{
                    width: "250px",
                    backgroundColor: "#EF3340",
                    margin: "0 5px",
                  }}
                  onClick={handleShow}
                >
                  Add Restaurants
                </Button>
                <AddRestaurants
                  show={show}
                  setShow={setShow}
                  handleClose={handleClose}
                  handleShow={handleShow}
                  setAllRestaurantLoading={setLoading}
                />

                <Button
                  style={{
                    width: "250px",
                    backgroundColor: "#EF3340",
                    margin: "0 5px",
                  }}
                  onClick={exportToCSV}
                >
                  Export to Excel
                </Button>
                <InputGroup>
                  <FormControl
                    placeholder="Search Restaurants"
                    aria-label="Search Restaurants"
                    onChange={(e) => handleChange(e)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="restaurant-data-table">
              <TableContainer exportButton={true}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "red" }}>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align="left"
                          style={{
                            width: column.maxWidth,
                          }}
                        >
                          <b>{column.label}</b>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {filteredRestaurants.length === 0 ? (
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {filteredRestaurants
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow key={row._id}>
                              <TableCell>
                                <img
                                  src={row?.restaurantThumbnail}
                                  alt="Thumbnail"
                                  style={{
                                    width: "90px",
                                    height: "50px",
                                    borderRadius: "5px",
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <Link to={`/restaurant/${row?._id}`}>
                                  <b style={{ color: "#ef3340" }}>
                                    {row?.restaurantName}
                                  </b>
                                </Link>
                              </TableCell>
                              <TableCell>{row?.restaurantType}</TableCell>
                              <TableCell>
                                {row?.restaurantSubscriptionPlan
                                  ?.subscriptionPrice
                                  ? row.restaurantSubscriptionPlan
                                      .subscriptionPrice
                                  : "NA"}
                              </TableCell>
                              <TableCell>{row?.restaurantAddress}</TableCell>
                              <TableCell>
                                {row?.createdAt.substring(0, 10)}
                              </TableCell>
                              <TableCell>
                                {row?.isPrepaidBookingAvailable === true
                                  ? "Yes"
                                  : "No"}
                              </TableCell>
                              <TableCell>
                                {row?.restaurantMaximumTableCapacity}
                              </TableCell>
                              <TableCell>{row?.restaurantOwner}</TableCell>
                              <TableCell>{row?.restaurantPhone}</TableCell>
                              <TableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItem: "center",
                                    justifyContent: "space-between",
                                    textAlign: "left",
                                  }}
                                >
                                  {findIcon(row?.status)}{" "}
                                  {row?.status ? row?.status : "Active"}
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={allRestaurantsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        )}
        {/* 
        {activeTab === 1 && <Payments restaurantData={allRestaurantsData} />} */}
        {activeTab === 2 && <Featured />}
        {activeTab === 3 && <AllReviews />}
        {activeTab === 4 && <AllEnquires />}
        {activeTab === 5 && <TechSupport />}
      </div>
    </div>
  );
}

export default AllRestaurants;
