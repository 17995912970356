import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { baseURL } from "../../../../BaseURL";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { useSnackbar } from "notistack";

function DeleteImageModal({ restaurantId, imageLink, isLoading, setLoading }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showDelete, setShowDelete] = useState(false);

  const handleCloseDelete = () => {
    setShowDelete(false);
  };
  const handleShowDelete = () => setShowDelete(true);
  // console.log(restaurantId, imageLink);

  async function handleDelete() {
    const body = {
      key: imageLink,
    };
    setLoading(true);
    await axios
      .post(
        baseURL + "/restaurant/delete-restaurant-images/" + restaurantId,
        body
      )
      .then((res) => {
        enqueueSnackbar("Image deleted successfully", {
          variant: "success",
        });
        setLoading(false);
        handleCloseDelete();
      })
      .catch((err) => {
        enqueueSnackbar("Error deleting image", {
          variant: "error",
        });
        setLoading(false);
        handleCloseDelete();
      });
  }
  return (
    <div>
      <IconButton onClick={handleShowDelete}>
        <DeleteIcon />
      </IconButton>
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Body
          class="font-semibold"
          style={{
            textAlign: "center",
            margin: "10px",
            padding: "5px",
          }}
        >
          Are you sure you want to delete this image?
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button variant="secondary" onClick={handleCloseDelete}>
            Cancel
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "30px",
                width: "90px",
                marginLeft: "5px",
              }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "30px",
                width: "70px",
                textAlign: "center",
                marginLeft: "5px",
              }}
              onClick={handleDelete}
            >
              Yes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeleteImageModal;
