import React, { useState, useEffect } from "react";
import {
  InputGroup,
  FormControl,
  Button,
  Spinner,
  Col,
  Row,
} from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { baseURL } from "../../../BaseURL";
function TechSupport() {
  const [allTechSupport, setAllTechSupport] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingTechSupport, setLoadingTechSupport] = useState("");
  const [TechSupportName, setTechSupportName] = useState("");

  useEffect(() => {
    axios
      .get(baseURL + "/admin/get-all-tech-support-topics")
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data) setAllTechSupport(res.data.data);
      })
      .catch((err) => {
        console.log("Error");
      });
  }, [isLoading, isLoadingTechSupport]);

  function handleSubmission() {
    const data = {
      topic: TechSupportName,
    };
    setLoading(true);
    axios
      .post(baseURL + "/admin/add-tech-support-topic", data)
      .then((res) => {
        if (res.data.data) console.log(res.data.data);
        setLoading(false);
        setTechSupportName("");
      })
      .catch((err) => {
        console.log("Error");
      });
  }

  async function handleDelete(TechSupportId) {
    setLoadingTechSupport(TechSupportId);
    console.log(TechSupportId);
    await axios
      .delete(baseURL + "/admin/delete-tech-support-topic/" + TechSupportId)
      .then((res) => {
        console.log(res);
        if (res.data.data) {
          setLoadingTechSupport("");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingTechSupport("");
      });
  }

  console.log(allTechSupport);

  return (
    <div>
      <div className="heading">
        <div>
          <p style={{ marginTop: "-20px", fontSize: "16px" }}>
            <b>Total Tech-Support </b> : {allTechSupport.length}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItem: "center",
            justifyContent: "space-between",
            width: "37rem",
          }}
        >
          <div style={{ width: "30rem" }}>
            <label>Tech-Support Content</label>
            <InputGroup>
              <FormControl
                placeholder="Tech-Support Content"
                aria-label="Tech-Support Content"
                value={TechSupportName}
                onChange={(e) => setTechSupportName(e.target.value)}
              />
            </InputGroup>
          </div>

          {isLoading === true ? (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "35px",
                marginTop: "32px",
              }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                width: "90px",
                height: "35px",
                marginTop: "32px",
                textAlign: "center",
              }}
              onClick={() => handleSubmission()}
            >
              Add
            </Button>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row
          sm={12}
          style={{
            width: "70rem",
            padding: "0 20px",
          }}
        >
          {allTechSupport.length !== 0 &&
            allTechSupport.map((TechSupport, index) => (
              <Col
                lg={12}
                style={{
                  margin: "5px 0",
                }}
              >
                <div
                  class="shadow-md"
                  style={{
                    textAlign: "center",
                    minHeight: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    padding: "5px 15px",
                    backgroundColor: "#39424e",
                    color: "white",
                  }}
                >
                  <p style={{ width: "60rem", textAlign: "left" }}>
                    {index + 1}. {TechSupport.topic}
                  </p>
                  <div>
                    {isLoadingTechSupport === TechSupport._id ? (
                      <CircularProgress
                        style={{
                          height: "20px",
                          width: "20px",
                          color: "white",
                        }}
                      />
                    ) : (
                      <DeleteIcon
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={(e) => handleDelete(TechSupport._id)}
                      />
                    )}
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </div>
  );
}

export default TechSupport;
