import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Button,
  Spinner,
  Modal,
  FormControl,
  InputGroup,
  Row,
  Col,
} from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { baseURL } from "../../BaseURL";
import Compressor from "compressorjs";

function AddBlogs({ isLoading, setLoading }) {
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [cuisineTag, setCuisineTag] = useState("");
  const [authorTag, setAuthorTag] = useState("");
  const [categoryTag, setCategoryTag] = useState("");
  const [allBlogTags, setAllBlogTags] = useState([]);
  const [allTags, setAllTags] = useState({
    cuisineTags: [],
    authorTags: [],
    categoryTags: [],
  });
  const [tagName, setTagName] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    blogTitle: "",
    createdBy: "",
    blogDesc: "",
    blogURL: "",
  });

  useEffect(() => {
    axios
      .get(baseURL + "/admin/get-all-blog-tags")
      .then((res) => {
        setAllBlogTags(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  function handleSubmit(formData) {
    console.log(formData);
    new Compressor(selectedFile, {
      quality: 0.3, // 0.6 can also be used, but its not recommended to go below.
      success: async (compressedResult) => {
        console.log(compressedResult);
        setLoading(true);
        const data = new FormData();
        data.append("blogImage", compressedResult);
        data.append("blogTitle", formData.blogTitle);
        data.append("blogURL", formData.blogURL);
        data.append("blogDesc", formData.blogDesc);
        data.append("createdBy", formData.createdBy);
        data.append("blogTags", tagName);
        data.append("categoryTags", allTags.categoryTags);
        data.append("authorTags", allTags.authorTags);
        data.append("cuisineTags", allTags.cuisineTags);
        console.log(data);
        const res = await axios.post(baseURL + "/admin/add-blog", data);
        console.log(res.data.data);
        setLoading(false);
        setShow(false);
      },
    });
  }

  function handleClick(e) {
    if (e.target.checked) {
      setTagName((prev) => [
        ...prev,
        e.target.parentNode.nextSibling.innerText,
      ]);
    } else {
      setTagName(
        tagName.filter(
          (cuisine) => cuisine !== e.target.parentNode.nextSibling.innerText
        )
      );
    }
  }

  // console.log(allBlogTags);
  return (
    <>
      <Button
        style={{
          width: "150px",
          backgroundColor: "#EF3340",
          margin: "0 5px",
        }}
        onClick={handleShow}
      >
        Add Blogs
      </Button>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Add Blog</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row sm={12}>
            <Col>
              <label>Blog Title</label>
              <InputGroup>
                <FormControl
                  placeholder="Enter Blog Title"
                  aria-label="Adv Name"
                  onChange={(e) => {
                    setFormData({ ...formData, blogTitle: e.target.value });
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={6}>
              <label>Blog Link</label>
              <InputGroup>
                <FormControl
                  placeholder="Enter Blog Link"
                  aria-label="Adv Link"
                  onChange={(e) => {
                    setFormData({ ...formData, blogURL: e.target.value });
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={6}>
              <label>Blog Desc</label>
              <InputGroup>
                <FormControl
                  as="textarea"
                  aria-label="Blog Desc"
                  placeholder="Enter Blog Desc"
                  onChange={(e) => {
                    setFormData({ ...formData, blogDesc: e.target.value });
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={6}>
              <label>Created By</label>
              <InputGroup>
                <FormControl
                  placeholder="Enter Created By"
                  aria-label="Created By"
                  onChange={(e) => {
                    setFormData({ ...formData, createdBy: e.target.value });
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={4} style={{ flex: 1 }}>
              <label style={{ display: "block" }}>Cuisine Tags</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  margin: "10px 0",
                }}
              >
                {allTags.cuisineTags.map((cuisineTag) => (
                  <div
                    style={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      maxWidth: "90px",
                      overflowWrap: "break-word",
                      fontSize: "11px",
                      margin: "2px",
                      padding: "2px 3px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          overflowWrap: "break-word",
                          maxWidth: "50px",
                          padding: "1px",
                        }}
                      >
                        {cuisineTag}
                      </p>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          console.log(
                            e.target.parentNode.previousSibling.innerText
                          );
                          const data = allTags.cuisineTags.filter((tag) => {
                            console.log(tag);
                            return (
                              tag.toLowerCase() !==
                              e.target.parentNode.previousSibling.innerText.toLowerCase()
                            );
                          });
                          console.log(data);
                          setAllTags({ ...allTags, cuisineTags: data });
                        }}
                      >
                        <ClearIcon fontSize="sm" />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
              <InputGroup>
                <FormControl
                  placeholder="Enter Created By"
                  aria-label="Created By"
                  onChange={(e) => setCuisineTag(e.target.value)}
                />
                <IconButton
                  onClick={(e) => {
                    console.log(e.target.parentNode.previousSibling);
                    let value = e.target.parentNode.previousSibling?.value;
                    let cuisineTags = allTags.cuisineTags;
                    if (cuisineTag) {
                      const data = allTags.cuisineTags.filter(
                        (tag) => tag.toLowerCase() === cuisineTag.toLowerCase()
                      );
                      console.log(data);
                      if (data.length === 0) {
                        setAllTags({
                          ...allTags,
                          cuisineTags: [...cuisineTags, cuisineTag],
                        });
                      }
                    }
                  }}
                >
                  <AddIcon />
                </IconButton>
              </InputGroup>
            </Col>
            <Col sm={4}>
              <label>Author Tags</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  margin: "10px 0",
                }}
              >
                {allTags.authorTags.map((authorTags) => (
                  <div
                    style={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      maxWidth: "90px",
                      overflowWrap: "break-word",
                      fontSize: "11px",
                      margin: "2px",
                      padding: "2px 3px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          overflowWrap: "break-word",
                          maxWidth: "50px",
                          padding: "1px",
                        }}
                      >
                        {authorTags}
                      </p>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          console.log(
                            e.target.parentNode.previousSibling.innerText
                          );
                          const data = allTags.authorTags.filter((tag) => {
                            console.log(tag);
                            return (
                              tag.toLowerCase() !==
                              e.target.parentNode.previousSibling.innerText.toLowerCase()
                            );
                          });
                          console.log(data);
                          setAllTags({ ...allTags, authorTags: data });
                        }}
                      >
                        <ClearIcon fontSize="sm" />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
              <InputGroup>
                <FormControl
                  placeholder="Enter Created By"
                  aria-label="Created By"
                  onChange={(e) => {
                    setAuthorTag(e.target.value);
                  }}
                />
                <IconButton
                  onClick={(e) => {
                    console.log(e.target.parentNode.previousSibling?.value);
                    let value = e.target.parentNode.previousSibling?.value;
                    let authorTags = allTags.authorTags;
                    if (authorTag) {
                      const data = allTags.authorTags.filter(
                        (tag) => tag.toLowerCase() === authorTag.toLowerCase()
                      );
                      console.log(data);
                      if (data.length === 0) {
                        setAllTags({
                          ...allTags,
                          authorTags: [...authorTags, authorTag],
                        });
                      }
                    }
                  }}
                >
                  <AddIcon />
                </IconButton>
              </InputGroup>
            </Col>
            <Col sm={4}>
              <label>Category Tags</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  margin: "10px 0",
                }}
              >
                {allTags.categoryTags.map((categoryTags) => (
                  <div
                    style={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      maxWidth: "90px",
                      overflowWrap: "break-word",
                      fontSize: "11px",
                      margin: "2px",
                      padding: "2px 3px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          overflowWrap: "break-word",
                          maxWidth: "50px",
                          padding: "1px",
                        }}
                      >
                        {categoryTags}
                      </p>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          console.log(
                            e.target.parentNode.previousSibling.innerText
                          );
                          const data = allTags.categoryTags.filter((tag) => {
                            console.log(tag);
                            return (
                              tag.toLowerCase() !==
                              e.target.parentNode.previousSibling.innerText.toLowerCase()
                            );
                          });
                          console.log(data);
                          setAllTags({ ...allTags, categoryTags: data });
                        }}
                      >
                        <ClearIcon fontSize="sm" />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
              <InputGroup>
                <FormControl
                  placeholder="Enter Created By"
                  aria-label="Created By"
                  onChange={(e) => {
                    setCategoryTag(e.target.value);
                  }}
                />
                <IconButton
                  onClick={(e) => {
                    console.log(e.target.parentNode.previousSibling?.value);
                    let value = e.target.parentNode.previousSibling?.value;
                    let categoryTags = allTags.categoryTags;
                    if (categoryTag) {
                      const data = allTags.categoryTags.filter(
                        (tag) => tag.toLowerCase() === categoryTag.toLowerCase()
                      );
                      console.log(data);
                      if (data.length === 0) {
                        setAllTags({
                          ...allTags,
                          categoryTags: [...categoryTags, categoryTag],
                        });
                      }
                    }
                  }}
                >
                  <AddIcon />
                </IconButton>
              </InputGroup>
            </Col>
          </Row>
          <Row sm={12}>
            <Col sm={6}>
              <label>Blog Image</label>
              <InputGroup>
                <FormControl
                  type="file"
                  style={{ padding: "4px" }}
                  onChange={(e) => {
                    e.preventDefault();
                    setSelectedFile(e.target.files[0]);
                  }}
                />
              </InputGroup>
            </Col>
            {selectedFile && (
              <Col sm={6}>
                <img
                  style={{ width: "150px", height: "120px" }}
                  src={URL?.createObjectURL(selectedFile)}
                  alt="advertisment"
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              onClick={() => handleSubmit(formData)}
            >
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddBlogs;
