import React, { useState, useEffect } from "react";
import { Button, Row, Col, Spinner, Modal } from "react-bootstrap";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import ImageIcon from "@mui/icons-material/Image";
import axios from "axios";
import Compressor from "compressorjs";
import { baseURL } from "../../../../BaseURL";
import DeleteImageModal from "./DeleteImageModal";
import { useSnackbar } from "notistack";

function ImagesTab({ restaurantId, restaurantData, isLoading, setLoading }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoadingThumbnail, setLoadingThumbnail] = useState(false);
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [imagesArray, setImagesArray] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoadingImages, setLoadingImages] = useState(false);
  const [compressedFile, setCompressedFile] = useState(null);

  function uploadThumbnailImage(thumbnailImage) {
    new Compressor(thumbnailImage, {
      quality: 0.3, // 0.6 can also be used, but its not recommended to go below.
      success: async (compressedResult) => {
        setLoadingThumbnail(true);
        const formData = new FormData();
        formData.append("restaurantThumbnail", compressedResult);
        const res = await axios.post(
          baseURL + "/restaurant/upload-restaurant-thumbnail/" + restaurantId,
          formData
        );
        // console.log(res.data);
        setThumbnailImage(res.data.data.restaurantThumbnail);
        setLoadingThumbnail(false);
        setCompressedFile(res);
      },
    });
  }

  async function uploadSelectedFiles() {
    let count = 0;
    let length = selectedFiles.length;
    selectedFiles.forEach((file) => {
      new Compressor(file, {
        quality: 0.3, // 0.6 can also be used, but its not recommended to go below.
        success: async (compressedResult) => {
          const formData = new FormData();
          formData.append("restaurantImage", compressedResult);
          await axios
            .post(
              baseURL + "/restaurant/upload-restaurant-images/" + restaurantId,
              formData
            )
            .then((res) => {
              count = count + 1;
              setCompressedFile(res);
              if (count === length) {
                window.location.reload(true);
                enqueueSnackbar("Images added successfully", {
                  variant: "success",
                });
              }
            })
            .catch((err) => {
              setError(err?.response?.body?.message);
              enqueueSnackbar("Error uploading images", { variant: "error" });
            });
        },
      });
    });
  }

  //make a function that takes a object filelist and loops through it and converts it to base64 and set it to the images array

  const uploadImages = async () => {
    setLoading(true);
    setShow(true);
    await uploadSelectedFiles();
  };
  const removeImage = (e, index) => {
    e.preventDefault();
    setSelectedFiles(selectedFiles.filter((image, i) => i !== index));
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>
          {/* <b>Total Images : {restaurantData?.restaurantImages.length}</b> */}
          <b>Photos</b>
        </p>
        <button
          className="text-white font-bold px-4 py-2 rounded-md"
          style={{ backgroundColor: "#EF3340" }}
          onClick={() => handleShow()}
        >
          Add Images
        </button>
        <Modal show={show} size="lg" onHide={handleClose} centered>
          <Modal.Body style={{ textAlign: "center", fontSize: "18px" }}>
            <b>Upload Images</b>
            <div className="mt-4 p-1">
              <div className="flex justify-between ml-3">
                <div className="bg-white  w-72 mr-2 h-72 border border-gray-300 rounded-sm flex flex-col items-center shadow-2xl">
                  <p className="font-semibold text-gray-600 text-sm mt-1 leading-8 h-6 mx-2">
                    Thumbnail
                  </p>
                  <div
                    style={{
                      height: "10rem",
                      width: "15rem",
                      marginTop: "20px",
                      padding: "2px",
                    }}
                  >
                    {isLoadingThumbnail ? (
                      <Box sx={{ width: "100%", color: "grey.800" }}>
                        <LinearProgress color="inherit" />
                      </Box>
                    ) : thumbnailImage ? (
                      <img
                        className="w-90% h-25vh object-cover border border-gray-200 mt-2 ml-2.5 mb-2"
                        src={thumbnailImage}
                        alt=""
                      />
                    ) : (
                      <ImageIcon
                        size="large"
                        style={{
                          width: "5rem",
                          height: "5rem",
                          marginTop: "30px",
                        }}
                      />
                    )}
                  </div>
                  <label
                    for="file-upload"
                    style={{
                      backgroundColor: "#EF3340",
                      fontWeight: "bold",
                    }}
                    className={`text-white text-base border hover:scale-110 mt-4 bg-custom-background focus:outline-none flex justify-center px-4 py-2 rounded font-medium cursor-pointer
                        }`}
                  >
                    Choose Thumbnail
                  </label>
                  {isLoadingThumbnail ? (
                    <input
                      id="file-upload"
                      type="file"
                      disabled
                      style={{ display: "none" }}
                      onChange={(e) => {
                        // e.preventDefault();
                        uploadThumbnailImage(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <input
                      id="file-upload"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        // e.preventDefault();
                        uploadThumbnailImage(e.target.files[0]);
                      }}
                    />
                  )}
                </div>

                <div className="ml-4 bg-white px-2 py-3 border border-gray-300 rounded-sm w-9/12 shadow-2xl">
                  <div className="flex items-start justify-between pb-0">
                    <p className="font-semibold text-gray-600 text-sm leading-8 h-6 mx-2">
                      Restaurant Images (optional)
                    </p>
                    <label
                      style={{
                        backgroundColor: "#EF3340",
                        fontWeight: "bold",
                      }}
                      for="file-upload-images"
                      className="text-white text-base border hover:scale-110 bg-custom-background focus:outline-none flex justify-center px-4 py-2 rounded font-medium cursor-pointer"
                    >
                      Choose Images
                    </label>
                    {isLoading ? (
                      <input
                        id="file-upload-images"
                        type="file"
                        style={{ display: "none" }}
                        disabled
                        // onChange={(e) => {
                        //   // e.preventDefault();
                        //   handleFileList(e.target.files[0]);
                        // }}
                      />
                    ) : (
                      <input
                        id="file-upload-images"
                        type="file"
                        multiple="multiple"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          // e.preventDefault();
                          // handleFileList(e.target.files[0]);
                          console.log(e.target.files);
                          setSelectedFiles((prev) => [
                            ...prev,
                            e.target.files[0],
                          ]);
                        }}
                      />
                    )}
                    <input
                      id="file-upload-images"
                      type="file"
                      style={{ display: "none" }}
                      // onChange={(e) => {
                      //   // e.preventDefault();
                      //   handleFileList(e.target.files[0]);
                      // }}
                    />
                  </div>
                  <br />

                  <div className="flex flex-wrap justify-between w-full">
                    {isLoading ? (
                      <Box sx={{ width: "100%", color: "grey.800" }}>
                        <LinearProgress color="inherit" />
                      </Box>
                    ) : selectedFiles.length !== 0 ? (
                      <Row sm={2}>
                        {[...selectedFiles]?.map((image, index) => (
                          <Col key={index}>
                            <img
                              src={URL.createObjectURL(image)}
                              className="border border-gray-300 max-w-sm m-2 w-48 h-25vh  object-cover "
                              alt=""
                            />
                            <button
                              className="m-2 flex items-center"
                              onClick={(e) => removeImage(e, index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width="20px"
                                height="20px"
                              >
                                <path
                                  fill="#E04F5F"
                                  d="M504.1,256C504.1,119,393,7.9,256,7.9C119,7.9,7.9,119,7.9,256C7.9,393,119,504.1,256,504.1C393,504.1,504.1,393,504.1,256z"
                                />
                                <path
                                  fill="#FFF"
                                  d="M285,256l72.5-84.2c7.9-9.2,6.9-23-2.3-31c-9.2-7.9-23-6.9-30.9,2.3L256,222.4l-68.2-79.2c-7.9-9.2-21.8-10.2-31-2.3c-9.2,7.9-10.2,21.8-2.3,31L227,256l-72.5,84.2c-7.9,9.2-6.9,23,2.3,31c4.1,3.6,9.2,5.3,14.3,5.3c6.2,0,12.3-2.6,16.6-7.6l68.2-79.2l68.2,79.2c4.3,5,10.5,7.6,16.6,7.6c5.1,0,10.2-1.7,14.3-5.3c9.2-7.9,10.2-21.8,2.3-31L285,256z"
                                />
                              </svg>
                              &nbsp;Remove
                            </button>
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <ImageIcon
                        size="large"
                        style={{
                          width: "5rem",
                          height: "5rem",
                          marginTop: "30px",
                          marginLeft: "12rem",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {isLoading === true || isLoadingThumbnail === true ? (
              <Button
                variant="dark"
                style={{ backgroundColor: "#ef3340" }}
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: "5px" }}
                />
                <span className="visually-hidden">Loading...</span>
              </Button>
            ) : (
              <Button
                variant="dark"
                style={{ backgroundColor: "#ef3340" }}
                onClick={uploadImages}
              >
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
      <Row lg={5} max-sm={2} style={{ margin: "10px 0" }}>
        {restaurantData?.restaurantImages?.map((image, index) => (
          <Col key={index}>
            <img
              className="m-2 h-20vh object-cover rounded-md"
              src={image}
              key={index}
              alt=""
            />
            <DeleteImageModal
              restaurantId={restaurantId}
              imageLink={image}
              isLoading={isLoading}
              setLoading={setLoading}
            />
          </Col>
        ))}
        <Col> <p className="rounded-md flex items-center justify-center m-2 h-20vh object-cover" 
          style={{ backgroundColor: "#E7EEEF",color:'#097BBF' }}
          onClick={() => handleShow()}>add images</p></Col>
      </Row>
    </div>
  );
}

export default ImagesTab;
