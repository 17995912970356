import React, { useState, useEffect, useRef } from "react";
import RecommendationItem from "./RecommedationItem";
import { InputGroup, FormControl, Button, Spinner } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import "../BaseStyles.css";
import axios from "axios";
import { baseURL } from "../../../BaseURL";

const RecommendationTable = () => {
  const [allRecommendation, setAllRecommendation] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [recommendationName, setRecommendationName] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleSubmission = async () => {
    const formData = new FormData();

    formData.append("recommendationName", recommendationName);
    formData.append("recommendationImage", selectedFile);
    setLoading(true);
    console.log(recommendationName, selectedFile);

    await axios
      .post(
        baseURL + "/restaurant/recommendations/add-recommendations",
        formData
      )
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);
        setSelectedFile();
        setRecommendationName("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    try {
      axios
        .get(baseURL + "/restaurant/recommendations/get-all-recommendations")
        .then((res) => {
          setAllRecommendation(res.data.data);
          console.log(res.data);
        });
    } catch (e) {
      console.log(e);
    }
  }, [isLoading, isLoadingDelete]);

  return (
    <div
      className="wrapper"
      style={{ backgroundColor: "white", padding: "10px 5px 20px" }}
    >
      <div className="recommendation-table">
        <div className="heading">
          <div>
            <p style={{ marginTop: "-20px", fontSize: "16px" }}>
              <b>Total Recommendations</b> : {allRecommendation.length}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItem: "center",
              justifyContent: "space-between",
              width: "40rem",
            }}
          >
            <div>
              <label>Recommendation Name</label>
              <InputGroup>
                <FormControl
                  placeholder="Recommendation Name"
                  aria-label="Recommendation Name"
                  onChange={(e) => setRecommendationName(e.target.value)}
                />
              </InputGroup>
            </div>

            <div>
              <label>Recommendation Image</label>
              <InputGroup>
                <FormControl
                  type="file"
                  style={{ padding: "4px" }}
                  onChange={changeHandler}
                />
              </InputGroup>
            </div>
            {isLoading === true ? (
              <Button
                variant="dark"
                style={{
                  backgroundColor: "#ef3340",
                  height: "35px",
                  marginTop: "32px",
                }}
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </Button>
            ) : (
              <Button
                variant="dark"
                style={{
                  backgroundColor: "#ef3340",
                  width: "90px",
                  height: "35px",
                  marginTop: "32px",
                  textAlign: "center",
                }}
                onClick={() => handleSubmission()}
              >
                Add
              </Button>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {isLoading === true ? (
            <Skeleton />
          ) : (
            allRecommendation.map((item, index) => {
              return (
                <RecommendationItem
                  item={item}
                  index={index + 1}
                  key={item._id}
                  isLoadingDelete={isLoadingDelete}
                  setLoadingDelete={setLoadingDelete}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default RecommendationTable;
