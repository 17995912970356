import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Spinner,
  FormControl,
  InputGroup,
  Col,
  Row,
} from "react-bootstrap";
import { baseURL } from "../../../BaseURL";
import axios from "axios";
import Compressor from "compressorjs";

function AddAdvertisment({ setLoading, totalAdvertisments, isLoading }) {
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [advertismentName, setAdvertismentName] = useState("");
  const [advertismentURL, setAdvertismentURL] = useState("");
  const [advertismentSortIndex, setAdvertismentSortIndex] =
    useState(totalAdvertisments);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleSubmit() {
    const formData = new FormData();
    formData.append("advertismentName", advertismentName);
    formData.append("advertismentURL", advertismentURL);
    formData.append("advertismentSortIndex", advertismentSortIndex);
    new Compressor(selectedFile, {
      quality: 0.3, // 0.6 can also be used, but its not recommended to go below.
      success: async (compressedResult) => {
        setLoading(true);
        formData.append("advertismentImage", compressedResult);
        console.log(formData);
        await axios
          .post(baseURL + "/admin/add-advertisment", formData)
          .then((res) => {
            console.log(res.data);
            setLoading(false);
            handleClose();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      },
    });
  }

  return (
    <>
      <Button
        style={{
          width: "150px",
          backgroundColor: "#EF3340",
          margin: "0 5px",
        }}
        onClick={handleShow}
      >
        Add Advertisment
      </Button>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Add Advertisment</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row sm={12}>
            <Col>
              <label>Advertisment Name</label>
              <InputGroup>
                <FormControl
                  placeholder="Enter Adv Name"
                  aria-label="Adv Name"
                  onChange={(e) => {
                    setAdvertismentName(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={6}>
              <label>Advertisment Link</label>
              <InputGroup>
                <FormControl
                  placeholder="Enter Adv Link"
                  aria-label="Adv Link"
                  onChange={(e) => {
                    setAdvertismentURL(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={6}>
              <label>Advertisment Image</label>
              <InputGroup>
                <FormControl
                  type="file"
                  style={{ padding: "4px" }}
                  onChange={(e) => {
                    e.preventDefault();
                    setSelectedFile(e.target.files[0]);
                  }}
                />
              </InputGroup>
            </Col>
            {selectedFile && (
              <Col sm={6}>
                <img
                  style={{ width: "150px", height: "120px" }}
                  src={URL?.createObjectURL(selectedFile)}
                  alt="advertisment"
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {isLoading === true ? (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{ backgroundColor: "#ef3340" }}
              onClick={() => handleSubmit()}
            >
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddAdvertisment;
