import React, { useState, useEffect, useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteCoolArea from "./DeleteCoolArea";
import axios from "axios";
import RestaurantTable from "../RestaurantTable";
import CircularProgress from "@mui/material/CircularProgress";
import { baseURL } from "../../../BaseURL";

const CoolAreaItem = ({ item, index, key }) => {
  const [selectedRestaurants, setSelectedRestaurants] = useState([]);
  const [allRestaurants, setAllRestaurants] = useState([]);
  const [currOpen, setCurrOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;

    const fetchSelectedRestaurants = async () => {
      console.log(item);
      try {
        const res = await axios.get(
          baseURL + "/restaurant/get-all-cool-area-restaurants/" + item._id
        );
        console.log(res);
        if (mounted) {
          setSelectedRestaurants(res.data.data?.recommendedRestaurants);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchSelectedRestaurants();

    return () => {
      mounted = false;
    };
  }, [isLoading, refetch]);

  useEffect(() => {
    let mounted = true;

    const fetchAllRestaurants = async () => {
      try {
        const res = await axios.get(
          baseURL + "/restaurant/get-all-restaurants"
        );
        if (mounted) {
          setAllRestaurants(res.data.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchAllRestaurants();

    return () => {
      mounted = false;
    };
  }, [isLoading]);

  const updateRecommendation = async (id, values) => {
    const body = { restaurantId: values };
    try {
      setLoading(true);
      const res = await axios.post(
        baseURL + "/restaurant/areas/add-restaurants-to-areas/" + id,
        body
      );
    } catch (e) {
      console.log(e.response.data.message);
    }
    setRefetch((prev) => !prev);
    setCurrOpen(false);
    setLoading(false);
  };

  const updateState = (e) => {
    if (e === currOpen) {
      setCurrOpen(false);
    } else {
      setCurrOpen(e);
    }
  };

  return (
    <div
      className="p-2 border m-2 border-gray-300 flex flex-row content-center flex-wrap shadow-md rounded-md bg-gray-100"
      style={{
        width: "71%",
        backgroundColor: "#39424e",
        color: "white",
        alignItems: "center",
      }}
    >
      <div className="w-1/12 text-gray-600 justify-center text-white">
        <img
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "5px",
          }}
          src={item.areaImage}
          alt={item.areaImage}
        />
      </div>
      <div className="w-4/12 text-md">{item.areaName}</div>
      <div
        className="w-4/12 text-md"
        onClick={() => updateState("selected")}
        style={{ cursor: "pointer" }}
      >
        {selectedRestaurants.length} restaurants
      </div>
      <div className="w-2/12" onClick={() => updateState("all")}>
        <AddIcon style={{ cursor: "pointer" }} />
      </div>
      <div className="w-1/12 text-gray-700">
        <DeleteCoolArea
          setLoading={setLoading}
          isLoading={isLoading}
          item={item}
        />
      </div>
      <div className="w-full">
        {currOpen === "selected" ? (
          <RestaurantTable
            id={item._id}
            rows={selectedRestaurants}
            model={selectedRestaurants}
            updateRecommendation={updateRecommendation}
          />
        ) : currOpen === "all" ? (
          <RestaurantTable
            id={item._id}
            rows={allRestaurants}
            model={selectedRestaurants}
            updateRecommendation={updateRecommendation}
            isLoading={isLoading}
            setLoading={setLoading}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CoolAreaItem;
