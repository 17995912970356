import React, { useState, useEffect } from "react";
import SpecialRequest from "./SpecialRequest";
import SpecialOccasion from "./SpecialOccasion";
import DietaryPreference from "./DietaryPreference";

function RestaurantFacilities({
  restaurantId,
  restaurantData,
  isLoading,
  setLoading,
}) {
  return (
    <div>
      <div style={{ height: "300px" }}>
        <SpecialRequest
          restaurantData={restaurantData}
          restaurantId={restaurantId}
          isLoading={isLoading}
          setLoading={setLoading}
        />
      </div>
      <div style={{ height: "300px" }}>
        <SpecialOccasion
          restaurantData={restaurantData}
          restaurantId={restaurantId}
          isLoading={isLoading}
          setLoading={setLoading}
        />
      </div>
      <div style={{ height: "300px" }}>
        <DietaryPreference
          restaurantData={restaurantData}
          restaurantId={restaurantId}
          isLoading={isLoading}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
}

export default RestaurantFacilities;
