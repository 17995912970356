import React, { useState, useEffect } from "react";
import PlatformSubscription from "./PlatformSubscription";
import MonthlyRental from "./MonthlyRental";
import Invoices from "./Invoices";
import RecordPayment from "./RecordPayment";
import GenerateInvoice from "./GenerateInvoice";
import PaymentInvoice from "./PaymentInvoice";
import { baseURL } from "../../../../BaseURL";
import axios from "axios";
import moment from "moment";

function Payment({ restaurantId }) {
  const [paymentDetails, setPaymentDetails] = useState([]);

  useEffect(() => {
    axios
      .get(
        baseURL + "/restaurant/get-restaurant-payment-details/" + restaurantId
      )
      .then((res) => {
        setPaymentDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function getDate(date) {
    const d = moment(date);
    return d.format("DD MMMM YYYY");
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 20px",
        }}
      >
        <p>
          <b>Payments</b>
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "10px 20px",
            // border: "2px solid black",
            width: "350px",
          }}
        >
          <RecordPayment restaurantId={restaurantId} />
          <GenerateInvoice restaurantId={restaurantId} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 20px",
          color: "white",
          fontSize: "14px",
        }}
      >
        <div
          style={{
            width: "200px",
            height: "100px",
            borderRadius: "5px",
            backgroundColor: "#29B6F6",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "98px",
            }}
          >
            <p>
              <b>${paymentDetails?.restaurantSubscriptionPrice}</b>
            </p>
            <p>Subscription</p>
          </div>
          <hr style={{ backgroundColor: "white" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "98px",
            }}
          >
            <b>${paymentDetails?.restaurantPAX}</b>
            <p>Cost / PAX</p>
          </div>
        </div>
        <div
          style={{
            width: "200px",
            height: "100px",
            borderRadius: "5px",
            backgroundColor: "#3C58A0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            textAlign: "center",
          }}
        >
          <b>{getDate(paymentDetails?.restaurantSubscriptionEndDate)}</b>
          <p>Subscription End Date</p>
        </div>
        <div
          style={{
            width: "200px",
            height: "100px",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            textAlign: "center",
            backgroundColor: "#EC9900",
          }}
        >
          <b>${paymentDetails.totalAmountToCollect}</b>
          <p>To Collect</p>
        </div>
        <div
          style={{
            width: "200px",
            height: "100px",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            textAlign: "center",
            backgroundColor: "#1B6E7C",
          }}
        >
          <b>${paymentDetails.totalAmountToPay}</b>
          <p>To Pay</p>
        </div>
        <div
          style={{
            width: "200px",
            height: "100px",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            textAlign: "center",
            backgroundColor: "#F9BA8F",
          }}
        >
          <b>${paymentDetails?.netTotalAmount}</b>
          <p>Net Total (Collect - Pay)</p>
        </div>
      </div>
      <div style={{ margin: "25px 20px 50px" }}>
        <p>
          <b>Platform Subscription</b>
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            margin: "10px 0",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <PlatformSubscription restaurantId={restaurantId} />
        </div>
      </div>
      <div style={{ margin: "25px 20px 50px" }}>
        <p>
          <b>Monthly Rentals</b>
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            margin: "10px 0",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <MonthlyRental restaurantId={restaurantId} />
        </div>
      </div>
      <div style={{ margin: "25px 20px 50px" }}>
        <p>
          <b>Invoices</b>
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "10px 0",
            borderRadius: "5px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <Invoices restaurantId={restaurantId} />
        </div>
      </div>
      <div style={{ margin: "25px 20px 50px" }}>
        <p>
          <b>Payments</b>
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            margin: "10px 0",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <PaymentInvoice restaurantId={restaurantId} />
        </div>
      </div>
    </div>
  );
}

export default Payment;
