import React, { useState, useEffect } from "react";
import axios from "axios";
import { FormControl, InputGroup, Button, Spinner } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import { baseURL } from "../../../../BaseURL";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

function Reviews({ userId }) {
  const [userReviews, setUserReviews] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  let filterBookings = [];
  const filterTable = (searchTerm) => {
    filterBookings = [];
    if (searchTerm === "") {
      filterBookings = userReviews;
    } else {
      userReviews.forEach((item) => {
        if (
          item.restuarantId.restaurantName
            .substring(0, searchTerm.length)
            .toLowerCase() === searchTerm.toLowerCase()
        ) {
          filterBookings.push(item);
        }
      });
    }
  };
  filterTable(searchTerm);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(baseURL + "/user/reviews/get-all-reviews/" + userId)
      .then((res) => {
        console.log(res);
        if (res.data.userReviews) {
          // console.log(res.data.userReviews);
          setUserReviews(res.data.userReviews);
          setLoading(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }, []);
  return (
    <div>
      <div className="heading">
        <div>
          <p>
            <b>Total Reviews</b> : <span>{userReviews?.length}</span>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            style={{
              width: "250px",
              backgroundColor: "#EF3340",
              margin: "0 5px",
            }}
          >
            Export to Excel
          </Button>
          <InputGroup>
            <FormControl
              placeholder="Search Restaurants"
              aria-label="Search Restaurants"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </div>
      </div>
      <div className="data-table">
        <TableContainer
          style={{ border: "1px solid black", borderRadius: "5px" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Id.</b>
                </TableCell>
                <TableCell align="left">
                  <b>Name</b>
                </TableCell>
                <TableCell align="left">
                  <b>Restaurant</b>
                </TableCell>
                <TableCell align="left">
                  <b>Paid(S$)</b>
                </TableCell>
                <TableCell align="left">
                  <b>Booking Date</b>
                </TableCell>
                <TableCell align="left">
                  <b>Booking Time</b>
                </TableCell>
                <TableCell align="left">
                  <b>Star Rating</b>
                </TableCell>
                <TableCell align="left">
                  <b>Reviewed At</b>
                </TableCell>
                <TableCell align="left">
                  <b>Comment</b>
                </TableCell>
              </TableRow>
            </TableHead>
            {filterBookings.length === 0 && isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {filterBookings
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow>
                        <TableCell>{row?._id.substring(0, 10)}...</TableCell>
                        <TableCell>{row?.userName}</TableCell>
                        <TableCell>
                          <Link to={`/restaurant/${row?.restuarantId?._id}`}>
                            <b style={{ color: "#ef3340" }}>
                              {row?.restuarantId?.restaurantName}
                            </b>
                          </Link>
                        </TableCell>
                        <TableCell>{row?.bookingId?.finalPrice}</TableCell>
                        <TableCell>{row?.bookingId?.bookingDate}</TableCell>
                        <TableCell>
                          {row?.bookingId?.startTime} -{" "}
                          {row?.bookingId?.endTime}
                        </TableCell>
                        <TableCell>{row?.starRating}</TableCell>
                        <TableCell>
                          {row?.date?.substring(0, 10)}{" "}
                          {row?.date?.substring(11, 16)}
                        </TableCell>
                        <TableCell style={{ maxWidth: "300px" }}>
                          {row?.reviewMessage ? row?.reviewMessage : "NA"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={userReviews.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

export default Reviews;
