import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import { Modal, Button, Spinner } from "react-bootstrap";
import UpdateRestaurant from "./UpdateRestaurant";
import axios from "axios";
import { baseURL } from "../../../BaseURL";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

function SideBar({
  restroData,
  restaurantId,
  setLoading,
  isLoading,
  activeTabIndex,
  setActiveTabIndex,
}) {
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function handleDelete() {
    axios
      .delete(baseURL + "/restaurant/delete-restaurant-by-id/" + restaurantId)
      .then((res) => {
        if (res.data.message) {
          setLoading(false);
          handleClose4();
          history.push({
            pathname: "/",
          });
          enqueueSnackbar("Restaurant Deleted Successfully", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      });
  }

  function handleMailTo(e) {
    console.log(e.target);
    e.target.setAttribute("href", "mailto:" + e.target.innerText);
  }

  return (
    <div>
      <div className="w-full py-2 px-10 bg-white min-h-screen">
        <div className="bg-indigo-900 p-3 mt-3 text-white rounded-md">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Avatar
              variant="square"
              src={restroData[0]?.restaurantThumbnail}
              style={{ width: "200px", height: "200px", borderRadius: "2px" }}
            />
          </div>
          <div className="w-full flex justify-between items-center">
            <p className="font-semibold text-sm"> Payment Plan </p>
            <span
              onClick={() => setActiveTabIndex(3)}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.33325 9.99996C3.33325 9.53972 3.70635 9.16663 4.16659 9.16663H15.8333C16.2935 9.16663 16.6666 9.53972 16.6666 9.99996C16.6666 10.4602 16.2935 10.8333 15.8333 10.8333H4.16659C3.70635 10.8333 3.33325 10.4602 3.33325 9.99996Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.41058 3.57739C9.73602 3.25195 10.2637 3.25195 10.5891 3.57739L16.4224 9.41072C16.7479 9.73616 16.7479 10.2638 16.4224 10.5892L10.5891 16.4226C10.2637 16.748 9.73602 16.748 9.41058 16.4226C9.08514 16.0971 9.08514 15.5695 9.41058 15.2441L14.6547 9.99998L9.41058 4.7559C9.08514 4.43047 9.08514 3.90283 9.41058 3.57739Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
          <div style={{ margin: "10px 0" }}>
            <p className="text-xs my-1 text-gray-100 font-light">
              {" "}
              {restroData[0]?.restaurantPricePerPerson} SGD per table booking{" "}
            </p>
            <p className=" text-xs 0 text-gray-100">
              {" "}
              2000 SGD platform subscription
            </p>
          </div>
        </div>
        {restroData?.map((restroData) => (
          <div className="my-2 p-1 text-xs rounded-md">
            <p className="font-medium  my-4 px-2">
              {" "}
              <span className="text-gray-500 font-light">
                <span class="font-bold text-black">Email</span> :{" "}
                <a
                  href="#"
                  onClick={(e) => handleMailTo(e)}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {restroData.restaurantEmail}
                </a>
              </span>
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Phone</span> :{" "}
              </span>{" "}
              {restroData && restroData.restaurantPhone}{" "}
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Type</span>:{" "}
              </span>{" "}
              {restroData && restroData.restaurantType}{" "}
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Website</span> :{" "}
              </span>{" "}
              <a style={{ color: "blue" }} href={restroData?.restaurantWebsite}>
                {restroData?.restaurantWebsite}
              </a>
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Reg. ON</span> :{" "}
              </span>{" "}
              {restroData && restroData.restaurantRegistrationNo}
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Location</span> :{" "}
              </span>{" "}
              {restroData && restroData?.restaurantAddress}{" "}
            </p>

            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Locality</span> :{" "}
              </span>{" "}
              {restroData && restroData?.restaurantLocality}{" "}
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Max Table Capacity</span> :{" "}
              </span>{" "}
              {restroData && restroData.restaurantMaximumTableCapacity}{" "}
            </p>
            {/* <p className="font-medium  my-4 px-2 ">
                {" "}
                <span className="text-gray-400 font-light">
                  Table Count :{" "}
                </span>{" "}
                {restroData && restroData.totalTables}{" "}
              </p> */}
            <p className="font-medium  my-4 px-2">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Owner</span> :{" "}
              </span>{" "}
              {restroData && restroData.restaurantOwner}{" "}
            </p>
            <p className="font-medium  my-4 px-2">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Acc Manager</span> :{" "}
              </span>{" "}
              {restroData && restroData.restaurantAccountManager}{" "}
            </p>
            <p className="font-medium  my-4 px-2">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Halal Certificate</span> :{" "}
              </span>{" "}
              {(restroData && restroData?.restaurantHalalCertificate) || "NA"}{" "}
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Cuisines</span>:{" "}
              </span>{" "}
              {restroData &&
                restroData?.cuisineNames?.map((cuisine, index) => {
                  if (index !== restroData?.cuisineNames.length - 1)
                    return cuisine + ", ";
                  else return cuisine;
                })}
            </p>
            <p className="font-medium  my-4 px-2">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Avg Meal Cost</span> :{" "}
              </span>{" "}
              SGD {restroData && restroData.restaurantPrice}{" "}
            </p>
            <p className="font-medium  my-4 px-2">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">Price Per Person</span> :{" "}
              </span>{" "}
              SGD {restroData && restroData?.restaurantPricePerPerson}{" "}
            </p>
            <p className="font-medium  my-4 px-2 ">
              {" "}
              <span className="text-gray-400 font-light">
                <span class="font-bold text-black">ID</span> :{" "}
              </span>{" "}
              {restroData && restroData?._id}{" "}
            </p>

            <div class="flex items-center justify-evenly mb-3">
              {restaurantId && (
                <UpdateRestaurant
                  restaurantId={restaurantId}
                  restaurantData={restroData}
                  setLoading={setLoading}
                  isLoading={isLoading}
                />
              )}

              <button
                onClick={() => handleShow4()}
                style={{ backgroundColor: "#EF3340" }}
                className="border border-red-300 text-white p-2 w-20 text-xs font-medium rounded-md"
              >
                {" "}
                Delete
              </button>
              <Modal show={show4} onHide={handleClose4} centered>
                <Modal.Body
                  class="font-semibold"
                  style={{
                    textAlign: "center",
                    margin: "10px",
                    padding: "5px",
                  }}
                >
                  Are you sure you want to delete this restaurant?
                </Modal.Body>
                <Modal.Footer
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button variant="secondary" onClick={handleClose4}>
                    Cancel
                  </Button>
                  <Button
                    style={{ backgroundColor: "#EF3340", font: "500" }}
                    onClick={handleDelete}
                  >
                    Yes
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        ))}

        {!restroData && (
          <div className="my-1 p-1 text-xs  ">
            <p className="font-medium  my-4 ">
              Email : <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Phone :<Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Website : <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Address : <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Locality : <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Owner : <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Halal Certificate
              <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Total Capacity
              <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              Table Count
              <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              <Skeleton />
            </p>
            <p className="font-medium  my-4 ">
              <Skeleton />
            </p>

            <button className="border p-2 text-xs font-medium my-2">
              <Skeleton width={100} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default SideBar;
