import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Modal,
  Button,
  Spinner,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { ReactSortable } from "react-sortablejs";
import axios from "axios";
import { baseURL } from "../../../BaseURL";
import { useSnackbar } from "notistack";
import "./TableLayout.css";

function TableLayout({ floorId, isLoading, setLoading }) {
  const [tableData, setTableData] = useState([]);
  const [originalTableData, setOriginalTableData] = useState([]);
  const [totalTables, setTotalTables] = useState([]);
  const [show, setShow] = useState(false);
  const [gridSize, setGridSize] = useState(0);
  const [columnSize, setColumnSize] = useState(0);
  const [rowSize, setRowSize] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => setShow(false);

  useEffect(() => {}, [totalTables]);

  const handleShow = async () => {
    await axios
      .get(baseURL + "/restaurant/tables/get-tables-of-floors/" + floorId)
      .then((res) => {
        if (res.data.data) {
          const tableData = res?.data?.data;
          const columnSizeResp = res.data.columnSize
            ? res.data.columnSize
            : tableData.length;
          const rowSizeResp = res.data.rowSize
            ? res.data.rowSize
            : tableData.length;

          setOriginalTableData(tableData);
          setColumnSize(columnSizeResp);
          setRowSize(rowSizeResp);
          setGridSize(columnSizeResp * rowSizeResp);
          // setTableData(totalTables);
          resetGridLayout(columnSizeResp, rowSizeResp);
          setTotalTables([]);
          let newTableData = [];
          const gridSizeArray = Array(gridSize);

          for (let index = 0; index < gridSizeArray.length; index++) {
            const table_data_index = tableData.findIndex(
              (el) => el.sortIndex === index
            );
            if (table_data_index >= 0) {
              const tableDataObj = tableData[table_data_index];
              console.log(tableDataObj);
              const data = {
                id: tableDataObj?._id,
                tableName: tableDataObj?.tableName,
                tableImg: `https://eatsezy.s3.amazonaws.com/seatingLayout/green${tableDataObj?.tableCapacity}.png`,
              };
              newTableData.push(data);
            } else {
              newTableData.push({});
            }
          }
          setTotalTables(newTableData);
          setShow(true);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  async function updateTable() {
    setLoading(true);
    const tableData2 = tableData.filter((table) => {
      return table?._id;
    });

    const body = {
      tableData: tableData2,
      rowSize: rowSize,
      columnSize: columnSize,
      floorId: floorId,
    };

    await axios
      .put(baseURL + "/restaurant/tables/edit-tables", body)
      .then((res) => {
        console.log(res);
        if (res) {
          setLoading(false);
          enqueueSnackbar("Layout Updated Successfully", {
            variant: "success",
          });
          setShow(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        setLoading(false);
      });
  }

  const resetGridLayout = (col, row) => {
    setGridSize(col * row);
    setTotalTables([]);
    let newTableData = [];
    const gridSizeArray = Array(col * row);

    originalTableData.forEach((tableDataObj) => {
      const data = {
        id: tableDataObj?._id,
        tableName: tableDataObj?.tableName,
        tableImg: `https://eatsezy.s3.amazonaws.com/seatingLayout/green${tableDataObj?.tableCapacity}.png`,
      };
      newTableData.push(data);
    });

    for (
      let index = 0;
      index < gridSizeArray.length - originalTableData.length;
      index++
    ) {
      newTableData.push({});
    }

    console.log(gridSizeArray.length);
    console.log(originalTableData.length);
    console.log(newTableData.length);
    setTotalTables(newTableData);
  };

  return (
    <div>
      <Button
        variant="dark"
        style={{
          backgroundColor: "#ef3340",
          width: "7rem",
          fontWeight: "bold",
          border: "none",
          fontSize: "13px",
          padding: "8px 0",
          marginLeft: "5px",
        }}
        onClick={() => {
          handleShow();
        }}
      >
        Layout
      </Button>
      {totalTables.length > 0 && (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <b>Table Layout</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="container">
            <div style={{ padding: "0 10px" }}>
              <Row sm={2}>
                <Col style={{ width: "30rem" }}>
                  <label>Columns</label>
                  <InputGroup>
                    <FormControl
                      placeholder="Columns"
                      aria-label="Columns"
                      type="number"
                      min="1"
                      value={columnSize}
                      onChange={(e) => {
                        setColumnSize(e.target.value);
                        resetGridLayout(e.target.value, rowSize);
                      }}
                    />
                  </InputGroup>
                </Col>

                <Col>
                  <label>Rows</label>
                  <InputGroup>
                    <FormControl
                      placeholder="Rows"
                      aria-label="Rows"
                      type="number"
                      min="1"
                      value={rowSize}
                      onChange={(e) => {
                        setRowSize(e.target.value);
                        resetGridLayout(columnSize, e.target.value);
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </div>

            <ReactSortable
              style={{
                display: "grid",
                gridTemplateColumns: `repeat(${columnSize},1fr)`,
                gridTemplateRows: `repeat(${rowSize},1fr)`,
                gap: "5px",
              }}
              id="table"
              list={totalTables}
              setList={setTotalTables}
              animation={150}
              group="cards"
              onChange={(order, sortable, evt) => {
                const listTables = document.getElementById("table").childNodes;
                setTableData([]);

                listTables.forEach((table, index) => {
                  console.log(table.id);
                  // tableData = [];

                  const data = {
                    _id: table.id,
                    sortIndex: index,
                    tableName: table.innerText,
                  };

                  setTableData((prev) => [...prev, data]);
                });
              }}
              onEnd={(evt) => {}}
            >
              {totalTables.map((table) => {
                return (
                  <div key={table.id} id={table?.id} className="square">
                    <img src={table?.tableImg} alt={table?.tableName} />
                    <p>{table?.tableName}</p>
                  </div>
                );
              })}
            </ReactSortable>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleClose}
              style={{
                backgroundColor: "#39424e",
                width: "5rem",
                fontWeight: "bold",
                border: "none",
                fontSize: "13px",
                padding: "8px 0",
              }}
            >
              Close
            </Button>
            {isLoading === true ? (
              <Button
                variant="dark"
                style={{
                  backgroundColor: "#ef3340",
                  width: "8rem",
                }}
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: "5px" }}
                />
                <span className="visually-hidden">Loading...</span>
              </Button>
            ) : (
              <Button
                variant="dark"
                style={{
                  backgroundColor: "#ef3340",
                  width: "8rem",
                  fontWeight: "bold",
                  border: "none",
                  fontSize: "13px",
                  padding: "8px 0",
                }}
                onClick={updateTable}
              >
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default TableLayout;
