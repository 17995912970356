import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Floor from "../Floors";
import axios from "axios";
import { baseURL } from "../../../../BaseURL";
import Alert from "react-bootstrap/Alert";
import { useSnackbar } from "notistack";

function FloorsTab({ restaurantId, restaurantData }) {
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [floorDetail, setFloorDetail] = useState({
    floorName: "",
    restaurantId: restaurantId,
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [totalFloors, setTotalFloors] = useState([]);

  useEffect(() => {
    axios
      .get(
        baseURL + "/restaurant/floors/get-floors-of-restaurant/" + restaurantId
      )
      .then((res) => {
        setTotalFloors(res.data.data);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }, [isLoading]);

  function addFloor() {
    if (floorDetail.floorName === "") {
      enqueueSnackbar("Floor Name cannot be empty", { variant: "error" });
    } else {
      setLoading(true);
      axios
        .post(baseURL + "/restaurant/floors/create-floor", floorDetail)
        .then((res) => {
          console.log(res.data.data);
          setLoading(false);
          setFloorDetail({ ...floorDetail, floorName: "" });
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>
          <b>Total Floors : {totalFloors?.length}</b>
        </p>
        <button
          className="text-white font-bold px-4 py-2 rounded-md"
          style={{ backgroundColor: "#EF3340" }}
          onClick={() => handleShow()}
        >
          Add Floors
        </button>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Body style={{ textAlign: "center", fontSize: "18px" }}>
            <b>Add Floors</b>
          </Modal.Body>
          <div style={{ padding: "0 10px" }}>
            <Row sm={12}>
              <Col>
                <label>Floor Name</label>
                <InputGroup>
                  <FormControl
                    placeholder="Floor Name"
                    aria-label="Floor Name"
                    value={floorDetail.floorName}
                    onChange={(e) =>
                      setFloorDetail({
                        ...floorDetail,
                        floorName: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              </Col>
            </Row>
          </div>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: "#EF3340" }}
              onClick={() => addFloor()}
              disabled={!floorDetail.floorName ? true : false}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        className="ml-4 bg-white px-2 mt-3 border border-gray-300 rounded-sm shadow-md"
        style={{ width: "50rem" }}
      >
        {totalFloors.length === 0 ? (
          <p class="py-3 text-gray-500">No Floors found.Please add one</p>
        ) : (
          totalFloors.map((floor) => (
            <Floor
              key={floor._id}
              floorId={floor._id}
              restaurantId={floor.restaurantId}
              floorName={floor.floorName}
              isFloorLoading={isLoading}
              setFloorLoading={setLoading}
              maxTableSize={restaurantData.restaurantMaximumTableCapacity}
              // updateFloor={updateFloor}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default FloorsTab;
