import React, { useState, useEffect } from "react";
import {
  InputGroup,
  FormControl,
  Button,
  Spinner,
  Col,
  Row,
} from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteCuisine from "./DeleteCusine";
import axios from "axios";
import { baseURL } from "../../../BaseURL";

function Cuisines() {
  const [allCuisines, setAllCuisines] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingCuisines, setLoadingCuisines] = useState("");
  const [cuisineName, setCuisineName] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  useEffect(() => {
    axios
      .get(baseURL + "/restaurant/cuisines/get-all-cuisines")
      .then((res) => {
        if (res.data.data) setAllCuisines(res.data.data);
      })
      .catch((err) => {
        console.log("Error");
      });
  }, [isLoading, isLoadingCuisines]);

  function handleSubmission() {
    const formData = new FormData();

    formData.append("cuisineName", cuisineName);
    formData.append("cuisineImage", selectedFile);
    setLoading(true);
    axios
      .post(baseURL + "/restaurant/cuisines/add-cuisines", formData)
      .then((res) => {
        if (res.data.data) console.log(res.data.data);
        setLoading(false);
        setCuisineName("");
      })
      .catch((err) => {
        console.log("Error");
      });
  }

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  return (
    <div>
      <div className="heading">
        <div>
          <p style={{ marginTop: "-20px", fontSize: "16px" }}>
            <b>Total Cuisines</b> : {allCuisines.length}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItem: "center",
            justifyContent: "space-between",
            width: "40rem",
          }}
        >
          <div>
            <label>Cuisine Name</label>
            <InputGroup>
              <FormControl
                placeholder="Cuisine Name"
                aria-label="Cuisine Name"
                value={cuisineName}
                onChange={(e) => setCuisineName(e.target.value)}
              />
            </InputGroup>
          </div>
          <div>
            <label>Cuisine Image</label>
            <InputGroup>
              <FormControl
                type="file"
                style={{ padding: "4px" }}
                onChange={changeHandler}
              />
            </InputGroup>
          </div>

          {isLoading === true ? (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                height: "35px",
                marginTop: "32px",
              }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="dark"
              style={{
                backgroundColor: "#ef3340",
                width: "90px",
                height: "35px",
                marginTop: "32px",
                textAlign: "center",
              }}
              onClick={() => handleSubmission()}
            >
              Add
            </Button>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row
          sm={3}
          style={{
            width: "70rem",
            padding: "0 20px",
          }}
        >
          {allCuisines.length !== 0 &&
            allCuisines.map((cuisine) => (
              <Col
                lg={4}
                style={{
                  margin: "5px 0",
                }}
              >
                <div
                  class="shadow-md"
                  style={{
                    textAlign: "center",
                    height: "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    padding: "0 15px",
                    backgroundColor: "#39424e",
                    color: "white",
                  }}
                >
                  <img
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "5px",
                    }}
                    src={cuisine.cuisineImage}
                    alt={cuisine.cuisineImage}
                  />
                  <p style={{}}>{cuisine.cuisineName}</p>
                  <div>
                    <DeleteCuisine
                      isLoading={isLoading}
                      setLoading={setLoading}
                      cuisineId={cuisine._id}
                    />
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </div>
  );
}

export default Cuisines;
